import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.scss";

import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import AddOn from "./pages/AddOn/AddOn";
import FaqPage from "./pages/FAQ/FaqPage";
import Careers from "./pages/Career/Careers";
import Privacy from "./pages/Privacy/Privacy";
import Layout from "./components/Layout/Layout";
import ContactUs from "./pages/ContactUs/ContactUs";
import Loader from "./components/Loader/LoaderComponent";
import CareerDetails from "./pages/Career/CareerDetails";
import CustomerInfo from "./pages/CustomerInfo/CustomerInfo";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import HomeDashboard from "./pages/HomeDashboard/HomeDashboard";
import GlobalContextControl from "./utils/context/GlobalContext";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import TermsCondition from "./pages/TermsCondition/TermsCondition";
import AddGarage from "./pages/AdminDashboard/AddGarage/AddGarage";
import ServicePackage from "./pages/Service-package/ServicePackage";
import GarageList from "./pages/AdminDashboard/GarageList/GarageList";
import ViewBooking from "./pages/AdminDashboard/ViewBooking/ViewBooking";
import ServicesDashboard from "./pages/ServicesDashboard/ServicesDashboard";
import AddAccessories from "./pages/AdminDashboard/AddAccessories/AddAccessories";
import ServiceHistory from "./pages/ServicesDashboard/ServiceHistory/ServiceHistory";
import AccessoriesList from "./pages/AdminDashboard/AccessoriesList/AccessoriesList";
import { CustomDialogServiceProvider } from "./components/Dialog/CustomDialog/CustomDialogService";
import Service from "./pages/Service/Service";
import HelpSupport from "./pages/ServicesDashboard/HelpSupport/HelpSupport";
import HelpSupportAdmin from "./pages/AdminDashboard/HelpSupport/HelpSupport";
import { SnackbarServiceProvider } from "./components/Dialog/SnackbarDialog/SnackbarService";
import UploadJobsheet from "./components/UploadJobSheet/UploadJobsheet";
import Services from "./pages/Services/Services";
import UploadInvoice from "./components/UploadInvoice/UploadInvoice";

function App() {
  return (
    <GlobalContextControl>
      <CustomDialogServiceProvider>
        <SnackbarServiceProvider>
          <div className="App">
            <Loader />
            <Router>
              <Routes>
                <Route
                  path="/service"
                  element={<Layout />}
                  children={
                    <>
                      <Route path="package" element={<ServicePackage />} />
                      <Route path="addon" element={<AddOn />} />
                      <Route path="create" element={<CustomerInfo />} />
                      <Route path="dashboard" element={<ServicesDashboard />} />
                      <Route path="home" element={<HomeDashboard />} />
                      <Route path="history" element={<ServiceHistory />} />
                      <Route element={<NotFoundPage />} />
                      <Route path="help" element={<HelpSupport />} />
                    </>
                  }
                />
                <Route
                  path="/"
                  element={<Layout />}
                  children={
                    <>
                      <Route path="/" element={<Home />} />
                      <Route path="/home" element={<Home />} />
                      <Route path="/contact" element={<ContactUs />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/terms" element={<TermsCondition />} />
                      <Route path="/privacy" element={<Privacy />} />
                      <Route element={<NotFoundPage />} />
                      <Route path="/faq" element={<FaqPage />} />
                      <Route path="/careers" element={<Careers />} />
                      <Route
                        path="career-details"
                        element={<CareerDetails />}
                      />
                      <Route path="services" element={<Services />} />
                    </>
                  }
                />
                <Route
                  path="/admin"
                  element={<Layout />}
                  children={
                    <>
                      <Route path="dashboard" element={<AdminDashboard />} />
                      <Route path="garage-list" element={<GarageList />} />
                      <Route path="viewbooking/:id" element={<ViewBooking />} />
                      <Route path="garage/add" element={<AddGarage />} />
                      <Route path="garage/:id" element={<AddGarage />} />
                      <Route path="accessories" element={<AccessoriesList />} />
                      <Route
                        path="accessories/:id"
                        element={<AddAccessories />}
                      />
                      <Route
                        path="accessories/add"
                        element={<AddAccessories />}
                      />
                      <Route path="help" element={<HelpSupportAdmin />} />
                      <Route
                        path="createJobsheet/:id"
                        element={<UploadJobsheet />}
                      />
                      <Route
                        path="createInvoice/:id"
                        element={<UploadInvoice />}
                      />
                      <Route element={<NotFoundPage />} />
                    </>
                  }
                />
                <Route path="404" element={<NotFoundPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Router>
          </div>
        </SnackbarServiceProvider>
      </CustomDialogServiceProvider>
    </GlobalContextControl>
  );
}

export default App;
