import React from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

import "./SnackbarDialog.scss";

export type SnackbarOptions = {
  title: string | React.ReactNode;
  hideCancel?: boolean;
  open?: boolean;
  severity?: "info" | "success" | "warning" | "error" | undefined;
  customClass?: string;
  autoHideDuration?: number | null;
  extraBtnText?: number | null;
  isShowExtraBtn?: boolean;
};

type SnackbarDialogProps = {
  open: boolean;
  onSubmit: () => void;
  onClose: (type?: boolean) => void;
} & SnackbarOptions;

export const SnackbarDialog: React.FC<SnackbarDialogProps> = ({
  open,
  title,
  onClose,
  severity,
  customClass,
  isShowExtraBtn = false,
  extraBtnText,
  autoHideDuration,
}) => {
  const handleCloseClick = (type?: boolean) => {
    onClose(type);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={autoHideDuration ? autoHideDuration : 6000}
        open={open}
        onClose={() => handleCloseClick()}
        className={`snackbardialog ${customClass ? customClass : ""}`}
      >
        {/* @ts-ignore */}
        <Alert
          severity={severity}
          icon={false}
          action={
            isShowExtraBtn && (
              <Button color="inherit" size="small">
                {extraBtnText}
              </Button>
            )
          }
        >
          {title}
        </Alert>
      </Snackbar>
    </>
  );
};
