import React, { useEffect } from "react";
import LeftSideBar from "../ServicesDashboard/LeftSideBar/LeftSideBar";

import "./HomeDashboard.scss";

import Bike from "../../assets/images/Bike.png";
import Oil from "../../assets/images/oil.png";
import { sendGetRequest } from "../../utils/helpers/requestController";
import { apiEndPoints } from "../../utils/helpers/endPoints";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
const HomeDashboard = () => {
  const navigate = useNavigate();
  const [serviceList, setServiceList] = React.useState([]);
  const [services, setService] = React.useState<any>({});

  const getOngoingService = async () => {
    const res = await sendGetRequest(`${apiEndPoints.createService}/ongoing`);
    setServiceList(res);
    setService(res[0]);
  };

  useEffect(() => {
    getOngoingService();
  }, []);

  const navigateOnGoing = () => {
    navigate("/service/dashboard");
  };

  const navigateHome = () => {
    navigate("/");
  };

  const getServiceStatus = (statusNumber: any) => {
    if (statusNumber === 1) {
      return "Booked";
    } else if (statusNumber === 2) {
      return "Ongoing Service";
    } else if (statusNumber === 3) {
      return "Ongoing Service";
    } else if (statusNumber === 4) {
      return "Ongoing Service";
    } else if (statusNumber === 5) {
      return "Comepleted";
    }
  };

  return (
    <div>
      <div className="dashboard-layout">
        <div className="dashboard-layout__left">
          <LeftSideBar />
        </div>
        <div className="dashboard-layout__right">
          <div className="home-dashboard">
          <div className="body-left">
            <div className="service-dashboard__header">
              <h3>Service Overview</h3>
            </div>
            <div className="body-left__banner">
              <div className="body-left__image">
                <div className="bike-img">
                  <img src={Bike} alt="" />
                </div>
                <div className="oil-img">
                  <img src={Oil} alt="" />
                </div>
              </div>
              <div className="body-left__text">
                <div>Refer Your Friend</div>
                <p>and Get 100 Rs off on </p>
                <p>Mobil Engine Oil</p>
                {/* <button className="refer-button">Refer Now</button> */}
              </div>
            </div>
            <div className="body-left__ongoing">
              <h6>On Going Service</h6>
              <div className="ongoing-serviceCards">
                {serviceList?.map((service: any) => {
                  return (
                    <div key={service?._id} className="ongoing-serviceCards__individual" onClick={navigateOnGoing}>
                      <div className="card-image">
                        <div
                          className={
                            service?.serviceStatus === 1
                              ? "status-booked"
                              : service?.serviceStatus === 2 || service?.serviceStatus === 3 || service?.serviceStatus === 4
                              ? "status-ongoing"
                              : "status-completed"
                          }
                        >
                          {getServiceStatus(service?.serviceStatus)}
                        </div>
                        <div className="image-container">
                          <img src={service?.vehicleInfo?.model?.image} alt="" />
                        </div>
                      </div>
                      <div className="card-content">
                        <div className="service-details">
                          <div className="id">SPDR{service?._id}</div>
                          <div className="date">{moment(service?.createdAt).format("DD MMM, YY")}</div>
                        </div>
                        <div className="vehicle-details">{service?.vehicleInfo?.model?.name} {service?.vehicleInfo?.vehicleNo}</div>
                        {/* <div className="vehicle-details">{service?.vehicleInfo?.vehicleNo}</div> */}
                        <div className="package-details">
                          <div className="name">Service Package</div>
                          <div className="package">{service?.packages?.name}</div>
                        </div>
                        <div className="package-details">
                          <div className="name">Add-Ons</div>
                          <div className="package">
                            {service.addOns?.map((add: any) => {
                              return <>{add?.name},</>;
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="body-right">
            <div className="service-dashboard__header">
              {/* <h6>
                <b>Ongoing Service</b>
              </h6> */}
            </div>
            <div className="body-left__serviceCard">
              <Button type="submit" variant="contained" onClick={navigateHome}>
                Book Service
              </Button>
            </div>
            {/* <div className="body-left__serviceCard">
              <div className="card-individual">
                <div className="card-individual__left">
                  <img src={EmissionTest} alt="" />
                </div>
                <div className="card-individual__right">
                  <p>Emission Test</p>
                  <p className="button-book">Renew Now</p>
                </div>
              </div>
              <div className="card-individual">
                <div className="card-individual__left" style={{ backgroundColor: "#9B51E0" }}>
                  <img src={BikeInsurance} alt="" />
                </div>
                <div className="card-individual__right">
                  <p>Bike Insurance</p>
                  <p className="button-book">Buy Now</p>
                </div>
              </div>
              <div className="card-individual">
                <div className="card-individual__left" style={{ backgroundColor: "#28CE8C" }}>
                  <img src={PeriodicService} alt="" />
                </div>
                <div className="card-individual__right">
                  <p>periodic service</p>
                  <p className="button-book">Book Now</p>
                </div>
              </div>
            </div> */}
            {/* <div className="body-right__timeline">
              <div className="timeline-individual">
                <div className="test-header">Emission Test</div>
                <div className="vehicle-no">KA 05 MB 1234</div>
                <div className="vehicle-model">Honda CBR650R</div>
                <div className="expiry">Expiring on 1 Jan, 23</div>
                <div className="renew-button">Renew Emission Test</div>
              </div>
              <hr />
              <div className="timeline-individual" style={{ paddingTop: "0" }}>
                <div className="test-header">Insurance</div>
                <div className="vehicle-no">KA 05 MB 1234</div>
                <div className="vehicle-model">Honda CBR650R</div>
                <div className="expiry">Expiring on 1 Jan, 23</div>
                <div className="renew-button">Renew Insurance</div>
              </div>
            </div> */}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeDashboard;
