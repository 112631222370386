// Generated by https://quicktype.io

export interface IGarage {
  address: IAddress;
  _id?: string;
  name: string;
  email: string;
  contact: string;
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
}

export interface IGarageError {
  address: boolean;
  locality: boolean;
  pincode: boolean;
  city: boolean;
  state: boolean;
  _id: boolean;
  name: boolean;
  email: boolean;
  contact: boolean;
}

export class GarageError implements IGarageError {
  address: boolean;
  locality: boolean;
  pincode: boolean;
  city: boolean;
  state: boolean;
  _id: boolean;
  name: boolean;
  email: boolean;
  contact: boolean;

  constructor(data: any = {}) {
    this.address = data.address || false;
    this.locality = data.locality || false;
    this.pincode = data.pincode || false;
    this.city = data.city || false;
    this.state = data.state || false;
    this._id = data._id || false;
    this.name = data.name || false;
    this.email = data.email || false;
    this.contact = data.contact || false;
  }
}
export interface IAddress {
  address: string;
  locality: string;
  pincode: string;
  city: string;
  state: string;
}

class Address implements IAddress {
  address: string;
  locality: string;
  pincode: string;
  city: string;
  state: string;
  constructor(data: any = {}) {
    this.address = data.address || "";
    this.locality = data.locality || "";
    this.pincode = data.pincode || "";
    this.city = data.city || "";
    this.state = data.state || "";
  }
}

export class Garage implements IGarage {
  address: IAddress;
  _id?: string;
  name: string;
  email: string;
  contact: string;
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
  constructor(data: any = {}) {
    this.address = data.address || new Address();
    this._id = data._id || "";
    this.name = data.name || "";
    this.email = data.email || "";
    this.contact = data.contact || "";
    this.createdAt = data.createdAt || "";
    this.updatedAt = data.updatedAt || "";
    this.__v = data.__v || "";
  }
}
