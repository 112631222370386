import React from "react";
import Slider from "react-slick";
import { Author } from "../../../assets/Icons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Textimonails.scss";

const Textimonails = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
  };
  return (
    <div className="textimonails">
      <div className="container">
        <Slider {...settings}>
          <div className="slider__item">
            <div className="slider__item-title">
            Spannderdoor provided an unparalleled two-wheeler service experience. Their professional technicians arrived promptly, equipped with top-notch tools, and efficiently resolved all my bike issues. I highly recommend their doorstep service for hassle-free and reliable maintenance.
            </div>
            <div className="slider__item-image">
              <Author />
            </div>
            <div className="slider__item-author">Nithin G</div>
            <div className="slider__item-designation">Spannerdoor customer</div>
          </div>
          <div className="slider__item">
            <div className="slider__item-title">
            I was amazed by the lightning-fast response and impeccable service provided by Spannerdoor when I discovered a fuel leakage in my scooty. Their skilled technicians swiftly identified and resolved the issue, ensuring my safety and peace of mind. I'm incredibly grateful for their prompt and efficient service.
            </div>
            <div className="slider__item-image">
              <Author />
            </div>
            <div className="slider__item-author">Sumanth</div>
            <div className="slider__item-designation">Engineer</div>
          </div>
          <div className="slider__item">
            <div className="slider__item-title">
            Spannderdoor came to my rescue when my scooty's kick got jammed. Their prompt and skilled service team efficiently fixed the issue, allowing me to get back on the road in no time. I'm incredibly impressed with their expertise and dedication to providing quick solutions. Highly recommended for any two wheeler -related concerns!
            </div>
            <div className="slider__item-image">
              <Author />
            </div>
            <div className="slider__item-author">Ashish k s</div>
            <div className="slider__item-designation">Engineer</div>
          </div>

         
        </Slider>
      </div>
    </div>
  );
};

export default Textimonails;
