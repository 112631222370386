import Button from "@mui/material/Button";
import "react-intl-tel-input/dist/main.css";
import IntlTelInput from "react-intl-tel-input";
import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  InputLabel,
} from "@mui/material";

import { apiEndPoints } from "../../utils/helpers/endPoints";
import { SpannerDoorContext } from "../../utils/context/GlobalContext";
import {
  sendGetRequest,
  sendPostRequest,
} from "../../utils/helpers/requestController";

import Faq from "./Faq/Faq";
import OtpInputs from "./OtpInputs";
import GetInTouch from "./GetInTouch/GetInTouch";
import HowItWorks from "./HowItWorks/HowItWorks";
// import ServicesInfo from "./ServiceInfo/ServicesInfo";
import Textimonails from "./Textimonails/Textimonails";
import TrustedCompay from "./TrustedCompany/TrustedCompay";
import { phoneRegEx } from "../../utils/helpers/constants";
import ServiceGurantee from "./ServiceGurantee/ServiceGurantee";

import "./Home.scss";
import { getCookie, setCookie } from "../../utils/helpers/utils";
import { useNavigate } from "react-router-dom";
import PendingOrder from "../../components/PendingOrder/PendingOrder";
import { Service } from "../../utils/models/service";

const Home = () => {
  const [submitted, setSubmitted] = useState(false);
  const [phoneNum, setPhoneNum] = useState("");
  const [isEnable, setIsEnable] = useState(false);
  const { service, dispatch, brandName, modelName }: any =
    React.useContext(SpannerDoorContext);
  const accessToken = getCookie("access_token");
  const [searchText, setSearchText] = useState("");
  const [searchBrand, setSearchBrand] = useState("");
  const navigate = useNavigate();

  const handleBooking = async (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (!accessToken) {
      const payload = {
        phone: phoneNum,
      };
      try {
        await sendPostRequest(apiEndPoints.addUser, payload);
        setSubmitted(true);
      } catch (error: any) {}
    } else {
      navigate("/service/package");
    }
  };

  React.useEffect(() => {
    if (accessToken) {
      setTimeout(() => {
        const phone = getCookie("userPhone") as string;
        if (phone) {
          setPhoneNum(phone);
          const serviceObj = new Service({});
          serviceObj.usersInfo.contact = phone?.replace(/\s/g, "");
          dispatch({ type: "service", payload: serviceObj });
        }
      }, 1000);
    }
  }, [accessToken]);

  const handleBrandSelect = (e: any) => {
    const serviceObj = Object.assign({}, service);
    switch (e.target.name) {
      case "select-brand":
        serviceObj.vehicleInfo.brand = e.target.value;
        getModelNames();
        break;
      case "select-model":
        serviceObj.vehicleInfo.model = e.target.value;
        break;
    }
    dispatch({ type: "service", payload: serviceObj });
    if (serviceObj.vehicleInfo.brand && serviceObj.vehicleInfo.model) {
      setIsEnable(true);
    }
  };

  const handlePhoneChange = (phoneNum: any) => {
    setPhoneNum(phoneNum?.replace(/\s/g, ""));
    const serviceObj = Object.assign({}, service);
    serviceObj.usersInfo.contact = phoneNum.replace(/\s/g, "");
    setCookie("userPhone", phoneNum.replace(/\s/g, ""));
    dispatch({ type: "service", payload: serviceObj });
  };

  const getModelNames = async () => {
    const url = apiEndPoints.modelNames + "/" + service.vehicleInfo.brand;
    const res = await sendGetRequest(url);
    dispatch({ type: "modelName", payload: res });
  };

  const getBrandNames = async () => {
    const res = await sendGetRequest(apiEndPoints.brandNames);
    dispatch({ type: "brandName", payload: res });
  };

  useEffect(() => {
    sessionStorage.clear();
    dispatch({ type: "clear", payload: "" });
    getBrandNames();
  }, []);

  // useEffect(() => {
  //   // console.log(modelName, searchText);
  //   const res = modelName?.filter((model: any) =>
  //     model.name.toLowerCase().includes(searchText)
  //   );
  //   console.log(res, "new");
  // }, [searchText]);
  return (
    <>
      <div className="home">
        <div className="container home__block">
          <div className="home__content">
            <h1 className="home__title">
              Experience the best bike service at your doorstep.
            </h1>
            <p className="home__text">
              Revolutionizing bike service : Unleash the convenience, right at
              your doorstep!
            </p>
            <div className="home__bike-man">
              <img src={"../../../images/bike-man.png"} alt="bike-man"></img>
            </div>
          </div>
          {/* <div className="home__booking">
            <div className="home__logoicon">
              <img src={"../../../images/logoicon.svg"} alt="logo"></img>
            </div>

            <h2 className="home__booking-title">Book a Service</h2>
            <p className="home__booking-quote">Get a instant service</p>
            {!submitted ? (
              <>
                <form onSubmit={handleBooking} autoComplete="off">
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="search-select-model">
                        Select Brand
                      </InputLabel>
                      <Select
                        // Disables auto focus on MenuItems and allows TextField to be in focus
                        MenuProps={{ autoFocus: false }}
                        labelId="search-select-model"
                        name="select-brand"
                        label="Select Brand"
                        onChange={handleBrandSelect}
                        onClose={() => setSearchBrand("")}
                        value={service.vehicleInfo.brand}
                      >
                        <ListSubheader>
                          <TextField
                            className="select-search"
                            size="small"
                            // Autofocus on textfield
                            autoFocus
                            placeholder="Search..."
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img
                                    src={"../../../images/search-icon.svg"}
                                    alt="search"
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSearchBrand(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                // Prevents autoselecting item while typing (default Select behaviour)
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>

                        {brandName
                          ?.filter((model: any) =>
                            model.name.toLowerCase().includes(searchBrand)
                          )
                          .sort()
                          .map((option: any) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="search-select-model">
                        Select Model
                      </InputLabel>
                      <Select
                        MenuProps={{ autoFocus: false }}
                        labelId="search-select-model"
                        name="select-model"
                        label="Select Model"
                        onChange={handleBrandSelect}
                        onClose={() => setSearchText("")}
                        value={service.vehicleInfo.model}
                      >
                        <ListSubheader>
                          <TextField
                            size="small"
                            // Autofocus on textfield
                            autoFocus
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {" "}
                                  <img
                                    src={"../../../images/search-icon.svg"}
                                    alt="search"
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                // Prevents autoselecting item while typing (default Select behaviour)
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>

                        {modelName
                          ?.filter((model: any) =>
                            model.name.toLowerCase().includes(searchText)
                          )
                          .sort()
                          .map((option: any) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl
                      fullWidth
                      className={`phone-input ${
                        !accessToken ? "" : "visbility-hidden"
                      }`}
                    >
                      <IntlTelInput
                        containerClassName="intl-tel-input"
                        inputClassName="form-control"
                        placeholder="Enter your mobile number"
                        defaultCountry="in"
                        fieldName="tel"
                        fieldId="PhoneNumber"
                        numberType="MOBILE"
                        onPhoneNumberChange={(
                          isValid: boolean,
                          value: string,
                          selectedCountryData: any,
                          fullNumber: string,
                          extension: string
                        ) => handlePhoneChange(fullNumber)}
                        // value={phoneNum}
                      />
                      {phoneNum?.length > 0 && !phoneRegEx.test(phoneNum) && (
                        <div className="form__input-error">
                          <p>Please enter valid phone number.</p>
                        </div>
                      )}
                    </FormControl>
                  </Box>

                  <Box>
                    <FormControl fullWidth>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={
                          isEnable
                            ? phoneNum?.length > 0 && phoneRegEx.test(phoneNum)
                              ? false
                              : true
                            : true
                        }
                      >
                        Get Started
                      </Button>
                    </FormControl>
                  </Box>
                  <div className="extra-info">
                    <span>
                      For Booking Support Call :
                      <a href="tel:+918050428282">+91 8050428282</a>
                    </span>
                    <span>
                      Quick Road Side Assistance &nbsp;
                      <a
                        href="https://wa.me/+918050428282?text=I%20Need%20Road%20Side%20Assistance%20-Quick%20Support"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Click here
                      </a>
                    </span>
                  </div>
                </form>
              </>
            ) : (
              <OtpInputs phoneNum={phoneNum} setSubmitted={setSubmitted} />
            )}
          </div> */}
        </div>
      </div>

      {/* //Star Rating and count */}
      {/* <ServicesInfo /> */}
      {accessToken && <PendingOrder />}
      <HowItWorks />
      <ServiceGurantee />
      <Textimonails />
      {/* <TrustedCompay /> */}
      <Faq />
      <GetInTouch />
      {/* <Footer /> */}
    </>
  );
};

export default Home;
