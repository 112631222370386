import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Layout = () => {
  return (
    <div className="layout">
      <div className="layout__container">
        <div className="layout__container--right">
          <Header />
          <Outlet />
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default Layout;
