export const pincodeData: any = {
  "562164": [
    {
      City: "Avathi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Bidalur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Bijjawara",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Karahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Koira",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Venkatagirikote",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562163": [
    {
      City: "Arakere",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Basettihalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Doddatumkur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Konnagatta",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Nagadenahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Shivapura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Veerapura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562162": [
    {
      City: "Aluru",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Dasanapura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hullegowdanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Huskur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Lakshmipura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Madanayakanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Madavara",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Makali",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mathahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Shivanapura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562157": [
    {
      City: "Bettahalsur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Chikkajala",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Doddajala",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hunasamaranahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Tarahunise",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Vidyanagara",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562149": [
    {
      City: "Bagalur (Bangalore)",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Bandikodigehalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kannur",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562135": [
    {
      City: "Channarayapatna",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Harohalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mandibele",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Narayanapura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Vijayapura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562132": [
    {
      City: "Doddabele",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kalalaghatta",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Manne",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Niduvanda",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Obalapura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Tyamagondlu",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562130": [
    {
      City: "Chikkanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Chunchanakuppe",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kadabagere",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kithanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Soladevanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Sondekoppa",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Tavarekere (Bangalore)",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562129": [
    {
      City: "Bendiganahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Bettakote",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Budigere",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Ibasapura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Reddihalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Sulebele",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562125": [
    {
      City: "Dommasandra",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Handenahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kugur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Neriga",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Sarjapura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562123": [
    {
      City: "Arasinakunte",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Budihal",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Gollahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Honnasandra",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Jakkanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Madalakote",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mahadevapura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mallarabanavadi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mandigere",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mylanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Nelamangala",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Theppadabeguru",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Yentiganahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562122": [
    {
      City: "Begur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Bylanarasapura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hindiganala",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "K.Satyavara",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Korati",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Nandagudi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Nelavagilu",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Shivanapura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Tavarekere",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562114": [
    {
      City: "Doddagattiganabbe",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Doddanallala",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hasigala",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hoskote Indl. Area",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hoskote",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Jadigenahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Jodidasarahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kattigenahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kembliganahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kolathur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kumbalahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Lakkondanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mugabala",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Vagata Agrahara",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562112": [
    {
      City: "Karikaldoddi",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562111": [
    {
      City: "Arebommanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Baragenahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Billanakote",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Devarahosahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Dobbespet",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Heggunda",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kambalu",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kuluvanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Lakkur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Maralakunte",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Narasipura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Shivagange",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Yelekyathanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562110": [
    {
      City: "Alurduddanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Anneshwara",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Aradeshanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Devanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Doddasanne",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hegganahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Jalige",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kannamangala",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kundana",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Sadahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Uganavadi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Vishwanathapura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Yeliyur",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562107": [
    {
      City: "Attibele",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Bidaraguppe",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mayasandra",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Neralur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Yadavanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "562106": [
    {
      City: "Anekal",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Bestamaranahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Byagadadenahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Guddanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Indalawadi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Marasur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Samandur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Sidihoskote",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Thammanayakanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Vanakanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "561205": [
    {
      City: "Gantiganahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hadonahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Heggadahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Melekote",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Tubugere",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "561204": [
    {
      City: "Arudi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Bhaktharahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Doddabelavangala",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Doddahejjaji",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hulikunte",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kadalappanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kadanur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Koligere",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Purushanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Rameshwara",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Sakkaaregollahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Sasalu",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Syakaladevanapura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "561203": [
    {
      City: "Antharahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Aralumallige",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Dodballapura Bazar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Dodballapura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Gundamgere",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hanabe",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hosahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kanasavadi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kannamangala",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kodigehalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mallathahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Rajaghatta",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Sri Subramanyaghati",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Thippur",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560500": [
    {
      City: "CPC ITD BNPLoking HUB",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560300": [
    {
      City: "Bangalore International Airport",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560117": [
    {
      City: "Devanagundi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Doddadunnasandra",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kalkunte",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Medimallasandra",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Samethanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560116": [
    {
      City: "Bolare",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kaggalipura P.O",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Somanhalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Taralu",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Thattekuppe",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560115": [
    {
      City: "Kannamangala",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560114": [
    {
      City: "Begur",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560113": [
    {
      Pincode: 560113,
      City: "Horamavu",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560112": [
    {
      City: "Kodigehalli",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560111": [
    {
      City: "Kumaraswamy Layout",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560110": [
    {
      City: "Ullalu Upanagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560109": [
    {
      City: "Thalaghattapura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560108": [
    {
      City: "Anjanapura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560107": [
    {
      City: "Achitnagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560105": [
    {
      Pincode: 560105,
      City: "Haragadde",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hennagara",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hulimangala",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Jigani",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kallubalu",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560104": [
    {
      City: "Hampinagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560103": [
    {
      City: "Bellandur",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560102": [
    {
      City: "HSR Layout",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560100": [
    {
      Pincode: 560100,
      City: "Electronics City",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560099": [
    {
      City: "Bommasandra Industrial Estate",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Huskur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Muthanallur",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560098": [
    {
      City: "Channasandra PO",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Pattanagere P.O",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Rajarajeshwarinagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560097": [
    {
      City: "Vidyaranyapura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560096": [
    {
      City: "Nandinilayout",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560095": [
    {
      City: "Koramangala VI Bk",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560094": [
    {
      City: "ISRO Anthariksha Bhavan",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "R M V Extension II Stage",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560093": [
    {
      City: "C V Raman Nagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560092": [
    {
      City: "Amruthahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Byatarayanapura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kodigehalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Sahakaranagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560091": [
    {
      City: "Bapagrama",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Herohalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Viswaneedam",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560090": [
    {
      City: "Chikkabanavara",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Silvepura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560089": [
    {
      City: "Byatha",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hessarghatta Lake",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kakolu",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Shivakote",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560088": [
    {
      Pincode: 560088,
      City: "Hessarghatta",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560087": [
    {
      Pincode: 560087,
      City: "Gunjur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Muthsandra",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Panathur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Vartur",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560086": [
    {
      City: "Mahalakshmipuram Layout",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "West of Chord Road II stage",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560085": [
    {
      City: "Banashankari III Stage",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Girinagar (Bangalore)",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kathriguppe",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560084": [
    {
      City: "Lingarajapuram",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "St. Thomas Town",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560083": [
    {
      City: "Bannerghatta",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Gottigere",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kalkere",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Ragihalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Sakalavara",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560082": [
    {
      City: "Thataguni",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Udaypura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560081": [
    {
      Pincode: 560081,
      City: "Chandapura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560080": [
    {
      City: "Sadashivanagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560079": [
    {
      City: "Basaveshwaranagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "K H B Colony",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kamakshipalya",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560078": [
    {
      City: "J P Nagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "JP Nagar III Phase",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Yelachenahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560077": [
    {
      City: "Doddagubbi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Dr. Shivarama Karanth Nagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kothanur",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560076": [
    {
      City: "Bannerghatta Road",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hulimavu",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "JP Nagar VIII phase",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mico Layout",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mount St Joseph",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560075": [
    {
      City: "Jeevanbhimanagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "New Thippasandra",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560074": [
    {
      City: "Kumbalagodu",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kumbalgodu Gollahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Ramohalli",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560073": [
    {
      City: "Bagalgunte",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "BAGALGUNTE",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Chikkabidarkal",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Nagasandra (Bangalore)",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Nelakadiranahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560072": [
    {
      City: "Nagarbhavi II Stage PO",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Nagarbhavi",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560071": [
    {
      City: "Domlur",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560070": [
    {
      City: "B Sk II Stage",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Padmanabhnagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Thyagarajnagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560068": [
    {
      City: "Bommanahalli (Bangalore)",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Madivala",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Singasandra",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560067": [
    {
      City: "Kadugodi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Naduvathi",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560066": [
    {
      Pincode: 560066,
      City: "E P I P",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Whitefield",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560065": [
    {
      City: "G K V K",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560064": [
    {
      City: "Attur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "CRPF Campus Yelahanka",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Jakkur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Rajanakunte",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Singanayakanahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Yelahanka",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Yelahanka Satellite Town",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560063": [
    {
      City: "A F Station Yelahanka",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "BSF Campus Yelahanka",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560062": [
    {
      City: "Doddakallasandra",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Konanakunte",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560061": [
    {
      City: "Chikkalasandra",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Subramanyapura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560060": [
    {
      City: "Chudenapura",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kengeri",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Sulikere",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560059": [
    {
      City: "Rv Niketan",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560058": [
    {
      City: "Laggere",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Peenya I Stage",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Peenya Small Industries",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560057": [
    {
      City: "Peenya Dasarahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560056": [
    {
      City: "Bnagalore Viswavidalaya",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mallathahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560055": [
    {
      City: "Malleswaram West",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560054": [
    {
      City: "Mathikere",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Msrit",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Sri Chowdeshwari",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560053": [
    {
      City: "Chickpet",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560051": [
    {
      Pincode: 560051,
      City: "H K P Road",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560050": [
    {
      City: "Ashoknagar (Bangalore)",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Banashankari",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Dasarahalli(Srinagar)",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "State Bank Of Mysuru Colony",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560049": [
    {
      City: "Bhattarahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Bidrahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mandur",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Virgonagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560048": [
    {
      City: "Hoodi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mahadevapura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560047": [
    {
      Pincode: 560047,
      City: "Austin Town",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Viveknagar (Bangalore)",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560046": [
    {
      City: "Benson Town",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560045": [
    {
      City: "Arabic College",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560043": [
    {
      City: "Banaswadi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Jalavayuvihar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kalyananagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560042": [
    {
      City: "Sivan Chetty Gardens",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560041": [
    {
      City: "Jayanagar East",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Jayanagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Tilaknagar (Bangalore)",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560040": [
    {
      City: "Chandra Lay Out",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Vijayanagar East",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Vijayanagar (Bangalore)",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560038": [
    {
      City: "Indiranagar (Bangalore)",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560037": [
    {
      City: "Doddanekkundi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Kundalahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Marathahalli Colony",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Rameshnagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560036": [
    {
      City: "Devasandra",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Krishnarajapuram",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560035": [
    {
      City: "Carmelram",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560034": [
    {
      City: "Agara",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Koramangala I Block",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Koramangala",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "St. Johns Medical College",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560033": [
    {
      City: "Maruthi Sevanagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560032": [
    {
      City: "P and T Colony Kavalbyrasandra",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "R T Nagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560030": [
    {
      City: "Adugodi",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560029": [
    {
      City: "Dharmaram College",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Tavarekere",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560027": [
    {
      City: "Sampangiramnagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Shanthinagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Wilson Garden",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560026": [
    {
      City: "Deepanjalinagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Governmemnt Electric Factory",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Nayandahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560025": [
    {
      City: "CMP Centre And School",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Museum Road",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Richmond Town",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560024": [
    {
      City: "Anandanagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "H A Farm",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Hebbal Kempapura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560023": [
    {
      City: "Magadi Road",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560022": [
    {
      City: "Yeshwanthpur Bazar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Yeswanthpura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560021": [
    {
      City: "Gayathrinagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Srirampuram",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560020": [
    {
      City: "Seshadripuram",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560018": [
    {
      City: "Chamrajpet (Bangalore)",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Goripalya",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560017": [
    {
      City: "N A L",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Vimanapura",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560016": [
    {
      City: "Doorvaninagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Krishnarajapuram R S",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Ramamurthy Nagar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560015": [
    {
      City: "Jalahalli West",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560013": [
    {
      City: "Jalahalli East",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Jalahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560012": [
    {
      City: "Science Institute",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560011": [
    {
      City: "Jayangar III Block",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Madhavan Park",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560010": [
    {
      City: "Industrial Estate (Bangalore)",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Rajajinagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Rajajinagar IVth Block",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560009": [
    {
      City: "Bangalore Dist Offices Bldg",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "K. G. Road",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560008": [
    {
      City: "H A L II Stage",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Halasur Bazar",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560007": [
    {
      City: "Agram",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Air Force Hospital",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560006": [
    {
      City: "J C Nagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Training Command IAF",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560005": [
    {
      City: "Fraser Town",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560004": [
    {
      City: "Basavanagudi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Gaviopuram Extension",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mavalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Pampamahakavi Road",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560003": [
    {
      City: "Malleswaram",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Palace Guttahalli",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Swimming Pool Extn",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Vyalikaval Extn",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560002": [
    {
      City: "Bangalore Corporation Building",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Bangalore City",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
  "560001": [
    {
      City: "Bangalore G.P.O.",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "CMM Court Complex",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Dr. Ambedkar Veedhi",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "HighCourt",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Legislators Home",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Mahatma Gandhi Road",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Rajbhavan",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Vasanthanagar",
      District: "Bangalore",
      State: "Karnataka",
    },
    {
      City: "Vidhanaudha",
      District: "Bangalore",
      State: "Karnataka",
    },
  ],
};
