import React from "react";
import { useNavigate } from "react-router-dom";

import "./FaqPage.scss";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

const faqItems = [
  {
  category: "service",
    heading: "How can I book a service for my vehicle?",
    content: "You can book a service for your vehicle through our website or by clicking the 'Book Now' button.",
  },
  {
  category: "service",
    heading: "What types of services do you offer?",
    content: "We offer periodic services, repair services, accidental damage fixes, tyre replacements, battery replacements, and inspections.",
  },
  {
  category: "service",
    heading: "Which vehicle makes and models do you cater to?",
    content: "We cater to all makes and models (less than 500 CC) across various brands, including Honda, Hero, Bajaj, TVS, Suzuki, Yamaha, Royal Enfield, Piaggio, and Aprilia.",
  },
  {
  category: "service",
    heading: "Can I select a service plan that suits my needs?",
    content: "Yes, for periodic service, we offer multiple packages suitable for your motorcycle or scooter usage. You can select the plan that fits your requirements.",
  },
  {
  category: "service",
    heading: "How do I select the date and time for my service?",
    content: "You can fill in your preferred date and time during the booking process. However, service bookings are subject to slot availability, and if your preferred slot is not available, our service team will contact you to reschedule.",
  },
  {
  category: "service",
      heading: "What should I do if I have specific requirements for spares or repairs?",
      content: "You can mention your specific requirements during the booking process. This helps us ensure that we bring the necessary spares for your appointment.",
  },
  {
  category: "service",
      heading: "What if I don't know what's wrong with my vehicle?",
      content: "If you're unsure of the problem, you can simply describe the symptoms or complaints you're experiencing. This information will help us diagnose and address the issue during the first visit.",
  },
  {
  category: "service",
      heading: "How can I ensure accurate communication and updates?",
      content: "Please provide accurate details such as your mobile number, alternate number, address, and email ID during the booking process. This allows us to send status updates, estimate approvals, and invoice details to you.",
  },
  {
  category: "service",
      heading: "How will I know that my booking is confirmed?",
      content: "Once your booking is complete, you will receive a confirmation SMS and email.",
  },
  {
  category: "service",
      heading: "Do you provide service delivery in my parking area?",
      content: "Yes, our team will visit your parking area on the approved date and time to deliver the service.",
  },
  {
  category: "service",
      heading: "What happens if my preferred date and time slot is not available?",
      content: "In such cases, you will receive an appointment call to discuss alternative options.",
  },
  {
  category: "service",
      heading: "Will the assigned mechanic be skilled in handling my vehicle's make and model?",
      content: "Yes, we ensure that the assigned mechanic has the necessary skills to handle your motorcycle or scooter's make, model, and service requirements.",
  },
  {
  category: "service",
      heading: "Is there a warranty for the service provided?",
      content: "Yes, we offer a 15-day service warranty on the workmanship performed during the service. However, it does not cover performance issues resulting from suggested repairs not approved by you. The warranty on spares follows the manufacturer's warranty guidelines.",
  },
  {
  category: "service",
      heading: "How can I make the payment for the service?",
      content: "You will receive a payment link for the invoice amount, which can be paid through the provided link.",
  },
  {
  category: "service",
      heading: "Can I provide feedback about my service experience?",
      content: "Yes, you will receive a feedback link along with the invoice. We appreciate your feedback as it helps us improve our services.",
  },
  {
  category: "service",
      heading: "Do you provide free pickup and drop-off service?",
      content: "Yes, we offer the convenience of free pickup and drop-off service for your vehicle.",
  }
];

const FaqPage = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="faqpage">
      <div className="container">
        <span className="faqpage__badge">FAQ</span>
        <div className="faqpage__block">
          <div className="faqpage__left">
            <div className="faqpage__title">Frequently Asked Questions</div>
            <div className="faqpage__text">
              Flex is the only saas business platform that lets you run your
              business on one platform, seamlessly across all digital channels.
            </div>

            <div className="faqpage-accordion">
              <Accordion allowZeroExpanded>
                {faqItems.map((item, index) => (
                  <AccordionItem key={index}>
                    <AccordionItemHeading>
                      <AccordionItemButton>{item.heading}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>{item.content}</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </div>
        
        <div className="faqpage__right">
          <img
            className="faqpage__img"
            src="../../../images/faq-right.svg"
            alt="faq"
          />
        </div>
        </div>
      </div>
      <div className="getintouch">
      <div className="container getintouch__block">
        <h1>Have any additional questions?</h1>
        <p>Flex is a Small SaaS Business. Flex isn’t a traditional company.</p>
        <button
          className="button"
          onClick={() => {
            navigate("/contact");
          }}
        >
          Get in touch
        </button>
      </div>
    </div>
    </div>
  );
};

export default FaqPage;
