import { MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import {
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
} from "../../utils/helpers/requestController";
import { useNavigate, useParams } from "react-router-dom";
import { apiEndPoints } from "../../utils/helpers/endPoints";
import {
  LabourDetails,
  LabourDetailsErr,
  PartDetails,
  PartDetailsErr,
} from "../../utils/models/invoice";

const UploadInvoice = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [table, setTable] = useState<any>([]);
  const [invoice, setInvoice] = useState<any>();
  const [isEdit, setIsEdit] = useState<number>();
  const [jobSheet, setJobSheet] = useState<any>();
  const [tableLabour, setTableLabour] = useState<any>([]);
  const [parts, setParts] = useState<any>(new PartDetails());
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [enablePart, setEnablePart] = useState<boolean>(false);
  const [isEditInvoice, setIsEditInvoice] = useState<any>(false);
  const [labour, setLabour] = useState<any>(new LabourDetails());
  const [enableLabour, setEnableLabour] = useState<boolean>(false);
  const [partsError, setPartsError] = useState<any>(new PartDetailsErr());
  const [labourError, setLabourError] = useState<any>(new LabourDetailsErr());

  const handleInputChange = (e: any) => {
    const partsObj = Object.assign({}, parts);
    partsObj[e.target.name] = e.target.value;
    setParts(partsObj);
  };

  const deletePartCard = (index: number) => {
    const tableObj = table.slice();
    tableObj.splice(index, 1);
    setTable(tableObj);
  };
  const deleteLabourCard = (index: number) => {
    const tableObj = tableLabour.slice();
    tableObj.splice(index, 1);
    setTableLabour(tableObj);
  };
  const handleError = (e: any) => {
    const partsObj = Object.assign({}, partsError);
    partsObj[e.target.name] = !(e.target.value.trim().length > 0);
    setPartsError(partsObj);
  };

  const handleErrorLabour = (e: any) => {
    const partsObj = Object.assign({}, labourError);
    partsObj[e.target.name] = !(e.target.value.trim().length > 0);
    setLabourError(partsObj);
  };

  useEffect(() => {
    const dataObbj = Object.assign({}, labour);
    if (
      dataObbj?.hsnCode?.length > 0 &&
      dataObbj?.sdCode?.length > 0 &&
      dataObbj?.partName?.length > 0 &&
      dataObbj?.price?.length > 0 &&
      dataObbj?.quantity?.length > 0 &&
      dataObbj?.discountPercentage?.length > 0 &&
      dataObbj?.gstPercentage?.length > 0
    ) {
      setEnableLabour(true);
    } else {
      setEnableLabour(false);
    }
  }, [labour]);

  useEffect(() => {
    const dataObbj = Object.assign({}, parts);
    if (
      dataObbj?.hsnCode?.length > 0 &&
      dataObbj?.sdCode?.length > 0 &&
      dataObbj?.partName?.length > 0 &&
      dataObbj?.price?.length > 0 &&
      dataObbj?.quantity?.length > 0 &&
      dataObbj?.discountPercentage?.length > 0 &&
      dataObbj?.gstPercentage?.length > 0
    ) {
      setEnablePart(true);
    } else {
      setEnablePart(false);
    }
  }, [parts]);

  useEffect(() => {
    getJobSheetDetails();
    getInvoiceDetails();
  }, []);

  useEffect(() => {
    if (table.length > 0 && tableLabour.length > 0) {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }
  }, [table, tableLabour]);

  const handleInputLabourChange = (e: any) => {
    const partsObj = Object.assign({}, labour);
    partsObj[e.target.name] = e.target.value;
    setLabour(partsObj);
  };
  const handleEdited = (e: any, index: any) => {
    const tableObj = table.slice();
    tableObj[index][e.target.name] = e.target.value;
    setTable(tableObj);
  };
  const handleEditedLabour = (e: any, index: any) => {
    const tableObj = tableLabour.slice();
    tableObj[index][e.target.name] = e.target.value;
    setTableLabour(tableObj);
  };

  const handleAdd = () => {
    setTable([...table, parts]);
    setParts({
      sdCode: "",
      hsnCode: "",
      partName: "",
      quantity: "",
      gstPercentage: "",
      price: "",
      discountPercentage: "",
    });
  };

  const handleAddLabour = () => {
    setTableLabour([...tableLabour, labour]);
    setLabour({
      sdCode: "",
      hsnCode: "",
      partName: "",
      quantity: "",
      gstPercentage: "",
      price: "",
      discountPercentage: "",
    });
  };

  const saveJob = async () => {
    const jobObj = { parts: table, labours: tableLabour };
    if (!isEditInvoice) {
      const res = await sendPostRequest(
        `${apiEndPoints.invoice}/${params.id}/createInvoice`,
        jobObj
      );
      if (res) {
        navigate(-1);
      }
    } else {
      const res = await sendPutRequest(
        `${apiEndPoints.invoice}/${params.id}/createInvoice`,
        jobObj
      );
      if (res) {
        navigate(-1);
      }
    }
  };
  const getJobSheetDetails = async () => {
    const res = await sendGetRequest(`${apiEndPoints.invoice}/${params.id}`);
    if (res && res?.card) {
      // setTable(res.card);
      setJobSheet(res);
    }
  };
  const getInvoiceDetails = async () => {
    const res = await sendGetRequest(
      `${apiEndPoints.invoice}/${params.id}/getInvoice`
    );
    if (res && res?.parts) {
      setIsEditInvoice(true);
      // setTable(res.card);
      setInvoice(res);
      setTable(res.parts);
      setTableLabour(res.labours);
    }
  };

  return (
    <div>
      <div
        className="container invoice-page"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="container_row">
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              Customer Name*:
            </label>
            <div className="jobsheet__text">
              {invoice?.name || jobSheet?.name}
            </div>
          </div>
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              Service Consultant*:
            </label>
            <div className="jobsheet__text">
              {invoice?.serviceConsultant || jobSheet?.serviceConsultant}
            </div>
          </div>
        </div>
        <div className="container_row">
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              address*:
            </label>
            <div className="jobsheet__text">
              {invoice?.name || jobSheet?.mobile}
            </div>
          </div>
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              Fuel Percentage*:
            </label>
            <div className="jobsheet__text">
              {invoice?.fuelPercentage || jobSheet?.fuelPercentage}
            </div>
          </div>
        </div>
        <div className="container_row">
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              Registration Number*:
            </label>
            <div className="jobsheet__text">
              {" "}
              {invoice?.reg || jobSheet?.reg}
            </div>
          </div>
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              Job Card No*:
            </label>
            <div className="jobsheet__text">{jobSheet?._id}</div>
          </div>
        </div>
        <div className="container_row">
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              Odometer Reading*:
            </label>
            <div className="jobsheet__text">
              {invoice?.odo || jobSheet?.odo}
            </div>
          </div>
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              Jobcard Date*:
            </label>
            <div className="jobsheet__text">
              {
                new Date(jobSheet?.createdAt)
                  .toLocaleString("en-GB")
                  .split(",")[0]
              }
            </div>
          </div>
        </div>
        <div className="container_row">
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              Chassis Number*:
            </label>
            <div className="jobsheet__text">
              {invoice?.chassisNumber || jobSheet?.chassisNumber}
            </div>
          </div>
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              Engine Number*:
            </label>
            <div className="jobsheet__text">
              {invoice?.engineNumber || jobSheet?.engineNumber}
            </div>
          </div>
        </div>
        <hr className="horizontal" />
        <div className="service__title" style={{ fontSize: "16px" }}>
          Parts
        </div>
        <form>
          <div className="container_row" style={{ flexWrap: "wrap" }}>
            <div className="form__group_job">
              <label htmlFor="custName" className="form__label">
                SD Code*:
              </label>
              <div>
                <input
                  type="text"
                  name="sdCode"
                  className="form__input"
                  placeholder="Add SD Code"
                  onChange={handleInputChange}
                  value={parts?.sdCode}
                  onBlur={handleError}
                />
                {partsError.sdCode && (
                  <>
                    <div className="form__input-error">
                      <p>Please enter SD Code.</p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="form__group_job">
              <label htmlFor="custName" className="form__label">
                HSN Code*:
              </label>
              <div>
                <input
                  type="text"
                  name="hsnCode"
                  className="form__input"
                  placeholder="Add HSN Code"
                  onChange={handleInputChange}
                  value={parts?.hsnCode}
                  onBlur={handleError}
                />
                {partsError.hsnCode && (
                  <>
                    <div className="form__input-error">
                      <p>Please enter HSN Code.</p>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="form__group_job">
              <label htmlFor="custName" className="form__label">
                Part Name*:
              </label>
              <div>
                <input
                  type="text"
                  name="partName"
                  className="form__input"
                  placeholder="Add Part Name"
                  onChange={handleInputChange}
                  value={parts?.partName}
                  onBlur={handleError}
                />
                {partsError.partName && (
                  <>
                    <div className="form__input-error">
                      <p>Please enter Part Name.</p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="form__group_job">
              <label htmlFor="custName" className="form__label">
                Price*:
              </label>
              <div>
                <input
                  type="number"
                  name="price"
                  className="form__input"
                  placeholder="Add Price"
                  onChange={handleInputChange}
                  value={parts?.price}
                  onBlur={handleError}
                />
                {partsError.price && (
                  <>
                    <div className="form__input-error">
                      <p>Please enter Price.</p>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="form__group_job">
              <label htmlFor="custName" className="form__label">
                Quantity*:
              </label>
              <div>
                <input
                  type="number"
                  name="quantity"
                  className="form__input"
                  placeholder="Add Quantity"
                  onChange={handleInputChange}
                  value={parts?.quantity}
                  onBlur={handleError}
                />
                {partsError.quantity && (
                  <>
                    <div className="form__input-error">
                      <p>Please enter Quantity.</p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="form__group_job">
              <label htmlFor="custName" className="form__label">
                Discount(%)*:
              </label>
              <div>
                <input
                  type="number"
                  name="discountPercentage"
                  value={parts?.discountPercentage}
                  onChange={handleInputChange}
                  className="form__input"
                  placeholder="Add Discount"
                  onBlur={handleError}
                />
                {partsError.discountPercentage && (
                  <>
                    <div className="form__input-error">
                      <p>Please enter Discount Percentage.</p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="form__group_job">
              <label htmlFor="custName" className="form__label">
                GST(%)*:
              </label>
              <div>
                <input
                  type="number"
                  name="gstPercentage"
                  className="form__input"
                  placeholder="Add GST"
                  onChange={handleInputChange}
                  onBlur={handleError}
                  value={parts?.gstPercentage}
                />
                {partsError.gstPercentage && (
                  <>
                    <div className="form__input-error">
                      <p>Please enter GST Percentage.</p>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="form__group_job">
              <button
                // className="button buttonn--enabled"
                onClick={handleAdd}
                type="button"
                className={
                  Object.values(partsError).includes(true) || !enablePart
                    ? "button button--disabled"
                    : "button buttonn--enabled"
                }
                disabled={
                  Object.values(partsError).includes(true) || !enablePart
                }
              >
                Add
              </button>
            </div>

            <Table responsive="sm">
              <thead>
                <tr>
                  <th>SL No.</th>
                  <th>SD Code</th>
                  <th>HSN Code</th>
                  <th>Part Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Base Price</th>
                  <th>Discount(%)</th>
                  <th>Discount</th>
                  <th>GST(%)</th>
                  <th>GST</th>
                  <th>Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {table?.map((data: any, index: any) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>

                        {isEdit === index ? (
                          <td>
                            <input
                              type="text"
                              name="sdCode"
                              className="form__input"
                              style={{ width: "100%" }}
                              placeholder="Add SD Code"
                              value={data?.sdCode}
                              onChange={(e) => {
                                handleEdited(e, index);
                              }}
                            />
                          </td>
                        ) : (
                          <td>{data.sdCode}</td>
                        )}
                        {isEdit === index ? (
                          <td>
                            <input
                              type="text"
                              name="hsnCode"
                              className="form__input"
                              style={{ width: "100%" }}
                              placeholder="Add Hsn Code"
                              value={data?.hsnCode}
                              onChange={(e) => {
                                handleEdited(e, index);
                              }}
                            />
                          </td>
                        ) : (
                          <td>{data.hsnCode}</td>
                        )}
                        {isEdit === index ? (
                          <td>
                            <input
                              type="text"
                              name="partName"
                              className="form__input"
                              style={{ width: "100%" }}
                              placeholder="Add Part Name"
                              value={data?.partName}
                              onChange={(e) => {
                                handleEdited(e, index);
                              }}
                            />
                          </td>
                        ) : (
                          <td>{data.partName}</td>
                        )}
                        {isEdit === index ? (
                          <td>
                            <input
                              type="number"
                              name="price"
                              className="form__input"
                              style={{ width: "100%" }}
                              placeholder="Add Price"
                              value={data?.price}
                              onChange={(e) => {
                                handleEdited(e, index);
                              }}
                            />
                          </td>
                        ) : (
                          <td>{data.price}</td>
                        )}
                        {isEdit === index ? (
                          <td>
                            <input
                              type="number"
                              name="quantity"
                              className="form__input"
                              style={{ width: "100%" }}
                              placeholder="Add Quantity"
                              value={data?.quantity}
                              onChange={(e) => {
                                handleEdited(e, index);
                              }}
                            />
                          </td>
                        ) : (
                          <td>{data.quantity}</td>
                        )}

                        <td>{data.price * data.quantity}</td>
                        {isEdit === index ? (
                          <td>
                            <input
                              type="number"
                              name="discountPercentage"
                              className="form__input"
                              style={{ width: "100%" }}
                              placeholder="Add Discount"
                              value={data?.discountPercentage}
                              onChange={(e) => {
                                handleEdited(e, index);
                              }}
                            />
                          </td>
                        ) : (
                          <td>{data.discountPercentage}</td>
                        )}
                        <td>
                          {(data?.discountPercentage / 100) *
                            (data.price * data.quantity)}
                        </td>
                        {isEdit === index ? (
                          <td>
                            <input
                              type="number"
                              name="gstPercentage"
                              className="form__input"
                              style={{ width: "100%" }}
                              placeholder="Add Discount"
                              value={data?.gstPercentage}
                              onChange={(e) => {
                                handleEdited(e, index);
                              }}
                            />
                          </td>
                        ) : (
                          <td>{data.gstPercentage}</td>
                        )}
                        <td>
                          {(
                            (data.price * data.quantity -
                              (data?.discountPercentage / 100) *
                                (data.price * data.quantity)) *
                            (data.gstPercentage / 100)
                          ).toFixed(3)}
                        </td>
                        <td>
                          {(
                            data.price * data.quantity -
                            (data?.discountPercentage / 100) *
                              (data.price * data.quantity) +
                            (data.price * data.quantity -
                              (data?.discountPercentage / 100) *
                                (data.price * data.quantity)) *
                              (data.gstPercentage / 100)
                          ).toFixed(3)}
                        </td>
                        <td className="table-action">
                          <div className="action-container">
                            <button
                              className="button button--outline table"
                              onClick={() => {
                                isEdit === index
                                  ? setIsEdit(undefined)
                                  : setIsEdit(index);
                              }}
                            >
                              {isEdit === index ? "Save" : "Edit"}
                            </button>

                            <button
                              className="button button--outline table"
                              onClick={() => deletePartCard(index)}
                              type="button"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </>
              </tbody>
            </Table>
          </div>
        </form>
        <hr className="horizontal" />
        <div className="service__title" style={{ fontSize: "16px" }}>
          Labour
        </div>
        <div className="container_row" style={{ flexWrap: "wrap" }}>
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              SD Code*:
            </label>
            <div>
              <input
                type="text"
                name="sdCode"
                className="form__input"
                placeholder="Add SD Code"
                onChange={handleInputLabourChange}
                value={labour?.sdCode}
                onBlur={handleErrorLabour}
              />
              {labourError.sdCode && (
                <>
                  <div className="form__input-error">
                    <p>Please enter SD Code.</p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              HSN Code*:
            </label>
            <div>
              <input
                type="text"
                name="hsnCode"
                className="form__input"
                placeholder="Add HSN Code"
                onChange={handleInputLabourChange}
                value={labour?.hsnCode}
                onBlur={handleErrorLabour}
              />
              {labourError.hsnCode && (
                <>
                  <div className="form__input-error">
                    <p>Please enter HSN Code.</p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              Part Name*:
            </label>
            <div>
              <input
                type="text"
                name="partName"
                className="form__input"
                placeholder="Add Part Name"
                value={labour?.partName}
                onChange={handleInputLabourChange}
                onBlur={handleErrorLabour}
              />
              {labourError.partName && (
                <>
                  <div className="form__input-error">
                    <p>Please enter Part Name.</p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              Price*:
            </label>
            <div>
              <input
                type="number"
                name="price"
                className="form__input"
                placeholder="Add Price"
                onChange={handleInputLabourChange}
                value={labour?.price}
                onBlur={handleErrorLabour}
              />
              {labourError.price && (
                <>
                  <div className="form__input-error">
                    <p>Please enter Price.</p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              Quantity(Hours)*:
            </label>
            <div>
              <input
                type="number"
                name="quantity"
                className="form__input"
                placeholder="Add Quantity"
                value={labour?.quantity}
                onChange={handleInputLabourChange}
                onBlur={handleErrorLabour}
              />
              {labourError.quantity && (
                <>
                  <div className="form__input-error">
                    <p>Please enter Quantity.</p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              Discount(%)*:
            </label>
            <div>
              <input
                type="number"
                name="discountPercentage"
                onChange={handleInputLabourChange}
                className="form__input"
                placeholder="Add Discount"
                value={labour?.discountPercentage}
                onBlur={handleErrorLabour}
              />
              {labourError.discountPercentage && (
                <>
                  <div className="form__input-error">
                    <p>Please enter Discount Percentage.</p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="form__group_job">
            <label htmlFor="custName" className="form__label">
              GST(%)*:
            </label>
            <div>
              <input
                type="number"
                name="gstPercentage"
                className="form__input"
                placeholder="Add GST"
                onChange={handleInputLabourChange}
                value={labour?.gstPercentage}
                onBlur={handleErrorLabour}
              />
              {labourError.gstPercentage && (
                <>
                  <div className="form__input-error">
                    <p>Please enter GST Percentage.</p>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="form__group_job">
            <button
              // className="button buttonn--enabled"
              onClick={handleAddLabour}
              className={
                Object.values(labourError).includes(true) || !enableLabour
                  ? "button button--disabled"
                  : "button buttonn--enabled"
              }
              disabled={
                Object.values(labourError).includes(true) || !enableLabour
              }
            >
              Add
            </button>
          </div>

          <Table responsive="sm">
            <thead>
              <tr>
                <th>SL No.</th>
                <th>SD Code</th>
                <th>HSN Code</th>
                <th>Part Name</th>
                <th>Price</th>
                <th>Quantity(Hours)</th>
                <th>Base Price</th>
                <th>Discount(%)</th>
                <th>Discount</th>
                <th>GST(%)</th>
                <th>GST</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <>
                {tableLabour?.map((data: any, index: any) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>

                      {isEdit === index ? (
                        <td>
                          <input
                            type="text"
                            name="sdCode"
                            className="form__input"
                            style={{ width: "100%" }}
                            placeholder="Add SD Code"
                            value={data?.sdCode}
                            onChange={(e) => {
                              handleEditedLabour(e, index);
                            }}
                          />
                        </td>
                      ) : (
                        <td>{data.sdCode}</td>
                      )}
                      {isEdit === index ? (
                        <td>
                          <input
                            type="text"
                            name="hsnCode"
                            className="form__input"
                            style={{ width: "100%" }}
                            placeholder="Add Hsn Code"
                            value={data?.hsnCode}
                            onChange={(e) => {
                              handleEditedLabour(e, index);
                            }}
                          />
                        </td>
                      ) : (
                        <td>{data.hsnCode}</td>
                      )}
                      {isEdit === index ? (
                        <td>
                          <input
                            type="text"
                            name="partName"
                            className="form__input"
                            style={{ width: "100%" }}
                            placeholder="Add Part Name"
                            value={data?.partName}
                            onChange={(e) => {
                              handleEditedLabour(e, index);
                            }}
                          />
                        </td>
                      ) : (
                        <td>{data.partName}</td>
                      )}
                      {isEdit === index ? (
                        <td>
                          <input
                            type="text"
                            name="price"
                            className="form__input"
                            style={{ width: "100%" }}
                            placeholder="Add Price"
                            value={data?.price}
                            onChange={(e) => {
                              handleEditedLabour(e, index);
                            }}
                          />
                        </td>
                      ) : (
                        <td>{data.price}</td>
                      )}
                      {isEdit === index ? (
                        <td>
                          <input
                            type="text"
                            name="quantity"
                            className="form__input"
                            style={{ width: "100%" }}
                            placeholder="Add Quantity"
                            value={data?.quantity}
                            onChange={(e) => {
                              handleEditedLabour(e, index);
                            }}
                          />
                        </td>
                      ) : (
                        <td>{data.quantity}</td>
                      )}

                      <td>{data.price * data.quantity}</td>
                      {isEdit === index ? (
                        <td>
                          <input
                            type="text"
                            name="discountPercentage"
                            className="form__input"
                            style={{ width: "100%" }}
                            placeholder="Add Discount"
                            value={data?.discountPercentage}
                            onChange={(e) => {
                              handleEditedLabour(e, index);
                            }}
                          />
                        </td>
                      ) : (
                        <td>{data.discountPercentage}</td>
                      )}
                      <td>
                        {(data?.discountPercentage / 100) *
                          (data.price * data.quantity)}
                      </td>
                      {isEdit === index ? (
                        <td>
                          <input
                            type="text"
                            name="gstPercentage"
                            className="form__input"
                            style={{ width: "100%" }}
                            placeholder="Add Discount"
                            value={data?.gstPercentage}
                            onChange={(e) => {
                              handleEditedLabour(e, index);
                            }}
                          />
                        </td>
                      ) : (
                        <td>{data.gstPercentage}</td>
                      )}
                      <td>
                        {(
                          (data.price * data.quantity -
                            (data?.discountPercentage / 100) *
                              (data.price * data.quantity)) *
                          (data.gstPercentage / 100)
                        ).toFixed(3)}
                      </td>
                      <td>
                        {(
                          data.price * data.quantity -
                          (data?.discountPercentage / 100) *
                            (data.price * data.quantity) +
                          (data.price * data.quantity -
                            (data?.discountPercentage / 100) *
                              (data.price * data.quantity)) *
                            (data.gstPercentage / 100)
                        ).toFixed(3)}
                      </td>
                      <td className="table-action">
                        <div className="action-container">
                          <button
                            className="button button--outline table"
                            onClick={() => {
                              isEdit === index
                                ? setIsEdit(undefined)
                                : setIsEdit(index);
                            }}
                          >
                            {isEdit === index ? "Save" : "Edit"}
                          </button>

                          <button
                            className="button button--outline table"
                            onClick={() => deleteLabourCard(index)}
                            type="button"
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="button-container invoice-page">
        <button
          //   className={
          //     Object.values(serviceErr).includes(true) || !enable
          //       ? "button button--disabled"
          //       : "button buttonn--enabled"
          //   }
          className={
            enableSave ? "button button--enabled" : "button button--disabled"
          }
          onClick={saveJob}
          disabled={!enableSave}
        >
          Save
        </button>

        <button
          className="button button--outline"
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UploadInvoice;
