import React, { useEffect, useState } from "react";
import "./UploadJobsheet.scss";
import {
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
} from "../../utils/helpers/requestController";
import { apiEndPoints } from "../../utils/helpers/endPoints";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Delete, Edit } from "../../assets/Icons";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import dayjs from "dayjs";
import {
  jobsheetDetails,
  jobsheetDetailsErr,
} from "../../utils/models/jobsheet";

const descOption = [
  {
    value: "CHECKED",
    label: "C",
  },
  {
    value: "NA",
    label: "NA",
  },
  {
    value: "REPLACED",
    label: "R",
  },
  {
    value: "TOPUP",
    label: "T",
  },
  {
    value: "ADJUSTED",
    label: "A",
  },
  {
    value: "Rework",
    label: "W",
  },
];

const UploadJobsheet = () => {
  const [title, setTitle] = useState<any>();
  const [desc, setDesc] = useState<any>();
  const [table, setTable] = useState<any>([]);
  const [packages, setPackage] = useState<any>();
  const [isEditJobSheet, setIsEditJobSheet] = useState<any>(false);
  const [isEdit, setIsEdit] = useState<any>();
  const [enable, setEnable] = useState<any>();
  const params = useParams();
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState<jobsheetDetails>(
    new jobsheetDetails()
  );

  const [serviceErr, setServiceErr] = useState<jobsheetDetailsErr>(
    new jobsheetDetailsErr()
  );

  useEffect(() => {
    const dataObbj = Object.assign({}, jobDetails);
    if (
      dataObbj?.name?.length > 0 &&
      dataObbj?.odo?.length > 0 &&
      dataObbj?.brand?.length > 0 &&
      dataObbj?.model?.length > 0 &&
      dataObbj?.package?.length > 0 &&
      dataObbj?.reg?.length > 0 &&
      dataObbj?.mobile?.length > 0 &&
      dataObbj?.fuelPercentage?.length > 0 &&
      dataObbj?.emission?.length > 0 &&
      dataObbj?.emissionExpiryDate &&
      dataObbj?.insurance?.length > 0 &&
      dataObbj?.insuranceExpiryDate &&
      dataObbj?.chassisNumber?.length > 0 &&
      dataObbj?.engineNumber?.length > 0 &&
      dataObbj?.recipientGST?.length > 0 &&
      dataObbj?.serviceConsultant?.length > 0 &&
      table?.length > 0
    ) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [jobDetails, table]);

  const getUserDetails = async () => {
    const res = await sendGetRequest(
      `${apiEndPoints.createService}/${params.id}`
    );
    const dataObj = Object.assign({}, jobDetails);
    dataObj.name = res?.usersInfo?.name;
    dataObj.mobile = res?.usersInfo?.contact;
    dataObj.brand = res?.vehicleInfo?.brand?.name;
    dataObj.model = res?.vehicleInfo?.model?.name;
    dataObj.reg = res?.vehicleInfo?.vehicleNo;
    dataObj.orderId = res?._id;
    dataObj.package = res?.packages;
    setJobDetails({ ...jobDetails, ...dataObj });
    getServicePakage({ ...jobDetails, ...dataObj });
    getJobSheetDetails();
  };

  const getJobSheetDetails = async () => {
    const res = await sendGetRequest(`${apiEndPoints.invoice}/${params.id}`);
    if (res && res?.card) {
      setTable(res.card);
      setJobDetails(res);
      setIsEditJobSheet(true);
    }
  };
  const getServicePakage = async (jobDetail: any) => {
    const res = await sendGetRequest(`${apiEndPoints.servicePackage}`);
    setPackage(res?.filter((res: any) => res._id === jobDetail?.package));
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleAdd = () => {
    const addObj = { title: title, desc: desc };
    setTable([...table, addObj]);
    setTitle("");
    setDesc("");
  };

  const handleDataChange = (e: any) => {
    const dataObj = Object.assign({}, jobDetails);
    switch (e.target.name) {
      case "fuel-level":
        dataObj.fuelPercentage = e.target.value;
        break;
      case "odo-level":
        dataObj.odo = e.target.value;
        break;
      case "emission":
        dataObj.emission = e.target.value;
        break;
      case "insurance":
        dataObj.insurance = e.target.value;
        break;
      case "consultant":
        dataObj.serviceConsultant = e.target.value;
        break;
      case "chassis-no":
        dataObj.chassisNumber = e.target.value;
        break;
      case "recepient-gst":
        dataObj.recipientGST = e.target.value;
        break;
      case "engine-no":
        dataObj.engineNumber = e.target.value;
        break;
    }

    setJobDetails(dataObj);
  };

  const saveJob = async () => {
    const jobObj = Object.assign({}, jobDetails);
    jobObj.card = table;
    if (!isEditJobSheet) {
      const res = await sendPostRequest(
        `${apiEndPoints.invoice}/${params.id}`,
        jobObj
      );
      if (res) {
        navigate(-1);
      }
    } else {
      const res = await sendPutRequest(
        `${apiEndPoints.invoice}/${params.id}`,
        jobObj
      );
      if (res) {
        navigate(-1);
      }
    }
  };

  const handleError = async (e: any) => {
    const dataObj = Object.assign({}, serviceErr);
    switch (e.target.name) {
      case "fuel-level":
        dataObj.fuelPercentage = e.target.value.trim().length === 0;
        break;
      case "odo-level":
        dataObj.odo = e.target.value.trim().length === 0;
        break;
      case "emission":
        dataObj.emission = e.target.value.trim().length === 0;
        break;
      case "insurance":
        dataObj.insurance = e.target.value.trim().length === 0;
        break;
      case "consultant":
        dataObj.serviceConsultant = e.target.value.trim().length === 0;
        break;
      case "chassis-no":
        dataObj.chassisNumber = e.target.value.trim().length === 0;
        break;
      case "recepient-gst":
        dataObj.recipientGST = e.target.value.trim().length === 0;
        break;
      case "engine-no":
        dataObj.engineNumber = e.target.value.trim().length === 0;
        break;
    }
    setServiceErr(dataObj);
  };
  const handleEdited = (e: any, index: any) => {
    const tableObj = table.slice();
    switch (e.target.name) {
      case "title":
        tableObj[index].title = e.target.value;
        break;
      case "description":
        tableObj[index].desc = e.target.value;
    }
    setTable(tableObj);
  };

  const deleteCard = (index: number) => {
    const tableObj = table.slice();
    tableObj.splice(index, 1);
    setTable(tableObj);
  };

  return (
    <div
      className="container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="container_row">
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Name*:
          </label>
          <div className="jobsheet__text">{jobDetails?.name}</div>
        </div>
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Mobile Number*:
          </label>
          <div className="jobsheet__text">{jobDetails.mobile}</div>
        </div>
      </div>
      <div className="container_row">
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Brand*:
          </label>
          <div className="jobsheet__text">{jobDetails.brand}</div>
        </div>
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Model*:
          </label>
          <div className="jobsheet__text">{jobDetails.model}</div>
        </div>
      </div>
      <div className="container_row">
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Reg No*:
          </label>
          <div className="jobsheet__text capital">{jobDetails.reg}</div>
        </div>
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Order Id*:
          </label>
          <div className="jobsheet__text capital">
            SPDR{jobDetails?.orderId}
          </div>
        </div>
      </div>
      <div className="container_row">
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Fuel Pecentage*:
          </label>
          {/* <div className="jobsheet__text">{userData?.usersInfo?.name}</div> */}
          <div>
            <input
              type="text"
              name="fuel-level"
              id="fuel-level"
              className="form__input"
              placeholder="Add Fuel Level"
              value={jobDetails?.fuelPercentage}
              onChange={handleDataChange}
              onBlur={handleError}
            />
            {serviceErr.fuelPercentage && (
              <>
                <div className="form__input-error">
                  <p>Please enter Fuel percentage.</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Odometer Rd*:
          </label>
          <div>
            <input
              type="text"
              name="odo-level"
              id="odo-level"
              className="form__input"
              placeholder="Add Odo meter Reading"
              value={jobDetails?.odo}
              onChange={handleDataChange}
              onBlur={handleError}
            />
            {serviceErr.odo && (
              <div className="form__input-error">
                <p>Please enter Odometer Rea.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container_row">
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Emission*:
          </label>
          <div>
            <input
              type="text"
              name="emission"
              id="emission"
              className="form__input"
              placeholder="Enter Emission"
              value={jobDetails?.emission}
              onChange={handleDataChange}
              onBlur={handleError}
            />
            {serviceErr.emission && (
              <>
                <div className="form__input-error">
                  <p>Please enter emission.</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="form__group_job">
          <label htmlFor="cdateustName" className="form__label">
            Emission Expiry *:
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disablePast
              label="Emission Expiry date"
              value={
                jobDetails?.emissionExpiryDate &&
                dayjs(jobDetails?.emissionExpiryDate)
              }
              className="time-picker-class"
              onChange={(newVal: any) => {
                const dataObj = Object.assign({}, jobDetails);
                dataObj.emissionExpiryDate = new Date(newVal.$d).toISOString();
                setJobDetails(dataObj);
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="container_row">
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Insurance*:
          </label>
          <div>
            <input
              type="text"
              name="insurance"
              id="insurance"
              className="form__input"
              placeholder="Enter Emission"
              value={jobDetails?.insurance}
              onChange={handleDataChange}
              onBlur={handleError}
            />
            {serviceErr.insurance && (
              <>
                <div className="form__input-error">
                  <p>Please enter insurance.</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="form__group_job">
          <label htmlFor="cdateustName" className="form__label">
            Insurance Expiry *:
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disablePast
              label="Insurance Expiry"
              value={
                jobDetails?.insuranceExpiryDate &&
                dayjs(jobDetails?.insuranceExpiryDate)
              }
              className="time-picker-class"
              onChange={(newVal: any) => {
                const dataObj = Object.assign({}, jobDetails);
                dataObj.insuranceExpiryDate = new Date(newVal.$d).toISOString();
                setJobDetails(dataObj);
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="container_row">
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Service Consultant*:
          </label>
          <div>
            <input
              type="text"
              name="consultant"
              id="consultant"
              className="form__input"
              placeholder="Enter Service Consultant"
              value={jobDetails?.serviceConsultant}
              onChange={handleDataChange}
              onBlur={handleError}
            />
            {serviceErr.serviceConsultant && (
              <>
                <div className="form__input-error">
                  <p>Please enter Service Consultant.</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="form__group_job">
          {/* <div className="form__group_job"> */}
          <label htmlFor="custName" className="form__label">
            Chassis No*:
          </label>
          <div>
            <input
              type="text"
              name="chassis-no"
              id="chassis-no"
              className="form__input"
              placeholder="Enter Chassis"
              value={jobDetails?.chassisNumber}
              onChange={handleDataChange}
              onBlur={handleError}
            />
            {serviceErr.chassisNumber && (
              <>
                <div className="form__input-error">
                  <p>Please enter Chassis number.</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="container_row">
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Recepient GST*:
          </label>
          <div>
            <input
              type="text"
              name="recepient-gst"
              id="recepient-gst"
              className="form__input"
              placeholder="Enter Recepient GST"
              value={jobDetails?.recipientGST}
              onChange={handleDataChange}
              onBlur={handleError}
            />
            {serviceErr.recipientGST && (
              <>
                <div className="form__input-error">
                  <p>Please enter Recepient GST.</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Engine No*:
          </label>
          <div>
            <input
              type="text"
              name="engine-no"
              id="engine-no"
              className="form__input"
              placeholder="Enter Engine No"
              value={jobDetails?.engineNumber}
              onChange={handleDataChange}
              onBlur={handleError}
            />
            {serviceErr.engineNumber && (
              <>
                <div className="form__input-error">
                  <p>Please enter Engine No.</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <hr className="horizontal" />

      <div className="container_row">
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Package*:
          </label>
          <div className="jobsheet__text">{packages && packages[0]?.name}</div>
        </div>
      </div>
      <hr className="horizontal" />
      <div className="container_row">
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Title*:
          </label>
          <input
            type="text"
            name="title"
            id="title"
            className="form__input"
            placeholder="Add Title"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </div>
        <div className="form__group_job">
          <label htmlFor="custName" className="form__label">
            Description*:
          </label>
          <Select
            labelId="demo-simple-select-label"
            name="description"
            id="description"
            placeholder="Add Description"
            className="form__input"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            // onBlur={handleError}
          >
            {descOption.map((option) => (
              <MenuItem value={option.value} key={option.label}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="form__group_job">
          <button
            className="button buttonn--enabled"
            onClick={handleAdd}
            disabled={!title || !desc}
          >
            Add
          </button>
        </div>
      </div>
      <hr className="horizontal" />
      <Table responsive="sm">
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <>
            {table?.map((data: any, index: any) => {
              return (
                <tr>
                  {isEdit === index ? (
                    <td>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        className="form__input"
                        placeholder="Add Title"
                        onChange={(e) => {
                          handleEdited(e, index);
                        }}
                        value={data?.title}
                      />
                    </td>
                  ) : (
                    <td>{data.title}</td>
                  )}
                  {isEdit === index ? (
                    <td>
                      <Select
                        labelId="demo-simple-select-label"
                        name="description"
                        id="description"
                        placeholder="Add Description"
                        className="form__input"
                        value={data?.desc}
                        onChange={(e) => {
                          handleEdited(e, index);
                        }}
                        // onBlur={handleError}
                      >
                        {descOption.map((option) => (
                          <MenuItem value={option.value} key={option.label}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </td>
                  ) : (
                    <td>{data.desc}</td>
                  )}

                  <td className="table-action">
                    <div className="action-container">
                      <button
                        className="button button--outline table"
                        onClick={() => {
                          isEdit === index ? setIsEdit("") : setIsEdit(index);
                        }}
                      >
                        {isEdit === index ? "Save" : "Edit"}
                      </button>

                      <button
                        className="button button--outline table"
                        onClick={() => deleteCard(index)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </>
        </tbody>
      </Table>
      <div className="button-container">
        <button
          className={
            Object.values(serviceErr).includes(true) || !enable
              ? "button button--disabled"
              : "button buttonn--enabled"
          }
          onClick={saveJob}
          disabled={Object.values(serviceErr).includes(true) || !enable}
        >
          Save
        </button>

        <button
          className="button button--outline"
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UploadJobsheet;
