/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-children-prop */
import * as React from "react";
import { ConfirmationDialog, ConfirmationOptions } from "./CustomDialog";

const CustomDialogServiceContext = React.createContext<
  (options: ConfirmationOptions) => Promise<void>
>(Promise.reject);

export const useCustomDialog = () =>
  React.useContext(CustomDialogServiceContext);

export const CustomDialogServiceProvider = ({ children }: any) => {
  const [confirmationState, setConfirmationState] =
    React.useState<ConfirmationOptions | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    setOpen(true);

    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = (reason = "") => {
    setConfirmationState(null);

    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setOpen(false);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setOpen(false);
  };

  React.useEffect(() => {
    window.addEventListener("popstate", () => handleClose());

    return () => {
      window.removeEventListener("popstate", () => handleClose());
    };
  }, []);

  return (
    <>
      <CustomDialogServiceContext.Provider
        value={openConfirmation}
        children={children}
      />
      <ConfirmationDialog
        open={open}
        onSubmit={handleSubmit}
        onClose={handleClose}
        description={[]}
        {...confirmationState}
      ></ConfirmationDialog>
    </>
  );
};
