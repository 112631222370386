import React from "react";
import EmptyService from "../../../../assets/images/no-service.svg";

import "./ServiceHistoryEmpty.scss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
const ServiceHistoryEmpty = () => {
  const navigate = useNavigate();
  const navigateHome = () => {
    navigate("/");
  };
  return (
    <div className="no-history">
      <h1 className="no-history__title">Service History Empty</h1>
      <p className="no-history__text">
        Please select your Vehicle to see service history
      </p>
      <div className="no-history__img">
        {/* <EmptyService /> */}
        <img src={EmptyService} alt="" />
        <div className="align-center">
          <Button type="submit" variant="contained" onClick={navigateHome}>
            Book Service
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ServiceHistoryEmpty;
