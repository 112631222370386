import React from 'react'

import LeftSideBar from "./LeftSideBar/LeftSideBar";
import './AdminDashboard.scss';
import ServiceOrders from './ServiceOrders/ServiceOrders';


const AdminDashboard = () => {

  return (
    <div>
    <div className="dashboard-layout admin">
      <div className="dashboard-layout__left">
        <LeftSideBar />
      </div>
      <div className="dashboard-layout__right">
        <ServiceOrders />
      </div>
    </div>
  </div>
  )
}

export default AdminDashboard





