// Generated by https://quicktype.io

export interface IAccessories {
  code: string;
  hsnCode: number;
  _id?: string;
  addOnsgroup: string;
  name: string;
  vehicleType: string;
  brand: string;
  price: string;
  taxRate: string;
  description: string;
  image: string;
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
}

export interface IAccessoriesError {
  code: boolean;
  hsncode: boolean;
  accessoriesType: boolean;
  type: boolean;
  vehicleType: boolean;
  _id: boolean;
  brand: boolean;
  price: boolean;
  taxRate: boolean;
  image: boolean;
  description: boolean;
}

export class AccessoriesError implements IAccessoriesError {
  code: boolean;
  hsncode: boolean;
  accessoriesType: boolean;
  type: boolean;
  vehicleType: boolean;
  _id: boolean;
  brand: boolean;
  price: boolean;
  description: boolean;
  taxRate: boolean;
  image: boolean;

  constructor(data: any = {}) {
    this.code = data.code || false;
    this.hsncode = data.hsncode || false;
    this.accessoriesType = data.accessoriesType || false;
    this.type = data.type || false;
    this.vehicleType = data.vehicleType || false;
    this._id = data._id || false;
    this.brand = data.brand || false;
    this.description = data.description || false;
    this.taxRate = data.taxRate || false;
    this.price = data.price || false;
    this.image = data.image || false;
  }
}

export class Accessories implements IAccessories {
  code: string;
  hsnCode: number;
  _id?: string;
  addOnsgroup: string;
  name: string;
  vehicleType: string;
  brand: string;
  price: string;
  taxRate: string;
  description: string;
  image: string;
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
  constructor(data: any = {}) {
    this.code = data.code || "";
    this._id = data._id || "";
    this.addOnsgroup = data.addOnsgroup || "";
    this.description = data.description || "";
    this.name = data.name || "";

    this.vehicleType = data.vehicleType || "";
    this.hsnCode = data.hsnCode || null;
    this.brand = data.brand || "";
    this.price = data.price || "";
    this.taxRate = data.taxRate || "";
    this.image = data.image || "";

    this.createdAt = data.createdAt || "";
    this.updatedAt = data.updatedAt || "";
    this.__v = data.__v || "";
  }
}
