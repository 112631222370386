export class Contact {
  public name: string;
  public email: string;
  public phone: string;
  public message: string;
  constructor(data: any = {}) {
    this.name = data.name || "";
    this.email = data.email || "";
    this.phone = data.phone || "";
    this.message = data.message || "";
  }
}
export class ContactError {
  public name: boolean;
  public email: boolean;
  public phone: boolean;
  public message: boolean;
  constructor(data: any = {}) {
    this.name = data.name || false;
    this.email = data.email || false;
    this.phone = data.phone || false;
    this.message = data.message || false;
  }
}
