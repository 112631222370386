import React, { useEffect } from "react";

import Table from "react-bootstrap/Table";
import "./GarageList.scss";
import LeftSideBar from "../LeftSideBar/LeftSideBar";
import { Link, useNavigate } from "react-router-dom";
import { Map, Edit, Delete, LeftArrowCircle } from "../../../assets/Icons";
import {
  sendDeleteRequest,
  sendGetRequest,
} from "../../../utils/helpers/requestController";
import { apiEndPoints } from "../../../utils/helpers/endPoints";
import { Snackbar } from "@mui/material";

const GarageList = () => {
  const [open, setOpen] = React.useState(false);
  const [garageList, setGarageList] = React.useState([]);
  const [search, setSearch] = React.useState();
  const navigate = useNavigate();

  const getGarageList = async (search: any = "") => {
    let res: any;
    if (search) {
      res = await sendGetRequest(apiEndPoints.garageList + "?search=" + search);
    } else {
      res = await sendGetRequest(apiEndPoints.garageList);
    }
    setGarageList(res);
  };

  React.useEffect(() => {
    getGarageList();
  }, []);

  const deleteGarage = async (id: any) => {
    const res = await sendDeleteRequest(apiEndPoints.garageList + "/" + id);
    if (res) {
      setOpen(true);
      getGarageList();
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  };

  return (
    <div className="dashboard-layout admin">
      <Snackbar open={open} autoHideDuration={6000} message="Garage Deleted" />
      <div className="dashboard-layout__left">
        <LeftSideBar />
      </div>
      <div className="dashboard-layout__right">
        <div className="garage-list">
          <div className="service-dashboard__header">
            <h3>Garage List</h3>
            <h3 className="go-back" style={{ display: "none" }}>
              <Link to="">
                <LeftArrowCircle /> Go Back
              </Link>
            </h3>
          </div>

          <div className="garage-list__table">
            <div className="garage-list__table-header">
              <div className="garage-list__table-search">
                <input
                  type="text"
                  className="garage-list__table-search-input"
                  placeholder="Search"
                  onChange={(e: any) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getGarageList(search);
                    }
                  }}
                />
              </div>
              <div className="garage-list__action">
                <Link to="/admin/garage/add" className="button button--outline">
                  + Add Garage
                </Link>
              </div>
            </div>

            <Table responsive="sm">
              <thead>
                <tr>
                  <th>Garage ID</th>
                  <th>Name</th>
                  <th>Contact </th>
                  <th>Email</th>
                  <th>Location</th>
                  <th>Pincode</th>
                  <th>Location Map</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {garageList?.map((garage: any) => {
                  return (
                    <>
                      <tr>
                        <td>SPDR{garage?._id}</td>
                        <td>{garage?.name}</td>
                        <td>{garage?.contact}</td>
                        <td>{garage?.email}</td>
                        <td>
                          {garage?.address?.address}, {garage?.address?.city}
                        </td>
                        <td>{garage?.address?.pincode}</td>
                        <td>
                          <Link to="" className="map-link">
                            <Map /> Navigation
                          </Link>
                        </td>
                        <td className="table-action">
                          <Edit
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate("/admin/garage/" + garage._id);
                            }}
                          />

                          <Delete
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              deleteGarage(garage?._id);
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
            {/* <nav className="pagenination">
              <List>
                {items.map(({ page, type, selected, ...item }, index) => {
                  let children = null;

                  if (type === "start-ellipsis" || type === "end-ellipsis") {
                    children = "…";
                  } else if (type === "page") {
                    children = (
                      <button
                        type="button"
                        style={{
                          fontWeight: selected ? "bold" : undefined,
                        }}
                        {...item}
                      >
                        {page}
                      </button>
                    );
                  } else {
                    children = (
                      <button type="button" {...item}>
                        {type}
                      </button>
                    );
                  }

                  return <li key={index}>{children}</li>;
                })}
              </List>
            </nav> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GarageList;
