import React from "react";
import Slider from "react-slick";
import { BikeSliderImage } from "../../../assets/Icons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HowItWorks.scss";
const HowItWorks = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:true,
  };
  return (
    <div className="howitwork">
      <div className="container slider">
       
        <Slider {...settings}>
          <div className="slider__item">
          <div className="slider__item-block">
            <div className="slider__left">
            <h3>Regular Maintenance</h3>
              <div className="slider__item-title">
              Importance of Regular Maintenance for Two-Wheelers
              </div>
              <div className="slider__item-text">
              Regular maintenance is crucial for the optimal performance and longevity of your two-wheeler. By adhering to a periodic maintenance schedule, you can keep your vehicle running smoothly and efficiently. 
              
              </div>
            </div>

            <div className="slider__right">
              <div className="slider__item-image">
              <BikeSliderImage />
              </div>
              </div>
            </div>
          </div>
          <div className="slider__item">
          <div className="slider__item-block">
            <div className="slider__left">
            <h3>Tire Safety </h3>
              <div className="slider__item-title">
              Ensuring Tire Safety in Rainy Conditions: Essential Precautions for a Secure Ride
              </div>
              <div className="slider__item-text">
              Ensuring proper tire safety during the rainy season is crucial for maintaining control and preventing accidents
              </div>
            </div>

            <div className="slider__right">
              <div className="slider__item-image">
              <BikeSliderImage />
              </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default HowItWorks;
