import React from "react";
import { Booked, Drop, PickUP } from "../../../../assets/Icons";
import moment from "moment";
import { useNavigate } from "react-router-dom";
// import "./NotPicked.scss";
const NotPicked = ({ data }: any) => {
  // const [isChecked, setIsChecked] = React.useState(false);
  const navigate = useNavigate();

  const handleNavigate = (item: any) => {
    navigate(`/admin/viewbooking/${item._id}`);
  };
  return (
    <>
      {data?.map((item: any) => {
        return (
          <>
            <div className="service-orders__details box-border">
              <div className="service-orders__details-left">
                <div className="service-orders__details-item">
                  <span className="service-orders__details-badge">
                    {item?.packages?.name}
                  </span>
                  <img
                    className="service-orders__details-badge-img"
                    src= {item?.vehicleInfo?.model?.image}
                    alt=""
                  />
                </div>
                <div className="service-orders__details-item">
                  <div className="service-orders__details-item-block mb-8">
                    <div
                      className="sm-text-dark sm-text-dark-blue"
                      style={{ fontSize: "12px" }}
                    >
                      SPDR{item?._id}
                    </div>
                    <div className="lg-text-dark">
                      {item?.vehicleInfo?.model?.name}
                    </div>
                    <div className="xs-text-light">
                      {" "}
                      {item?.vehicleInfo?.vehicleNo}
                    </div>
                    <div className="xs-text-light">
                      {moment(item?.createdAt).format("YYYY")}
                    </div>
                  </div>
                  <div className="service-orders__details-item-block mb-8">
                    <div className="xs-text-light">Customer Name</div>
                    <div className="lg-text-dark">{item?.usersInfo?.name}</div>
                  </div>
                  <div className="service-orders__details-item-block">
                    <button className="button button--primary">
                      <a href={`tel:${item?.usersInfo?.contact}`}>Call Now</a>
                    </button>
                  </div>
                </div>
                <div className="service-orders__details-item">
                  <div className="service-orders__details-item-icon mb-8">
                    <div className="service-orders__icon">
                      <Booked />
                    </div>
                    <div className="service-orders__icon-text">
                      <div>
                        <span className="sm-text-dark">Booked </span>
                        <span className="xs-text-light">
                          {" "}
                          {moment(item?.createdAt).format("DD/MM/YYYY")} |
                          <p style={{ margin: "0" }}>
                            {moment(item?.createdAt).format("HH:mm a")}
                          </p>
                        </span>
                      </div>
                      <div className="xs-text-light">
                        Vehicle Booked Successfully
                      </div>
                    </div>
                  </div>
                  <div className="service-orders__details-item-icon mb-8">
                    <div className="service-orders__icon">
                      <PickUP />
                    </div>
                    <div className="service-orders__icon-text">
                      <div>
                        <span className="sm-text-dark">Pickup </span>{" "}
                        <span className="xs-text-light">
                          {moment(item?.createdAt).format("DD/MM/YYYY")} |
                          <p style={{ margin: "0" }}>
                            {moment(item?.createdAt).format("HH:MM")}
                          </p>
                        </span>
                      </div>
                      <div className="xs-text-light">
                        {item?.address?.pickup?.address},{" "}
                        {item?.address?.pickup?.city},
                        {item?.address?.pickup?.pincode}
                      </div>
                    </div>
                  </div>
                  <div className="service-orders__details-item-icon">
                    <div className="service-orders__icon">
                      <Drop />
                    </div>
                    <div className="service-orders__icon-text">
                      <div>
                        <span className="sm-text-dark">Drop</span>
                        <span className="xs-text-light"></span>
                      </div>
                      <div className="xs-text-light">
                        {item?.address?.pickup?.address},{" "}
                        {item?.address?.pickup?.city},
                        {item?.address?.pickup?.pincode}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-orders__details-right">
                <div className="service-orders__details-item">
                  {/* <div className="service-orders__details-item-block towing-checkbox mb-16">
              <Checkbox
                checked
                onChange={(e) => {
                  setIsChecked(!isChecked);
                }}
              />
              Required Towing
            </div> */}
                  <div className="service-orders__details-item-block mb-16">
                    <button
                      className="button button--outline view-booking"
                      onClick={() => handleNavigate(item)}
                    >
                      View Booking
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default NotPicked;
