import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { Filter, Sort } from "../../../../assets/Icons";

import "./FilterComponents.scss";
const FilterComponents = ({ searchHandler }: any) => {
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [searchKey, setSearchKey] = useState<any>();

  return (
    <div className="filter-component">
      <div className="filter-component__search">
        <input
          type="text"
          placeholder="Search by Vehicle number or Customer Name or Id"
          className="filter-component__search-input"
          onChange={(e: any) => {
            setSearchKey(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchHandler(searchKey, startDate, endDate);
            }
          }}
        />
      </div>
      <div className="filter-component__date">
        <DatePicker
          selected={startDate}
          onChange={(date: any) => setStartDate(new Date(date))}
          selectsStart
          placeholderText="Start Date - "
          // startDate={startDate}
          // endDate={endDate}
        />
        <DatePicker
          placeholderText="End Date"
          selected={endDate}
          onChange={(date: any) => {
            setEndDate(new Date(date));
          }}
          selectsEnd
          startDate={startDate}
          // endDate={endDate}
          minDate={startDate}
        />
      </div>
      <div className="filter-component__filter">
        <Filter
          onClick={() => {
            searchHandler(searchKey, startDate, endDate);
          }}
        />
      </div>
      {/* <div className="filter-component__sort">
        <Sort />
      </div> */}
    </div>
  );
};

export default FilterComponents;
