import React, { useState } from "react";

function ShowMore({ packagelist = [] }) {
  const [showMore, setShowMore] = useState(false);
  function handleClick() {
    setShowMore((prevShowAll) => !prevShowAll);
  }

  const serviceListItems = showMore ? packagelist : packagelist.slice(0, 5);

  return (
    <div>
      <ul className="service-package__item-list-block">
        {serviceListItems?.map((servicelist: any) => (
          <li className="service-package__item-list list-icon">
            {servicelist}
          </li>
        ))}

        <span 
         
          className="service-package__item-more"
          onClick={handleClick}
        >
          {showMore ? "Show less" :  '+' + `${packagelist.length - serviceListItems.length}` + ' ' + 'more'}
        </span>
      </ul>
    </div>
  );
}

export default ShowMore;
