import React, { useState } from "react";
import ReactDOM from "react-dom";

import { JobSheet, Download } from "../../../../assets/Icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import { data } from "./User";

import "bootstrap/dist/css/bootstrap.min.css";

import "./ServiceHistoryTable.scss";

const ServiceHistoryTable = ({ serviceList }) => {
  //State variable to keep track of all the expanded rows
  // By default, nothing expanded. Hence initialized with empty array.
  const [expandedRows, setExpandedRows] = useState([]);

  const [expandState, setExpandState] = useState({});

  function downloadImage(url) {
    let a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  /**
   * This function gets called when show/hide link is clicked.
   */
  const handleEpandRow = (event, historyId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(historyId);

    let obj = {};
    isRowExpanded ? (obj[historyId] = false) : (obj[historyId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== historyId)
      : currentExpandedRows.concat(historyId);

    setExpandedRows(newExpandedRows);
  };

  const getServiceStatus = (statusNumber) => {
    if (statusNumber === 1) {
      return "Booked";
    } else if (statusNumber === 2) {
      return "Picked Up";
    } else if (statusNumber === 3) {
      return "Diagnosing";
    } else if (statusNumber === 4) {
      return "Vehicle Under Repair";
    } else if (statusNumber === 5) {
      return "Delivered";
    }
  };
  return (
    <Row>
      <Col sm={12}>
        <Table responsive>
          <thead>
            <tr>
              <th>Service ID</th>
              <th>Service Package</th>
              <th>Pickup Date</th>
              <th>Delivery Date</th>
              <th>Status</th>
              <th>Jobsheet</th>
              <th>Invoice</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {serviceList.map((history) => (
              <>
                <tr>
                  <td>SPDR{history?._id}</td>
                  <td>{history?.packages?.name}</td>
                  <td>
                    {
                      new Date(history?.slot?.date)
                        .toLocaleString("en-GB")
                        .split(",")[0]
                    }
                  </td>
                  <td>
                    {" "}
                    {
                      new Date(history?.slot?.deliveryDate)
                        .toLocaleString("en-GB")
                        .split(",")[0]
                    }
                  </td>
                  <td>{getServiceStatus(history.serviceStatus)}</td>
                  <td>
                    <button
                      className="button button--outline download-jobsheet"
                      onClick={() => downloadImage(history.jobSheet)}
                    >
                      <JobSheet /> Jobsheet
                    </button>
                  </td>
                  <td>
                    <button
                      className="button button--outline border-0 download-invoice"
                      onClick={() => downloadImage(history.jobSheet)}
                    >
                      <Download /> Invoice
                    </button>
                  </td>

                  <td>
                    <Button
                      className="downarrow"
                      onClick={(event) => handleEpandRow(event, history._id)}
                    ></Button>
                  </td>
                </tr>
                <>
                  {expandedRows.includes(history._id) ? (
                    <tr>
                      <td colspan="8" className="expanded-td">
                        <div className="expanded">
                          <div className="expanded__left">
                            <div className="expanded__left-top">
                              <div className="expanded__item">
                                <div className="expanded__item-title">Name</div>
                                <div className="expanded__item-data">
                                  {history?.usersInfo?.name}
                                </div>
                              </div>
                              <div className="expanded__item">
                                <div className="expanded__item-title">
                                  Add Ons
                                </div>

                                <ol>
                                  {/* <li className="expanded__item-data">
                                    Semi Syntactic Oil
                                  </li>
                                  <li className="expanded__item-data">
                                    Fuel Injector
                                  </li>
                                  <li className="expanded__item-data">
                                    chain sprocket
                                  </li>
                                  <li className="expanded__item-data">
                                    Crank Shaft
                                  </li>
                                  <li className="expanded__item-data">
                                    Front Fork
                                  </li> */}
                                  {history?.addOns?.map((addOn) => {
                                    return (
                                      <li
                                        key={addOn._id}
                                        className="expanded__item-data"
                                      >
                                        {addOn?.name}
                                      </li>
                                    );
                                  })}
                                </ol>
                              </div>
                              <div className="expanded__item">
                                <div className="expanded__item-title">
                                  Pick Up
                                </div>
                                <div className="expanded__item-data">
                                  {history?.address?.pickup.address},{" "}
                                  {history?.address?.pickup.locality},
                                  {history?.address?.pickup.city},
                                  {history?.address?.pickup.state}-{" "}
                                  {history?.address?.pickup.pincode}
                                </div>
                              </div>
                            </div>
                            <div className="expanded__left-bottom">
                              <div className="expanded__item">
                                <div className="expanded__item-title">
                                  Contact
                                </div>
                                <div className="expanded__item-data">
                                  {history?.usersInfo?.contact}
                                </div>
                              </div>
                              <div className="expanded__item">
                                <div className="expanded__item-title">Drop</div>
                                <div className="expanded__item-data">
                                  {history?.address?.delivery.address},{" "}
                                  {history?.address?.delivery.locality},
                                  {history?.address?.delivery.city},
                                  {history?.address?.delivery.state}-{" "}
                                  {history?.address?.delivery.pincode}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="expanded__right">
                            <div className="expanded__item">
                              <div className="expanded__item-title">
                                Payment Mode
                              </div>
                              <div className="expanded__item-data">
                                {history?.paymentMode}
                              </div>
                            </div>
                            <div className="expanded__item">
                              <div className="expanded__item-title">
                                Transaction ID
                              </div>
                              <div className="expanded__item-data">
                                {history?.paymentId}
                              </div>
                            </div>
                            <div className="expanded__item">
                              <div className="expanded__item-title">
                                Amount Paid
                              </div>
                              <div className="expanded__item-data">
                                ₹ {history?.totalCost}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </>
              </>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default ServiceHistoryTable;
