import React, { useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";

import ServiceHistoryTable from "./ServiceHistoryTable/ServiceHistoryTable";
import LeftSideBar from "../LeftSideBar/LeftSideBar";

import "./ServiceHistory.scss";
import { apiEndPoints } from "../../../utils/helpers/endPoints";
import { sendGetRequest } from "../../../utils/helpers/requestController";
import { useNavigate } from "react-router-dom";
import ServiceHistoryEmpty from "./ServiceHistoryEmpty/ServiceHistoryEmpty";

const ServiceHistory = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = React.useState(0);
  const [serviceList, setServiceList] = React.useState([]);
  const [services, setService] = React.useState<any>({});
  console.log(serviceList);
  const getServiceHistory = async () => {
    const res = await sendGetRequest(`${apiEndPoints.createService}/history`);
    setServiceList(res);
    setService(res[0]);
  };
  const navigateHome = () => {
    navigate("/");
  };
  useEffect(() => {
    getServiceHistory();
  }, []);
  return (
    <div>
      <div className="dashboard-layout">
        <div className="dashboard-layout__left">
          <LeftSideBar />
        </div>
        <div className="dashboard-layout__right">
          <div className="service-dashboard">
            <div className="service-dashboard__header">
              <h3>My Vehicle</h3>
            </div>

            <div className="vehicle">
              <div className="vehicle__cards">
                {serviceList?.map((data: any, ind: any) => {
                  return (
                    <div
                      onClick={(e) => {
                        setIsChecked(ind);
                        setService(serviceList[ind]);
                      }}
                      className={
                        ind === isChecked
                          ? "vehicle__card active"
                          : "vehicle__card"
                      }
                    >
                      <div className="card-image">
                        <img
                          src={services?.vehicleInfo?.model?.image}
                          alt="logo"
                        />
                      </div>
                      <div className="card-content">
                        <div className="vehicle__model xs-text-light">
                          {data?.vehicleInfo?.model?.name}
                        </div>
                        <div className="vehicle__number lg-text-dark">
                          {data.vehicleInfo.vehicleNo}
                        </div>
                        <div className="vehicle__year sm-text-dark">
                          {data.vehicleInfo.year}
                        </div>
                      </div>
                      <Checkbox
                        checked={isChecked === ind}
                        // onChange={(e) => {
                        //   setIsChecked(ind);
                        //   setService(serviceList[ind]);
                        // }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="vehicle-history">
              {serviceList.length > 0 ? (
                <>
                  <div className="service-details">
                    <div className="service-details__left">
                      <div className="service-details__block">
                        <div className="service-details__item display-item">
                          <div className="service-details__item-img">
                            <img
                              src={services?.vehicleInfo?.model?.image}
                              alt="logo"
                            />
                          </div>
                          <div className="service-details__content">
                            <div className="service-details__item-block">
                              <div className="sm-text-light">Vehicle Info</div>
                              <div className="lg-text-dark">
                                {services?.vehicleInfo?.model?.name}
                              </div>
                            </div>

                            <div className="service-details__item-block">
                              <span className="sm-text-light">
                                Vehicle No :
                              </span>
                              <span className="sm-text-dark">
                                {services?.vehicleInfo?.vehicleNo || "--"}
                              </span>
                            </div>

                            <div className="service-details__item-block">
                              <span className="sm-text-light">Year :</span>
                              <span className="sm-text-dark">
                                {services?.vehicleInfo?.year || "--"}{" "}
                              </span>
                            </div>

                            <div className="service-details__item-block">
                              <span className="sm-text-light">
                                Fuel Type :{" "}
                              </span>
                              <span className="sm-text-dark">
                                {services?.vehicleInfo?.fuelType || "--"}
                              </span>
                            </div>

                            <div className="service-details__item-block">
                              <span className="sm-text-light">Km Driven :</span>
                              <span className="sm-text-dark">
                                {services?.vehicleInfo?.kmDriven || "--"}
                              </span>
                            </div>

                            <div className="service-details__item-block">
                              <span className="sm-text-light">
                                Last Service :
                              </span>
                              <span className="sm-text-dark">
                                {services?.vehicleInfo?.lastService || "--"}
                              </span>
                            </div>
                            <div className="service-details__item-block">
                              <span className="sm-text-light">
                                Front Tyre Pressure:{" "}
                              </span>
                              <span className="sm-text-dark">
                                {services?.vehicleInfo?.frontTyrePressure ||
                                  "--"}
                              </span>
                            </div>
                            <div className="service-details__item-block">
                              <span className="sm-text-light">
                                Back Tyre Pressure:{" "}
                              </span>
                              <span className="sm-text-dark">
                                {services?.vehicleInfo?.backTyrePressure ||
                                  "--"}
                              </span>
                            </div>
                            <div className="service-details__item-block">
                              <span className="sm-text-light">Remarks: </span>
                              <span className="sm-text-dark">
                                {services?.vehicleInfo?.remark || "--"}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="service-details__item">
                          <div className="service-details__item-block mb-8">
                            <div className="sm-text-light">
                              Vehicle Condition
                            </div>
                          </div>
                          <div className="display-item">
                            <div className="display-item__group">
                              <div className="service-details__progress">
                                <ProgressBar
                                  bgcolor="#007CDE"
                                  progress={
                                    services?.vehicleStatus?.engine || 0
                                  }
                                  height={8}
                                  isProgresstxt={false}
                                  progressBarLabel={"Engine"}
                                />
                              </div>
                              <div className="service-details__progress">
                                <ProgressBar
                                  bgcolor="#F8BB54"
                                  progress={services?.vehicleStatus?.brake || 0}
                                  height={8}
                                  isProgresstxt={false}
                                  progressBarLabel={"Brake"}
                                />
                              </div>
                              <div className="service-details__progress">
                                <ProgressBar
                                  bgcolor="#E15539"
                                  progress={services?.vehicleStatus?.oil || 0}
                                  height={8}
                                  isProgresstxt={false}
                                  progressBarLabel={"Oil"}
                                />
                              </div>
                            </div>
                            <div className="display-item__group">
                              <div className="service-details__progress">
                                <ProgressBar
                                  bgcolor="#007CDE"
                                  progress={services?.vehicleStatus?.tyres || 0}
                                  height={8}
                                  isProgresstxt={false}
                                  progressBarLabel={"Tyres"}
                                />
                              </div>
                              <div className="service-details__progress">
                                <ProgressBar
                                  bgcolor="#007CDE"
                                  progress={
                                    services?.vehicleStatus?.coolent || 0
                                  }
                                  height={8}
                                  isProgresstxt={false}
                                  progressBarLabel={"Coolent"}
                                />
                              </div>
                              <div className="service-details__progress">
                                <ProgressBar
                                  bgcolor="#007CDE"
                                  progress={services?.vehicleStatus?.other || 0}
                                  height={8}
                                  isProgresstxt={false}
                                  progressBarLabel={"Other Parts"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-details__right">
                      <button
                        className="button button--outline service-dashboard__bookservice"
                        onClick={navigateHome}
                      >
                        Book Service
                      </button>
                    </div>
                  </div>

                  <div className="history-table">
                    <ServiceHistoryTable serviceList={serviceList} />
                  </div>
                </>
              ) : (
                <ServiceHistoryEmpty />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceHistory;
