import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { Booked, Drop, PickUP } from "../../../../assets/Icons";
import "./NotPicked.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContentText, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
const NotPicked = ({
  data,
  onAcceptHandler,
  cancelReason,
  setCancelReason,
}: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState<any>(false);

  const [error, setError] = React.useState<any>(false);

  const handleNavigate = (item: any) => {
    navigate(`/admin/viewbooking/${item._id}`);
  };

  const handleClose = () => {
    setError(false);
    setCancelReason("");
    setOpen(false);
  };

  const handleError = async (e: any) => {
    if (cancelReason?.trim()?.length === 0) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <>
      {data?.map((item: any) => {
        return (
          <>
            <div className="service-orders__details box-border">
              <div className="service-orders__details-left">
                <div className="service-orders__details-item">
                  <div>
                    <span className="service-orders__details-badge">
                      {item?.packages?.name}
                    </span>
                    {item?.slot?.towingRequired && (
                      <span className="service-orders__details-badge">
                        Towing required
                      </span>
                    )}
                  </div>
                  <img
                    className="service-orders__details-badge-img"
                    src={item?.vehicleInfo?.model?.image}
                    // src="../../../images/mybike.svg"
                    alt=""
                  />
                </div>
                <div className="service-orders__details-item">
                  <div className="service-orders__details-item-block mb-8">
                    <div
                      className="sm-text-dark sm-text-dark-blue"
                      style={{ fontSize: "12px" }}
                    >
                      SPDR{item?._id}
                    </div>
                    <div className="lg-text-dark">
                      {item?.vehicleInfo?.model?.name}
                    </div>
                    <div className="xs-text-light">
                      {" "}
                      {item?.vehicleInfo?.vehicleNo}
                    </div>
                    <div className="xs-text-light">
                      {moment(item?.createdAt).format("YYYY")}
                    </div>
                  </div>
                  <div className="service-orders__details-item-block mb-8">
                    <div className="xs-text-light">Customer Name</div>
                    <div className="lg-text-dark">{item?.usersInfo?.name}</div>
                  </div>
                  <div className="service-orders__details-item-block">
                    <button className="button button--primary">
                      <a href={`tel:${item?.usersInfo?.contact}`}>Call Now</a>
                    </button>
                  </div>
                </div>
                <div className="service-orders__details-item">
                  <div className="service-orders__details-item-icon mb-8">
                    <div className="service-orders__icon">
                      <Booked />
                    </div>
                    <div className="service-orders__icon-text">
                      <div>
                        <span className="sm-text-dark">Booked </span>
                        <span className="xs-text-light">
                          {moment(item?.createdAt).format("DD/MM/YYYY")} |
                          <p style={{ margin: "0" }}>
                            {moment(item?.createdAt).format("HH:mm a")}
                          </p>
                        </span>
                      </div>
                      {/* <div className="xs-text-light">Vehicle Booked Successfully</div> */}
                    </div>
                  </div>
                  <div className="service-orders__details-item-icon mb-8">
                    <div className="service-orders__icon">
                      <PickUP />
                    </div>
                    <div className="service-orders__icon-text">
                      <div>
                        <span className="sm-text-dark">Pickup </span>{" "}
                        <span className="xs-text-light">
                          {" "}
                          {moment(item?.slot?.date).format("DD/MM/YYYY")} |
                          <p style={{ margin: "0" }}>{item?.slot?.time}</p>
                        </span>
                      </div>
                      <div className="xs-text-light">
                        {item?.address?.pickup?.address},{" "}
                        {item?.address?.pickup?.city},
                        {item?.address?.pickup?.pincode}
                      </div>
                    </div>
                  </div>
                  <div className="service-orders__details-item-icon">
                    <div className="service-orders__icon">
                      <Drop />
                    </div>
                    <div className="service-orders__icon-text">
                      <div>
                        <span className="sm-text-dark">Drop</span>
                        <span className="xs-text-light"></span>
                      </div>
                      <div className="xs-text-light">
                        {item?.address?.delivery?.address},{" "}
                        {item?.address?.delivery?.city},
                        {item?.address?.delivery?.pincode}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-orders__details-right">
                <div className="service-orders__details-item">
                  {/* <div className="service-orders__details-item-block towing-checkbox mb-16">
                    {item?.slot?.towingRequired}
                    <Checkbox readOnly checked={item?.slot?.towingRequired} />
                    Required Towing
                  </div> */}
                  <div className="service-orders__details-item-block mb-16">
                    <button
                      className="button button--outline view-booking"
                      onClick={() => handleNavigate(item)}
                    >
                      View Booking
                    </button>
                  </div>
                  {!item?.serviceAccepted && (
                    <div className="service-orders__details-item-block mb-16">
                      <button
                        className="button button--outline accept"
                        onClick={() => {
                          onAcceptHandler("accept", item);
                        }}
                      >
                        Accept
                      </button>
                    </div>
                  )}
                  <div className="service-orders__details-item-block">
                    <button
                      className="button button--outline cancel"
                      onClick={() => {
                        // onAcceptHandler("cancel", item);
                        setOpen(true);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <Dialog open={open} onClose={handleClose}>
                <Box style={{ padding: "40px" }}>
                  <DialogTitle style={{ paddingLeft: "0" }}>
                    Cancel Service
                  </DialogTitle>
                  <DialogContentText style={{ paddingBottom: "10px" }}>
                    Enter Your Reason For Cancelling..
                  </DialogContentText>
                  <textarea
                    name="message"
                    id="message"
                    className="form__input"
                    placeholder="Enter Reason.."
                    style={{ width: "100%", minHeight: "80px" }}
                    value={cancelReason}
                    onChange={(evt: any) => {
                      setCancelReason(evt.target.value);
                    }}
                    onBlur={handleError}
                  ></textarea>
                  {error && (
                    <div className="form__input-error">
                      <p>Please enter the reason.</p>
                    </div>
                  )}
                  <div style={{ display: "flex", gap: "10px" }}>
                    <button
                      className="button button--outline"
                      style={{ marginTop: "10px" }}
                      onClick={handleClose}
                    >
                      Close
                    </button>
                    <button
                      className={
                        cancelReason && cancelReason?.trim()?.length > 0
                          ? "button button--outline cancel"
                          : "button button--disabled"
                      }
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        onAcceptHandler("cancel", item);
                        handleClose();
                      }}
                    >
                      Cancel Service
                    </button>
                  </div>
                </Box>
              </Dialog>
            </div>
          </>
        );
      })}
    </>
  );
};

export default NotPicked;
