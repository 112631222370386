import React from "react";
import Checkbox from "@mui/material/Checkbox";

import VerticalStepper from "../Stepper/Stepper";
import { apiEndPoints } from "../../../utils/helpers/endPoints";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import {
  sendGetRequest,
  sendPutRequest,
} from "../../../utils/helpers/requestController";

import "./OngoingService.scss";

import {
  JobSheet,
  PackageIcon,
  AddOn,
  CurrentStatus,
  Payment,
  User,
  Settings,
  PhoneIcon,
} from "../../../assets/Icons";
import ServiceHistoryEmpty from "../ServiceHistory/ServiceHistoryEmpty/ServiceHistoryEmpty";
import { Dialog, DialogContentText, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import { textRegEx } from "../../../utils/helpers/constants";

const OngoingService = () => {
  const [isChecked, setIsChecked] = React.useState(0);
  const [serviceList, setServiceList] = React.useState([]);
  const [services, setService] = React.useState<any>({});
  const [open, setOpen] = React.useState<any>(false);
  const [cancelReason, setCancelReason] = React.useState<any>("");
  const [error, setError] = React.useState<any>(false);
  const getOngoingService = async () => {
    const res = await sendGetRequest(`${apiEndPoints.createService}/ongoing`);
    setServiceList(res);
    setService(res[0]);
  };

  const handleClose = () => {
    setError(false);
    setCancelReason("");
    setOpen(false);
  };

  const handleError = async (e: any) => {
    if (cancelReason?.trim()?.length === 0) {
      setError(true);
    } else {
      setError(false);
    }
  };

  function downloadImage(url: string) {
    let a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  const onCancelHandler = async (item: any) => {
    try {
      const payload = Object.assign({}, item);
      payload.serviceAccepted = false;
      payload.cancelRemark = cancelReason;
      const res = await sendPutRequest(
        apiEndPoints.createService + "/" + payload._id,
        payload
      );
      getOngoingService();
    } catch (error: any) {}
    handleClose();
  };

  const vehicleCondition = (healthValue: number) => {
    if (healthValue < 20) {
      return "#E15539";
    } else if (healthValue < 50) {
      return "#F8BB54";
    } else if (healthValue < 80) {
      return "#007CDE";
    } else {
      return "#28CE8C";
    }
  };

  const getServiceStatus = (statusNumber: any) => {
    if (statusNumber === 1) {
      return "Booked";
    } else if (statusNumber === 2) {
      return "Picked Up";
    } else if (statusNumber === 3) {
      return "Diagnosing";
    } else if (statusNumber === 4) {
      return "Vehicle Under Repair";
    } else if (statusNumber === 5) {
      return "Delivered";
    }
  };

  React.useEffect(() => {
    getOngoingService();
  }, []);

  return (
    <div className="service-dashboard">
      <div className="service-dashboard__header">
        <h3>On Going Services</h3>
        {services?.jobSheet && (
          <button
            className="button button--outline service-dashboard__jobsheet jobsheet"
            onClick={() => downloadImage(services?.jobSheet)}
          >
            <JobSheet />
            Jobsheet
          </button>
        )}
      </div>
      <div className="vehicle">
        <div className="vehicle__cards">
          {serviceList.map((data: any, index: any) => {
            return (
              <div
                className={
                  isChecked === index ? "vehicle__card active" : "vehicle__card"
                }
                onClick={() => {
                  setIsChecked(index);
                  setService(serviceList[index]);
                }}
              >
                <div className="card-image">
                  <img src={data?.vehicleInfo?.model?.image} alt="" />
                </div>
                <div className="card-content">
                  {/* <div className="vehicle__model xs-text-light">
                  </div> */}
                  <div className="vehicle__model xs-text-light">
                    {services?.vehicleInfo?.brand?.name || "--"}{" "}
                    {data.vehicleInfo.model?.name}
                  </div>
                  <div className="vehicle__number lg-text-dark">
                    {data.vehicleInfo.vehicleNo}
                  </div>
                  <div className="vehicle__year sm-text-dark">
                    {" "}
                    {data.vehicleInfo.year}
                  </div>
                </div>
                <Checkbox
                  checked={isChecked === index}
                  onChange={(e) => {
                    setIsChecked(index);
                    setService(serviceList[index]);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      {services ? (
        <>
          <div className="service-info">
            <div className="service-info__item">
              <div className="service-info__icon">
                <PackageIcon />
              </div>
              <div className="service-info__content">
                <div className="service-info__label">Services Package</div>
                <div className="service-info__data">
                  {services?.packages?.name}
                </div>
              </div>
            </div>

            <div className="service-info__item">
              <div className="service-info__icon">
                <AddOn />
              </div>
              <div className="service-info__content">
                <div className="service-info__label">Add-Ons</div>
                <div className="service-info__data">
                  +{services?.addOns?.length} Added
                </div>
              </div>
            </div>

            <div className="service-info__item">
              <div className="service-info__icon">
                <CurrentStatus />
              </div>
              <div className="service-info__content">
                <div className="service-info__label">Current Status</div>
                <div className="service-info__data">
                  {getServiceStatus(services?.serviceStatus)}
                </div>
              </div>
            </div>
            <div className="service-info__item">
              <div className="service-info__icon">
                <Payment />
              </div>
              <div className="service-info__content">
                <div className="service-info__label">Payment</div>
                <div className="service-info__data">
                  {services?.paymentStatus}
                </div>
              </div>
            </div>
          </div>
          <div className="service-details">
            <div className="service-details__left">
              <div className="service-details__register">
                <div className="service-details__register-item">
                  <User />
                  <div className="service-details__register-data">
                    <span className="service-details__register-label">
                      Service ID :{" "}
                    </span>
                    <span className="service-details__register-value">
                      SPDR{services?._id}
                    </span>
                  </div>
                </div>
                <div className="service-details__register-item">
                  <Settings />
                  <div className="service-details__register-data">
                    <span className="service-details__register-label">
                      Assigned to :{" "}
                    </span>
                    <span className="service-details__register-value">
                      {services?.mechanicInfo?.name || "--"}
                    </span>
                  </div>
                </div>
                <div className="service-details__register-item">
                  <PhoneIcon />
                  <div className="service-details__register-data">
                    <span className="service-details__register-label">
                      Contact :
                    </span>
                    <span className="service-details__register-value">
                      {services?.mechanicInfo?.contact || "--"}
                    </span>
                  </div>
                </div>
              </div>

              <div className="service-details__block">
                <div className="service-details__item">
                  <div className="service-details__item-img">
                    <img src={services?.vehicleInfo?.model?.image} alt="logo" />
                  </div>
                  <div className="service-details__item-block">
                    <div className="sm-text-light">Vehicle Info</div>
                    <div className="lg-text-dark">
                      {services?.vehicleInfo?.brand?.name || "--"}{" "}
                      {services?.vehicleInfo?.model?.name || "--"}
                    </div>
                    {/* <div className="lg-text-dark">
                  {services?.vehicleInfo?.model?.name || "--"}
                </div> */}
                  </div>

                  <div className="service-details__item-block">
                    <span className="sm-text-light">Vehicle No :</span>
                    <span className="sm-text-dark">
                      {services?.vehicleInfo?.vehicleNo?.toUpperCase() || "--"}
                    </span>
                  </div>

                  <div className="service-details__item-block">
                    <span className="sm-text-light">Year :</span>
                    <span className="sm-text-dark">
                      {services?.vehicleInfo?.year || "--"}{" "}
                    </span>
                  </div>

                  <div className="service-details__item-block">
                    <span className="sm-text-light">Fuel Type : </span>
                    <span className="sm-text-dark">
                      {services?.vehicleInfo?.fuelType || "--"}
                    </span>
                  </div>

                  <div className="service-details__item-block">
                    <span className="sm-text-light">Km Driven :</span>
                    <span className="sm-text-dark">
                      {services?.vehicleInfo?.kmDriven || "--"}
                    </span>
                  </div>

                  <div className="service-details__item-block">
                    <span className="sm-text-light">Last Service : </span>
                    <span className="sm-text-dark">
                      {services?.vehicleInfo?.lastService || "--"}
                    </span>
                  </div>
                  <div className="service-details__item-block">
                    <span className="sm-text-light">Front Tyre Pressure: </span>
                    <span className="sm-text-dark">
                      {services?.vehicleInfo?.frontTyrePressure || "--"}
                    </span>
                  </div>
                  <div className="service-details__item-block">
                    <span className="sm-text-light">Back Tyre Pressure: </span>
                    <span className="sm-text-dark">
                      {services?.vehicleInfo?.backTyrePressure || "--"}
                    </span>
                  </div>
                  <div className="service-details__item-block">
                    <span className="sm-text-light">Remarks: </span>
                    <span className="sm-text-dark">
                      {services?.vehicleInfo?.remark || "--"}
                    </span>
                  </div>
                </div>

                <div className="service-details__item">
                  <div className="service-details__item-block mb-16">
                    <div className="sm-text-light">Customer Name</div>
                    <div className="lg-text-dark">
                      {services?.usersInfo?.name}
                    </div>
                  </div>

                  <div className="service-details__item-block mb-43">
                    <div className="sm-text-light">Contact</div>
                    <div className="lg-text-dark">
                      {services?.usersInfo?.contact}
                    </div>
                  </div>

                  <div className="service-details__item-block address mb-16">
                    <div className="sm-text-light mb-16">
                      Pick Up & Delivery Location
                    </div>
                    <div className="sm-text-dark">Pick Up</div>
                    <div className="address__pickup">
                      <p>
                        {
                          new Date(services?.slot?.date)
                            .toLocaleString("en-GB")
                            .split(",")[0]
                        }{" "}
                        | {services?.slot?.time}{" "}
                      </p>
                      <p>
                        {services?.address?.pickup?.address},{" "}
                        {services?.address?.pickup?.locality},
                        {services?.address?.pickup?.city}
                      </p>
                      <p>
                        {services?.address?.pickup?.state}-{" "}
                        {services?.address?.pickup?.pincode}
                      </p>
                      <p>
                        Landmark :{services?.address?.pickup?.landmark || "--"}
                      </p>
                    </div>
                  </div>
                  <div className="service-details__item-block address">
                    <div className="sm-text-dark">Drop</div>
                    <div className="address__pickup">
                      <p>{services?.address?.delivery?.address}, ,</p>
                      <p>
                        {services?.address?.delivery?.locality},
                        {services?.address?.delivery?.city}{" "}
                        {services?.address?.delivery?.state}-{" "}
                        {services?.address?.delivery?.pincode}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="service-details__item">
                  <div className="service-details__item-block mb-8">
                    <div className="sm-text-light">Vehicle Condition</div>
                  </div>
                  <div className="service-details__progress">
                    <ProgressBar
                      bgcolor={vehicleCondition(
                        services?.vehicleStatus?.engine
                      )}
                      progress={services?.vehicleStatus?.engine || 0}
                      height={8}
                      isProgresstxt={false}
                      progressBarLabel={"Engine"}
                    />
                  </div>
                  <div className="service-details__progress">
                    <ProgressBar
                      bgcolor={vehicleCondition(services?.vehicleStatus?.brake)}
                      progress={services?.vehicleStatus?.brake || 0}
                      height={8}
                      isProgresstxt={false}
                      progressBarLabel={"Brake"}
                    />
                  </div>
                  <div className="service-details__progress">
                    <ProgressBar
                      bgcolor={vehicleCondition(services?.vehicleStatus?.oil)}
                      progress={services?.vehicleStatus?.oil || 0}
                      height={8}
                      isProgresstxt={false}
                      progressBarLabel={"Oil"}
                    />
                  </div>
                  <div className="service-details__progress">
                    <ProgressBar
                      bgcolor={vehicleCondition(services?.vehicleStatus?.tyres)}
                      progress={services?.vehicleStatus?.tyres || 0}
                      height={8}
                      isProgresstxt={false}
                      progressBarLabel={"Tyres"}
                    />
                  </div>
                  <div className="service-details__progress">
                    <ProgressBar
                      bgcolor={vehicleCondition(
                        services?.vehicleStatus?.coolent
                      )}
                      progress={services?.vehicleStatus?.coolent || 0}
                      height={8}
                      isProgresstxt={false}
                      progressBarLabel={"Coolent"}
                    />
                  </div>
                  <div className="service-details__progress">
                    <ProgressBar
                      bgcolor={vehicleCondition(services?.vehicleStatus?.other)}
                      progress={services?.vehicleStatus?.other || 0}
                      height={8}
                      isProgresstxt={false}
                      progressBarLabel={"Other Parts"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details__right">
              <div className="service-details__item-block mb-8">
                <div className="lg-text-dark">Status</div>
              </div>
              <VerticalStepper
                modelType="ongoing"
                activeStatus={services?.serviceStatus}
              />
            </div>
          </div>
          <div className="service-addon">
            <div className="service-addon__block">
              <div className="service-addon__left">
                <div className="service-details__item-block mb-8">
                  <div className="lg-text-dark">
                    {" "}
                    Additional Spare Add Ons ({services?.addOns?.length})
                  </div>
                </div>
                <div className="d-flex">
                  {services?.addOns?.map((addOn: any) => {
                    return (
                      <div className="service-addon__inner">
                        <div className="service-addon__item">
                          <div className="service-addon__item-img">
                            <img src={addOn?.image} alt="" />
                          </div>
                          <div className="service-addon__item-info">
                            <div className="service-details__item-block ">
                              <div className="xs-text-light">
                                {addOn?.name}{" "}
                              </div>
                            </div>
                            <div className="service-details__item-block ">
                              <div className="sm-text-light">
                                {addOn?.brand}
                                {/* <span className="sm-text-dark">
                              ₹ {addOn?.price}
                            </span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {services?.additionalSpares?.map((addOn: any) => {
                    return (
                      <div className="service-addon__inner">
                        <div className="service-addon__item">
                          <div className="service-addon__item-img">
                            <img src={addOn?.addOn?.image} alt="" />
                          </div>
                          <div className="service-addon__item-info">
                            <div className="service-details__item-block ">
                              <div className="xs-text-light">
                                {addOn?.addOn?.name}{" "}
                              </div>
                            </div>
                            <div className="service-details__item-block ">
                              <div className="sm-text-light">
                                {addOn?.addOn?.brand}
                                {/* <span className="sm-text-dark">
                              ₹ {addOn?.price}
                            </span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="service-addon__right">
                <div className="service-addon__total">
                  <div className="service-details__item">
                    <div className="service-details__item-block mb-16">
                      <div className="sm-text-light">Subtotal</div>
                      <div className="lg-text-dark">
                        ₹ {services?.discountAmount + services?.totalCost}
                      </div>
                    </div>
                  </div>
                  <div className="service-details__item">
                    <div className="service-details__item-block mb-16">
                      <div className="sm-text-light">Discount</div>
                      <div className="lg-text-dark discount">
                        ₹ -{services?.discountAmount}
                      </div>
                    </div>
                  </div>
                  <div className="service-details__item">
                    {/* <div className="service-details__item-block mb-16">
                  <div className="sm-text-light">18% GST</div>
                  <div className="lg-text-dark">₹ 161</div>
                </div> */}
                  </div>
                  <div className="service-details__item">
                    <div className="service-details__item-block mb-16">
                      <div className="sm-text-dark">Total Cost</div>
                      <div className="sm-text-dark">{services?.totalCost}</div>
                    </div>
                  </div>
                  <div className="service-details__item">
                    {!!services?.invoice && (
                      <div className="service-details__item-block">
                        <button
                          className="button button--outline service-dashboard__jobsheet"
                          onClick={() => downloadImage(services?.invoice)}
                        >
                          <JobSheet />
                          Download Invoice
                        </button>
                      </div>
                    )}
                    {!!!services?.serviceAccepted && (
                      <button
                        className="button button--outline cancel"
                        style={{ width: "100%", marginTop: "10px" }}
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        Cancel Service
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
        <div className="vehicle-history">
        <ServiceHistoryEmpty />
        </div>
        </>
      )}
      <Dialog open={open} onClose={handleClose}>
        <Box style={{ padding: "40px" }}>
          <DialogTitle style={{ paddingLeft: "0" }}>Cancel Service</DialogTitle>
          <DialogContentText style={{ paddingBottom: "10px" }}>
            Enter Your Reason For Cancelling..
          </DialogContentText>
          <textarea
            name="message"
            id="message"
            className="form__input"
            placeholder="Enter Reason.."
            style={{ width: "100%", minHeight: "80px" }}
            value={cancelReason}
            onChange={(evt: any) => {
              setCancelReason(evt.target.value);
            }}
            onBlur={handleError}
          ></textarea>
          {error && (
            <div className="form__input-error">
              <p>Please enter the reason.</p>
            </div>
          )}
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              className="button button--outline"
              style={{ marginTop: "10px" }}
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className={
                cancelReason && cancelReason?.trim()?.length > 0
                  ? "button button--outline cancel"
                  : "button button--disabled"
              }
              style={{ marginTop: "10px" }}
              onClick={() => {
                onCancelHandler(services);
              }}
            >
              Cancel Service
            </button>
          </div>
        </Box>
      </Dialog>
    </div>
  );
};

export default OngoingService;
