import React from "react";
import SideBar from "../../components/SideBar/SideBar";
import { Checkbox } from "@mui/material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import "./AddOn.scss";
import { sendGetRequest } from "../../utils/helpers/requestController";
import { apiEndPoints } from "../../utils/helpers/endPoints";
import { SpannerDoorContext } from "../../utils/context/GlobalContext";
import BookigStepper from "../Service-package/Stepper";

const AddOn = () => {
  const [isActiveGroup, setIsActiveGroup] = React.useState("");
  const [isChecked, setIsChecked] = React.useState<any>([]);
  // const [addOnPrice, setAddOnPrice] = React.useState<any>([]);
  const { dispatch, addOnGroup, addOnList, service }: any =
    React.useContext(SpannerDoorContext);
  const addOns: any = React.useRef([]);

  const getAddOnGroup = async () => {
    const res = await sendGetRequest(apiEndPoints.addOnGroup);
    dispatch({ type: "addOnGroup", payload: res });

    if (res && res.length > 0) {
      setIsActiveGroup(res[0]._id);
    }
  };

  const getAddOnGroupData = async (id?: string) => {
    const addOnGroupId = id || isActiveGroup;
    const res = await sendGetRequest(`${apiEndPoints.addOns}/${addOnGroupId}`);
    dispatch({ type: "addOnList", payload: res });
  };

  React.useEffect(() => {
    getAddOnGroup();
    const checkedList = sessionStorage.getItem("selectedAddOn");
    addOns.current = JSON.parse(
      sessionStorage.getItem("selectedAddOnRef") || "[]"
    );

    if (checkedList) {
      setIsChecked(JSON.parse(checkedList));
    }
  }, []);

  React.useEffect(() => {
    if (isActiveGroup) {
      getAddOnGroupData();
    }
  }, [isActiveGroup]);
  return (
    <div className="addOn">
      <BookigStepper step={2} />
      <div className="container">
        <div className="addOn__block">
          <div className="addOn__container">
            <p className="addOn__title"> </p>
            <h5 className="addOn__block-title">Add-Ons & Checkout</h5>

            <div className="addOn__header">
              {addOnGroup?.map((addOn: any) => {
                return (
                  <div
                    key={addOn._id}
                    className="addOn__tabs"
                    onClick={() => {
                      setIsActiveGroup(addOn._id);
                    }}
                  >
                    <div
                      className={
                        isActiveGroup === addOn._id
                          ? "addOn__tabs__individual active"
                          : "addOn__tabs__individual"
                      }
                      // style={{
                      //   opacity: addOn.name === "Engine Oils" ? "1" : "0.5",
                      // }}
                    >
                      {addOn.name}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="addOn__body">
              <div className="addOn__cards">
                {addOnList?.map((addOn: any) => {
                  return (
                    <div
                      className="addOn__card"
                      key={addOn._id}
                      onClick={() => {
                        const serviceObj = Object.assign({}, service);
                        // let addOnTotal = parseInt(addOnPrice, 10) || 0;
                        const index = serviceObj.addOns.findIndex(
                          (addOnId: any) => addOnId === addOn._id
                        );
                        if (index > -1) {
                          // addOnTotal =
                          //   addOnTotal - parseInt(addOnList[index].price, 10);
                          serviceObj.addOns.splice(index, 1);
                          addOns.current.splice(index, 1);
                        } else {
                          const index = addOns.current.findIndex(
                            (addOnId: any) =>
                              addOnId.group === addOn.addOnsgroup
                          );
                          if (index > -1) {
                            // addOnTotal =
                            //   addOnTotal - parseInt(addOnList[index].price, 10);
                            serviceObj.addOns.splice(index, 1);
                            addOns.current.splice(index, 1);
                          }
                          addOns.current.push({
                            group: addOn.addOnsgroup,
                            id: addOn._id,
                          });
                          serviceObj.addOns.push(addOn._id);
                          // addOnTotal = addOnTotal + parseInt(addOn.price, 10);
                        }

                        setIsChecked(serviceObj.addOns);
                        sessionStorage.setItem(
                          "selectedAddOn",
                          JSON.stringify(isChecked)
                        );
                        sessionStorage.setItem(
                          "selectedAddOnRef",
                          JSON.stringify(addOns.current)
                        );
                        dispatch({ type: "service", payload: serviceObj });
                        // setAddOnPrice(addOnTotal);
                      }}
                    >
                      <div className="card-image">
                        <img src={addOn.image} alt="" />
                      </div>
                      <div className="card-content">
                        <h2>{addOn.name}</h2>
                        <p>{addOn.brand}</p>
                        {/* <span>₹ {addOn.price}</span> */}
                      </div>
                      <Checkbox
                        checked={isChecked.some(
                          (id: string) => id === addOn._id
                        )}
                        // onChange={(e) => {
                        //   setIsChecked(addOn.name);
                        //   const serviceObj = Object.assign({}, service);
                        //   serviceObj.addOns = [addOn._id];
                        //   dispatch({ type: "service", payload: serviceObj });
                        // }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="addOn__sideBar">
            <SideBar addOnPrice={0} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOn;
