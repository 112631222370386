import axios from "axios";
import { getCookie } from "./utils";
let configHeader;
let axiosInstance = axios.create({
  headers: {
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    const accessToken = getCookie("access_token");
    if (accessToken != null) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return handleError(error.response);
  }
);

export const sendGetRequest = (url: string, config?: Object) => {
  configHeader = setHeader(config as Object);
  return axiosInstance.get(url, configHeader).then((response) => {
    return response.data;
  });
};

export const sendPostRequest = (url: string, data: Object, config?: Object) => {
  configHeader = setHeader(config as Object);
  return axiosInstance.post(url, data, configHeader).then((response) => {
    return response.data;
  });
};

export const sendEncodedPostRequest = (url: string, data: Object) => {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  configHeader = setHeader(config);
  return axiosInstance.post(url, data, configHeader).then((response) => {
    return response.data;
  });
};

export const sendPutRequest = (url: string, data: Object, config?: Object) => {
  configHeader = setHeader(config as Object);
  return axiosInstance.put(url, data, configHeader).then((response) => {
    return response.data;
  });
};

export const sendDeleteRequest = (url: string, config?: Object) => {
  configHeader = setHeader(config as Object);
  return axiosInstance.delete(url, config).then((response) => {
    return response.data;
  });
};

const handleError = (error: any) => {
  if (error.status === 401 || error.status === 403 || error.data.status === 401 || error.data.status === 403) {
    redirectToLogin();
  }
  return Promise.reject(error.data || error);
};

export const redirectToLogin = () => {
  const expiry_date = new Date("1 Jan 1920").toUTCString();
  try {
    document.cookie = `access_token= ;expires=${expiry_date}`;
    document.cookie = `userId= ;expires=${expiry_date}`;
    document.cookie = `isAdmin= ;expires=${expiry_date}`;
    document.cookie = `userPhone= ;expires=${expiry_date}`;
    const url = `${window.location.origin}/`;
    window.location.href = url;

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (err) {
    console.log(err);
  }
};

const setHeader = (config: Object) => {
  if (config) {
    return config;
  } else {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    return config;
  }
};
