import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ClickAwayListener from "@mui/base/ClickAwayListener";

import "./Header.scss";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl } from "@material-ui/core";
import IntlTelInput from "react-intl-tel-input";
import { phoneRegEx } from "../../utils/helpers/constants";
import { redirectToLogin, sendGetRequest, sendPostRequest } from "../../utils/helpers/requestController";
import { apiEndPoints } from "../../utils/helpers/endPoints";
import OtpInputs from "../../pages/Home/OtpInputs";
import { SpannerDoorContext } from "../../utils/context/GlobalContext";

import { getCookie, setCookie } from "../../utils/helpers/utils";

import userCircle from "../../assets/images/userCircle.svg";
import signOut from "../../assets/images/signOut.svg";
import { Home, Reload, History, Document, Logout, Orders, Garage, Accessories, Help, HamburgerMenu } from "../../assets/Icons";
import { useCustomDialog } from "../Dialog/CustomDialog/CustomDialogService";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [openLoginForm, setOpenLoginForm] = useState(false);
  const [dropDown, showDropDown] = useState(false);
  const [user, setUser] = useState<any>();
  const isAdmin = getCookie("isAdmin") === "true";
  const accessToken = getCookie("access_token");
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useMediaQuery("(min-width: 960px)");

  const handleDropdown = () => {
    setOpen(!open);
  };
  console.log("Dropdown", dropDown);

  const showLogin = () => {
    setOpenLoginForm(!openLoginForm);
  };

  const getUserData = async () => {
    const res = await sendGetRequest(apiEndPoints.userData);
    setUser(res.data);
    setCookie("userPhone", res.data?.user?.phone);
  };

  useEffect(() => {
    if (accessToken) {
      getUserData();
    }
  }, []);

  const LoginForm = () => {
    const [phoneNum, setPhoneNum] = useState("");
    const [pageView, setPageView] = useState("login");
    const { service, dispatch }: any = React.useContext(SpannerDoorContext);

    const handlePhoneChange = (phoneNum: any) => {
      setPhoneNum(phoneNum?.replace(/\s/g, ""));
      const serviceObj = Object.assign({}, service);
      serviceObj.usersInfo.contact = phoneNum.replace(/\s/g, "");
      dispatch({ type: "service", payload: serviceObj });
    };

    const handleBooking = async (evt: any) => {
      evt.preventDefault();
      evt.stopPropagation();
      const payload = {
        phone: phoneNum,
      };
      try {
        await sendPostRequest(apiEndPoints.addUser, payload);
        setPageView("otp");
      } catch (error: any) {}
    };

    const changeView = (value: any) => {
      if (!value) {
        setPageView("login");
      } else {
        setPageView("otp");
      }
    };

    return (
      <div>
        {pageView === "login" ? (
          <Dialog open={openLoginForm} onClose={showLogin} className="login-dialog">
            {/* <DialogTitle>Login</DialogTitle> */}
            <DialogContent>
              <DialogContentText>
                <Box>
                  <FormControl fullWidth className="phone-input">
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control"
                      placeholder="Enter your mobile number"
                      defaultCountry="in"
                      fieldName="tel"
                      fieldId="PhoneNumber"
                      numberType="MOBILE"
                      onPhoneNumberChange={(isValid: boolean, value: string, selectedCountryData: any, fullNumber: string, extension: string) =>
                        handlePhoneChange(fullNumber)
                      }
                    />
                    {phoneNum?.length > 0 && !phoneRegEx.test(phoneNum) && (
                      <div className="form__input-error">
                        <p>Please enter valid phone number.</p>
                      </div>
                    )}
                  </FormControl>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Box>
                <FormControl fullWidth>
                  <Button type="button" onClick={handleBooking} variant="contained" disabled={phoneNum?.length > 0 && phoneRegEx.test(phoneNum) ? false : true}>
                    Get OTP
                  </Button>
                </FormControl>
              </Box>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog open={openLoginForm} onClose={showLogin} className="login-dialog">
            <OtpInputs onClose={showLogin} type="login" setSubmitted={changeView} />
          </Dialog>
        )}
      </div>
    );
  };
  const customDialog = useCustomDialog();
  return (
    // {isAdmin ?
    <header className={`header ${location.pathname == "/" || location.pathname == "/home" ? "add-border" : ""}`}>
      <div className="container header__block">
        <div className="logo">
          <Link to="/">
            <img src={"../../../images/logo.svg"} className="logo-desktop" alt="logo"></img>
            <img src={"../../../images/mobile-logo.svg"} className="logo-mobile" alt="logo"></img>
          </Link>
        </div>
        <div className="header__right">
          <div className="mobile-dropdown">
            {accessToken === false ? (
              <div className="menu__item" onClick={showLogin}>
                {/* <a href="javascript:void(0)" className="menu__link">
                  Log In
                </a> */}
              </div>
            ) : (
              <div className="menu__item">
                <img
                  src={userCircle}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    getUserData();
                    showDropDown(!dropDown);
                  }}
                />
                {dropDown && (
                  <ClickAwayListener onClickAway={() => showDropDown(!dropDown)}>
                    <div className="profile">
                      <div className="profile__drop">
                        <div className="user-details">
                          <div className="user-picture">
                            <img src={userCircle} alt="" />
                          </div>
                          <div className="user-name">
                            <div className="name">Hi {user?.user?.name} !</div>
                            <div className="number">{user?.user?.phone} </div>
                          </div>
                        </div>
                        <hr />
                        <div className="user-navigation">
                          <div
                            className="menu-item-drop"
                            onClick={() => {
                              isAdmin ? navigate("/admin/dashboard") : navigate("/service/home");
                            }}
                          >
                            {isAdmin ? (
                              <>
                                <Orders />
                                <span>Orders</span>
                              </>
                            ) : (
                              <>
                                <Home />
                                <span>Dashboard</span>
                              </>
                            )}
                          </div>
                          <div
                            className="menu-item-drop"
                            onClick={() => {
                              isAdmin ? navigate("/admin/garage-list") : navigate("/service/dashboard");
                            }}
                          >
                            {isAdmin ? (
                              <>
                                <Garage />
                                <span>Garage List</span>
                              </>
                            ) : (
                              <>
                                <Reload />
                                <span>
                                  <span className="mob-hide">On Going</span>
                                  &nbsp;Services
                                </span>
                              </>
                            )}
                          </div>
                          <div
                            className="menu-item-drop"
                            onClick={() => {
                              isAdmin ? navigate("/admin/accessories") : navigate("/service/history");
                            }}
                          >
                            {isAdmin ? (
                              <>
                                <Accessories />
                                <span>
                                  <span className="mob-hide">Accessories</span>
                                </span>
                              </>
                            ) : (
                              <>
                                <History />
                                <span>
                                  <span className="mob-hide">Service</span>
                                  &nbsp; History
                                </span>
                              </>
                            )}
                          </div>
                          {/* <div
                            className="menu-item-drop"
                            onClick={() => {
                              isAdmin ? navigate("/admin/help") : navigate("/service/help");
                            }}
                          >
                            {isAdmin ? (
                              <>
                                <Help />
                                <span>
                                  Help
                                  <span className="mob-hide">& Support</span>
                                </span>
                              </>
                            ) : (
                              <>
                                <Help />
                                <span>
                                  Help
                                  <span className="mob-hide">& Support</span>
                                </span>
                              </>
                            )}
                          </div> */}
                        </div>
                        <div
                          className="logout"
                          onClick={() => {
                            customDialog({
                              catchOnCancel: false,
                              description: ["Sign out Successfully", "You have sign out successfully!"],
                              customClass: "no-description",
                              successLabel: "",
                              maxWidthProp: "md",
                              imgSrc: signOut,
                            }).then(async () => {
                              redirectToLogin();
                            });
                          }}
                        >
                          <Logout />
                          <span style={{ marginLeft: "20px" }}>Logout</span>
                        </div>
                      </div>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            )}
          </div>
          <button className="menu-button" onClick={() => handleDropdown()}>
            <HamburgerMenu />
          </button>
          <div className={`header__right-menu menu ${open ? "active" : ""}`}>
            <div className="menu__item">
              <a href="/" className="menu__link">
                Home
              </a>
            </div>
            <div className="menu__item">
              <a href="/about" className="menu__link">
                About
              </a>
            </div>
            <div className="menu__item">
              <a href="/services" className="menu__link">
                Services
              </a>
            </div>
            <div className="menu__item">
              <a href="https://spannerdoor.medium.com/" className="menu__link" target="_blank">
                Blog
              </a>
            </div>
            <div className="menu__item">
              <a href="/contact" className="menu__link">
                Contact
              </a>
            </div>
            {accessToken === false ? (
              <div className="menu__item logo-desktop" onClick={showLogin}>
                {/* <a href="javascript:void(0)" className="menu__link">
                  Log In
                </a> */}
              </div>
            ) : (
              <div className="menu__item logo-desktop">
                <img
                  src={userCircle}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    getUserData();
                    showDropDown(!dropDown);
                  }}
                />
                {isDesktop && dropDown && (
                  <ClickAwayListener onClickAway={() => showDropDown(!dropDown)}>
                    <div className="profile">
                      <div className="profile__drop">
                        <div className="user-details">
                          <div className="user-picture">
                            <img src={userCircle} alt="" />
                          </div>
                          <div className="user-name">
                            <div className="name">Hi {user?.user?.name} !</div>
                            <div className="number">{user?.user?.phone} </div>
                          </div>
                        </div>
                        <hr />
                        <div className="user-navigation">
                          <div
                            className="menu-item-drop"
                            onClick={() => {
                              isAdmin ? navigate("/admin/dashboard") : navigate("/service/home");
                            }}
                          >
                            {isAdmin ? (
                              <>
                                <Orders />
                                <span>Orders</span>
                              </>
                            ) : (
                              <>
                                <Home />
                                <span>Dashboard</span>
                              </>
                            )}
                          </div>
                          <div
                            className="menu-item-drop"
                            onClick={() => {
                              isAdmin ? navigate("/admin/garage-list") : navigate("/service/dashboard");
                            }}
                          >
                            {isAdmin ? (
                              <>
                                <Garage />
                                <span>Garage List</span>
                              </>
                            ) : (
                              <>
                                <Reload />
                                <span>
                                  <span className="mob-hide">On Going</span>
                                  &nbsp;Services
                                </span>
                              </>
                            )}
                          </div>
                          <div
                            className="menu-item-drop"
                            onClick={() => {
                              isAdmin ? navigate("/admin/accessories") : navigate("/service/history");
                            }}
                          >
                            {isAdmin ? (
                              <>
                                <Accessories />
                                <span>
                                  <span className="mob-hide">Accessories</span>
                                </span>
                              </>
                            ) : (
                              <>
                                <History />
                                <span>
                                  <span className="mob-hide">Service</span>
                                  &nbsp; History
                                </span>
                              </>
                            )}
                          </div>
                          {/* <div
                            className="menu-item-drop"
                            onClick={() => {
                              isAdmin ? navigate("/admin/help") : navigate("/service/help");
                            }}
                          >
                            {isAdmin ? (
                              <>
                                <Help />
                                <span>
                                  Help
                                  <span className="mob-hide">& Support</span>
                                </span>
                              </>
                            ) : (
                              <>
                                <Help />
                                <span>
                                  Help
                                  <span className="mob-hide">& Support</span>
                                </span>
                              </>
                            )}
                          </div> */}
                        </div>
                        <div
                          className="logout"
                          onClick={() => {
                            customDialog({
                              catchOnCancel: false,
                              description: ["Sign out Successfully", "You have sign out successfully!"],
                              customClass: "no-description",
                              successLabel: "",
                              maxWidthProp: "md",
                              imgSrc: signOut,
                            }).then(async () => {
                              redirectToLogin();
                            });
                          }}
                        >
                          <Logout />
                          <span style={{ marginLeft: "20px" }}>Logout</span>
                        </div>
                      </div>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {LoginForm()}
    </header>
  );
};

export default Header;
