import React from "react";
import { Link } from "react-router-dom";

import { mailRegEx } from "../../utils/helpers/constants";
import { textRegEx } from "../../utils/helpers/constants";
import { phoneRegEx } from "../../utils/helpers/constants";
import { apiEndPoints } from "../../utils/helpers/endPoints";
import { Contact, ContactError } from "../../utils/models/contact";
import { sendPostRequest } from "../../utils/helpers/requestController";

import "./ContactUs.scss";

import { Email, Phone, Office, Follow, Fb, Twt, Insta } from "../../assets/Icons";

const ContactUs = ({ type = "" }) => {
  const [open, setOpen] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [contact, setContact] = React.useState(new Contact({}));
  const [contactError, setContactError] = React.useState(new ContactError({}));

  const sendContact = async () => {
    const res = await sendPostRequest(apiEndPoints.contact, contact);
    if (res) {
      setOpen(true);
      setContact(new Contact({}));
      setBtnDisabled(true);
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  };

  React.useEffect(() => {
    const contactObj = Object.assign({}, contact);
    if (!contactObj.email || !contactObj.message || !contactObj.name || !contactObj.phone) {
      setBtnDisabled(true);
    } else if (Object.values(contactError).includes(true)) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [contact, contactError]);

  const handleError = async (e: any) => {
    const contactErrorObj = Object.assign({}, contactError);

    switch (e.target.name) {
      case "Name":
        contactErrorObj.name = e.target.value.trim().length === 0 || !textRegEx.test(e.target.value.trim());
        break;
      case "email":
        contactErrorObj.email = e.target.value.trim().length === 0 || !mailRegEx.test(e.target.value.trim());
        break;
      case "phone":
        contactErrorObj.phone = e.target.value.trim().length === 0 || !phoneRegEx.test(e.target.value.trim());
        break;
      case "message":
        contactErrorObj.message = e.target.value.trim().length === 0;
        break;
    }
    setContactError(contactErrorObj);
    // setBtnDisabled(Object.values(contactErrorObj).includes(true));
  };

  const handleChange = (type: string, value: string) => {
    setContact({
      ...contact,
      [type]: value,
    });
  };
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="contact">
      {!type && (
        <div className="contact__top">
          <div className="container">
            <div className="contact__block">
              <div className="contact__left">
                <h1>Let’s get connected</h1>
                <p>Can’t find the solution you are looking for? Here’s how to get help from our spannerdoor community</p>
              </div>
              {!type && (
                <div className="contact__right">
                  <Link to="/careers" className="button button--primary">
                    Open Positions
                  </Link>
                  <Link to="/about" className="button button--outline">
                    About Us
                  </Link>
                </div>
              )}
            </div>
          </div>
          {open && <h3 className="align-center">Query submitted successfully</h3>}
        </div>
      )}
      {type && open && <h3 className="align-center">Query submitted successfully</h3>}
      <div className="container">
        <div className={`contact__block contact__bottom ${type}`}>
          <div className="contact__details">
            <div className="contact__item">
              <Email />
              <div className="contact__item-flex">
                <span className="dark-text">Contact Support</span>
                <span>
                  <a className="light-text" href="mailto:care@spannerdoor.com">
                    care@spannerdoor.com
                  </a>
                </span>
              </div>
            </div>
            <div className="contact__item">
              <Phone />
              <div className="contact__item-flex">
                <span className="dark-text">Helpline</span>
                <span className="light-text">+91-8050428282</span>
              </div>
            </div>
            <div className="contact__item">
              <Office className="office-svg" />
              <div className="contact__item-flex">
                <span className="dark-text">Office</span>
                <span className="light-text">88/89 Gurukrupa, Kempegowda Layout, Near Palanahalli Lake,</span>
                <span className="light-text">Yelahanka, Bangalore Karnataka, India - 560064</span>
              </div>
            </div>

            <div className="contact__item">
              <Follow />
              <div className="contact__item-flex">
                <span className="dark-text">Follow Us</span>
                <div className="social-links">
                  <Link to="https://www.facebook.com/people/Spannerdoor/100091129757170/?mibextid=ZbWKwL" target="blank">
                    <Fb />
                  </Link>
                  <Link to="https://twitter.com/spannerdoor" target="blank">
                    <Twt />
                  </Link>
                  <Link to="https://www.instagram.com/spannerdoor/" target="blank">
                    <Insta />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="contact__form">
            <div className="form__group">
              <label htmlFor="Name" className="form__label">
                Name
              </label>
              <input
                type="text"
                name="Name"
                id="Name"
                className="form__input"
                placeholder="Enter your Name"
                value={contact?.name}
                onChange={(evt: any) => handleChange("name", evt.target.value)}
                onBlur={handleError}
              />
              {contactError.name && (
                <div className="form__input-error">
                  <p>Please enter the Name.</p>
                </div>
              )}
            </div>
            <div className="form__group">
              <label htmlFor="email" className="form__label">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="form__input"
                placeholder="Enter your email"
                value={contact?.email}
                onChange={(evt: any) => handleChange("email", evt.target.value)}
                onBlur={handleError}
              />
              {contactError.email && (
                <div className="form__input-error">
                  <p>Please enter the email.</p>
                </div>
              )}
            </div>
            <div className="form__group">
              <label htmlFor="phone" className="form__label">
                Phone number
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                className="form__input"
                placeholder="Enter your contact number"
                value={contact?.phone}
                onChange={(evt: any) => handleChange("phone", evt.target.value)}
                onBlur={handleError}
              />
              {contactError.phone && (
                <div className="form__input-error">
                  <p>Please enter the Phone Number.</p>
                </div>
              )}
            </div>
            <div className="form__group">
              <label htmlFor="message" className="form__label">
                Message
              </label>
              <textarea
                name="message"
                id="message"
                className="form__input"
                placeholder="Enter your message"
                value={contact?.message}
                onChange={(evt: any) => handleChange("message", evt.target.value)}
                onBlur={handleError}
              >
                Your message...
              </textarea>
              {contactError.message && (
                <div className="form__input-error">
                  <p>Please enter the message.</p>
                </div>
              )}
            </div>

            <button
              className={btnDisabled ? "button button--disabled" : "button button--primary"}
              disabled={btnDisabled}
              // style={{ opacity: btnDisabled ? "0.5" : "1" }}
              onClick={sendContact}
            >
              Send
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
