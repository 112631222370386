import React from "react";
import {
  PickupICon,
  PartsIcon,
  ServiceWarantyIcon,
  AffordablePrice,
  FastService,
  PickupGurantee,
  GeniunePart,
  ServiceWaranty,
  Assistence
} from "../../../assets/Icons";
import "./ServiceGurantee.scss";
const ServiceGurantee = () => {
  return (
    <div>
      <div className="service-gurantee">
        <div className="container">
          <div className="service-gurantee__header">
            <div className="service-gurantee__title">Spannerdoor Guarantee</div>
            <div className="service-gurantee__text">
            Convenient and complimentary vehicle pickup and drop-off for hassle-free
servicing.
            </div>
          </div>
          <div className="service-gurantee__block">
            <div className="service-gurantee__item">
              <PickupGurantee />
              <div className="service-gurantee__item-title">
                Free Pickup Drop
              </div>
              <div className="service-gurantee__item-text">
                Stay connected with your team and make quick decisions wherever
                you are.
              </div>
            </div>
            <div className="service-gurantee__item">
              <GeniunePart />
              <div className="service-gurantee__item-title">Genuine Parts</div>
              <div className="service-gurantee__item-text">
              Reliable performance guaranteed with our genuine parts for optimal vehicle
              maintenance.
              </div>
            </div>
            <div className="service-gurantee__item">
              <ServiceWaranty />
              
              <div className="service-gurantee__item-title">
                Service Warranty
              </div>
              <div className="service-gurantee__item-text">
              Peace of mind assured with our comprehensive service warranty for your vehicle.
              </div>
            </div>
          </div>
          <div className="service-gurantee__block">
            <div className="service-gurantee__item">
              <FastService />
              <div className="service-gurantee__item-title">
              Fast Service
              </div>
              <div className="service-gurantee__item-text">
              Efficient and swift service delivered with our commitment to speed and quality.
              </div>
            </div>
            <div className="service-gurantee__item">
              <Assistence />
              <div className="service-gurantee__item-title">Roadside assistance</div>
              <div className="service-gurantee__item-text">
              Providing help when you're on the go, road-side assistance is a life-saving show.
              </div>
            </div>
            <div className="service-gurantee__item">
              <AffordablePrice />
              <div className="service-gurantee__item-title">
              Affordable price
              </div>
              <div className="service-gurantee__item-text">
              Quality doesn't have to break the bank, our services come at affordable ranks.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceGurantee;
