import React from "react";
import "./Careers.scss";
import { useNavigate, Link } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Location, Clock } from "../../assets/Icons";

const Careers = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const careers = [
    // {
    //   title: "Front-end Developer",
    //   workMode: "Remote friendly",
    //   workModeIcon: <Location />,
    //   workType: "Full-time",
    //   workTypeIcon: <Clock/>,
    //   action: "View Job",
    // },
    // {
    //   title: "Back-end Developer",
    //   workMode: "Remote friendly",
    //   workModeIcon: <Location />,
    //   workType: "Full-time",
    //   workTypeIcon: <Clock/>,
    //   action: "View Job",
    // },
    {
      title: "UI/UX Designer",
      workMode: "Remote friendly",
      workModeIcon: <Location />,
      workType: "Full-time",
      workTypeIcon: <Clock />,
      action: "View Job",
    },
    // {
    //   title: "Product Designer",
    //   workMode: "Remote friendly",
    //   workModeIcon: <Location />,
    //   workType: "Full-time",
    //   workTypeIcon: <Clock/>,
    //   action: "View Job",
    // },
    // {
    //   title: "Project Manager",
    //   workMode: "Remote friendly",
    //   workModeIcon: <Location />,
    //   workType: "Full-time",
    //   workTypeIcon: <Clock/>,
    //   action: "View Job",
    // },
    // {
    //   title: "Front-end Developer",
    //   workMode: "Remote friendly",
    //   workModeIcon: <Location />,
    //   workType: "Full-time",
    //   workTypeIcon: <Clock/>,
    //   action: "View Job",
    // },
    // {
    //   title: "Back-end Developer",
    //   workMode: "Remote friendly",
    //   workModeIcon: <Location />,
    //   workType: "Full-time",
    //   workTypeIcon: <Clock/>,
    //   action: "View Job",
    // },
  ];
  return (
    <div className="career">
      <div className="container">
        <span className="career__badge">We are hiring</span>
        <h2 className="career__title">Join our team</h2>
        <p>
          Our philosophy is simple — hire a team of diverse, passionate people
          and foster a culture <br /> that empowers you to do you best work.
        </p>
        <div className="career__fiter">
          {/* <form>
            <div className="form__row form__row--three-col">
              <div className="form__group">
                <label htmlFor="search" className="form__label">
                  Search
                </label>
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="form__input"
                  placeholder="Search "
                  value=""
                  // onChange=""
                  // onBlur={handleError}
                />

               
              </div>

              <div className="form__group">
                <label htmlFor="Location" className="form__label">
                  Location
                </label>
                <FormControl>
                 

                  <Select
                    labelId="demo-simple-select-label"
                    name="location"
                    id="Location"
                    value="Bangalore"
                    placeholder=""
                    // onChange={handleChange}
                    // onBlur={handleError}
                  >
                    <MenuItem value={10}>Bangalore</MenuItem>
                  </Select>
                </FormControl>
               
              </div>

              <div className="form__group">
                <label htmlFor="type" className="form__label">
                  Type
                </label>
                <FormControl>
                 

                  <Select
                    labelId="demo-simple-select-label"
                    name="type"
                    id="type"
                    value="Office"
                    // onChange={handleChange}
                    // onBlur={handleError}
                  >
                    <MenuItem value={10}>Remote</MenuItem>
                    <MenuItem value={20}>Office</MenuItem>
                    <MenuItem value={30}>Hybrid</MenuItem>
                  </Select>
                </FormControl>
               
              </div>
            </div>
          </form> */}
        </div>
        <div className="career__block">
          {careers.map((career, index) => (
            <div className="career__item">
              <div className="career__item-title">{career.title}</div>
              <div className="career__item-inner">
                <div className="career__item-wrkmode">
                  {career.workModeIcon} {career.workMode}
                </div>
                <div className="career__item-wrktype">
                  {career.workTypeIcon} {career.workType}
                </div>
                <Link
                  to="/career-details"
                  className="career__item-action button"
                >
                  {career.action}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="getintouch">
        <div className="container getintouch__block">
          <h1>Profile not matched?</h1>
          <p>If your profile is not listed above, send your resume</p>
          <Link
            to="mailto:info@spannerdoor.com"
            className="button"
            onClick={() => {
              navigate("/careers");
            }}
          >
            Send Your Resume Here
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Careers;
