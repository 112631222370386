import React from "react";
import "./AddGarage.scss";
import LeftSideBar from "../LeftSideBar/LeftSideBar";
import { LeftArrowCircle, Map } from "../../../assets/Icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Garage, GarageError } from "../../../utils/models/garage";
import { apiEndPoints } from "../../../utils/helpers/endPoints";

import {
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
} from "../../../utils/helpers/requestController";
import {
  mailRegEx,
  phoneRegEx,
  pincodeRegEx,
  textRegEx,
} from "../../../utils/helpers/constants";
const AddGarage = ({ isEdit = false }) => {
  const params = useParams();
  const [garage, setGarage] = React.useState(new Garage());
  const [garageErr, setGarageErr] = React.useState(new GarageError());
  const [btnDisabled, setBtnDisabled] = React.useState(true);

  const navigate = useNavigate();
  const getGarage = async () => {
    const res = await sendGetRequest(
      apiEndPoints.garageList + "/" + params?.id
    );
    setGarage(res);
  };

  const updateGarage = async () => {
    if (params?.id) {
      const res = await sendPutRequest(
        apiEndPoints.garageList + "/" + params?.id,
        garage
      );
      if (res) {
        navigate("/admin/garage-list");
      }
    } else {
      delete garage._id;
      delete garage.__v;
      delete garage.updatedAt;
      delete garage.createdAt;
      const res = await sendPostRequest(apiEndPoints.garageList, garage);
      if (res) {
        navigate("/admin/garage-list");
      }
    }
  };

  React.useEffect(() => {
    if (params?.id) {
      getGarage();
      setBtnDisabled(false);
    }
  }, []);
  const handleChange = (e: any) => {
    const garageObj = Object.assign({}, garage);
    switch (e.target.name) {
      case "cust-name":
        garageObj.name = e.target.value;
        break;
      case "cust-email":
        garageObj.email = e.target.value;
        break;
      case "cust-contact":
        garageObj.contact = e.target.value;
        break;
      case "cust-dhouse-no":
        garageObj.address.address = e.target.value;
        break;
      case "cust-dlocality":
        garageObj.address.locality = e.target.value;
        break;
      case "cust-dpincode":
        garageObj.address.pincode = e.target.value;
        break;
      case "cust-dcity":
        garageObj.address.city = e.target.value;
        break;
      case "cust-dstate":
        garageObj.address.state = e.target.value;
        break;
    }
    setGarage(garageObj);
  };

  const handleError = (e: any) => {
    const errorObj = Object.assign({}, garageErr);
    switch (e.target.name) {
      case "cust-name":
        errorObj.name =
          e.target.value.trim().length === 0 ||
          !textRegEx.test(e.target.value.trim());
        break;
      case "cust-email":
        // pattern validation needed
        errorObj.email =
          e.target.value.trim().length === 0 ||
          !mailRegEx.test(e.target.value.trim());
        break;
      case "cust-contact":
        // patter needed
        errorObj.contact =
          e.target.value.trim().length === 0 ||
          !phoneRegEx.test(e.target.value.trim());
        break;
      case "cust-dhouse-no":
        errorObj.address = e.target.value.trim().length === 0;
        break;
      case "cust-dlocality":
        errorObj.locality = e.target.value.trim().length === 0;
        break;
      case "cust-dpincode":
        errorObj.pincode =
          e.target.value.trim().length === 0 ||
          !pincodeRegEx.test(e.target.value.trim());
        break;
      case "cust-dcity":
        errorObj.city = e.target.value.trim().length === 0;
        break;
      case "cust-dstate":
        errorObj.state = e.target.value.trim().length === 0;
        break;
    }
    setGarageErr(errorObj);
    setBtnDisabled(Object.values(errorObj).includes(true));
  };
  return (
    <div className="dashboard-layout admin">
      <div className="dashboard-layout__left">
        <LeftSideBar />
      </div>
      <div className="dashboard-layout__right">
        <div className="garage-list">
          <div className="service-dashboard__header">
            <h3 className="go-back">
              <Link to="/admin/garage-list">
                <LeftArrowCircle
                  onClick={() => {
                    navigate("/admin/garage-list");
                  }}
                />{" "}
                {params?.id ? "Edit Garage" : "Add Garage"}
              </Link>
            </h3>
          </div>
          <div className="add-garage">
            <h5 className="add-garage__block-title">Delivery Address</h5>

            <div className="form__row form__row--three-col">
              <div className="form__group">
                <label htmlFor="custName" className="form__label">
                  Name
                </label>
                <input
                  type="text"
                  name="cust-name"
                  id="custName"
                  className="form__input"
                  placeholder="Add your name"
                  value={garage?.name}
                  onChange={handleChange}
                  onBlur={handleError}
                />
                {garageErr.name && (
                  <div className="form__input-error">
                    <p>Please enter valid name.</p>
                  </div>
                )}
              </div>

              <div className="form__group">
                <label htmlFor="custEmail" className="form__label">
                  Email
                </label>
                <input
                  type="text"
                  name="cust-email"
                  id="custEmail"
                  className="form__input msg-icon"
                  placeholder="example@yourmail.com"
                  value={garage?.email}
                  onChange={handleChange}
                  onBlur={handleError}
                />
                {garageErr.email && (
                  <div className="form__input-error">
                    <p>Please enter valid email.</p>
                  </div>
                )}
              </div>
              <div className="form__group pos-relative">
                <label htmlFor="custContact" className="form__label">
                  Contact No
                </label>
                <input
                  type="text"
                  name="cust-contact"
                  id="custContact"
                  className="form__input tel-icon text-bold"
                  placeholder="+91 99341 34543"
                  value={garage?.contact}
                  onChange={handleChange}
                  onBlur={handleError}
                />
                {garageErr.contact && (
                  <div className="form__input-error">
                    <p>Please enter valid Phone number.</p>
                  </div>
                )}
              </div>
            </div>
            <div className="form__row form__row--three-col">
              <div className="form__group">
                <label htmlFor="cust DHouseNo" className="form__label">
                  House No, Building
                </label>
                <input
                  type="text"
                  name="cust-dhouse-no"
                  id="cust DHouseNo"
                  className="form__input"
                  placeholder="eg 32/1, President Apartment"
                  value={garage?.address?.address}
                  onChange={handleChange}
                  onBlur={handleError}
                />
                {garageErr.address && (
                  <div className="form__input-error">
                    <p>Please enter address.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="custDLocality" className="form__label">
                  Locality
                </label>
                <input
                  type="text"
                  name="cust-dlocality"
                  id="custDLocality"
                  className="form__input"
                  placeholder="eg Jayanagar"
                  value={garage?.address?.locality}
                  onChange={handleChange}
                  onBlur={handleError}
                />
                {garageErr.locality && (
                  <div className="form__input-error">
                    <p>Please enter locality.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="custDPincode" className="form__label">
                  Pincode
                </label>
                <input
                  type="text"
                  name="cust-dpincode"
                  id="custDPincode"
                  className="form__input"
                  placeholder="eg 560011"
                  value={garage?.address?.pincode}
                  onChange={handleChange}
                  onBlur={handleError}
                />
                {garageErr.pincode && (
                  <div className="form__input-error">
                    <p>Please enter valid Pincode.</p>
                  </div>
                )}
              </div>
            </div>
            <div className="form__row form__row--two-col">
              <div className="form__group">
                <label htmlFor="custDCity" className="form__label">
                  City
                </label>
                <input
                  type="text"
                  name="cust-dcity"
                  id="custDCity"
                  className="form__input"
                  placeholder="eg Bangalore"
                  value={garage?.address?.city}
                  onChange={handleChange}
                  onBlur={handleError}
                />
                {garageErr.city && (
                  <div className="form__input-error">
                    <p>Please enter city.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="custDState" className="form__label">
                  State
                </label>
                <input
                  type="text"
                  name="cust-dstate"
                  id="custDState"
                  className="form__input"
                  placeholder="eg Karnataka"
                  value={garage?.address?.state}
                  onChange={handleChange}
                  onBlur={handleError}
                />
                {garageErr.state && (
                  <div className="form__input-error">
                    <p>Please enter state.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="location-map" className="form__label">
                  Location Map
                </label>
                <input
                  type="text"
                  name="location-map"
                  id="location-map"
                  className="form__input map-icon "
                  placeholder="Paste Location Map"
                  value=""
                />
              </div>
            </div>
            <div className="form__actions">
              <button
                className={
                  btnDisabled
                    ? "button button--disabled"
                    : "button button--enabled"
                }
                disabled={btnDisabled}
                // style={{ opacity: btnDisabled ? "0.5" : "1" }}
                onClick={updateGarage}
              >
                {params?.id ? "Update Garage" : "Save Garage"}
              </button>
              <button
                className="button  button--disabled"
                onClick={() => {
                  navigate("/admin/garage-list");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddGarage;
