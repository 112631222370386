import React, { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import SideBar from "../../components/SideBar/SideBar";
import { SpannerDoorContext } from "../../utils/context/GlobalContext";
import { ServiceError, IServiceError } from "../../utils/models/service";
import "./CustomerInfo.scss";
import {
  sendGetRequest,
  sendPostRequest,
} from "../../utils/helpers/requestController";
import { apiEndPoints } from "../../utils/helpers/endPoints";
import { getCookie } from "../../utils/helpers/utils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Snackbar, Select, MenuItem, InputLabel } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import BookigStepper from "../Service-package/Stepper";
import { pincodeData } from "../../utils/helpers/pincodeData";
import { mailRegEx } from "../../utils/helpers/constants";
import { textRegEx } from "../../utils/helpers/constants";
import { vehicleRegEx } from "../../utils/helpers/constants";
import signOut from "../../assets/images/signOut.svg";
import { useCustomDialog } from "../../components/Dialog/CustomDialog/CustomDialogService";
const CustomerInfo = () => {
  const [serviceErr, setServiceErr] = React.useState<IServiceError>(
    new ServiceError()
  );
  const { service, dispatch }: any = React.useContext(SpannerDoorContext);

  const [enabledDelivery, setEnabledDelivery] = React.useState(true);
  const [submittable, setSubmittable] = React.useState(false);
  const [enable, setEnable] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [pickupLocality, setPickupLocality] = React.useState([]);
  const [deliveryLocality, setDeliveryLocality] = React.useState([]);
  const [message, setMessage] = React.useState("");
  // const [inputTxt, setinputTxt] = React.useState(false);
  const navigate = useNavigate();
  const customDialog = useCustomDialog();
  useEffect(() => {
    const serviceObj = Object.assign({}, service);
    if (
      serviceObj?.usersInfo?.contact?.length > 0 &&
      serviceObj?.usersInfo?.email?.length > 0 &&
      serviceObj?.usersInfo?.name?.length > 0 &&
      serviceObj?.vehicleInfo?.vehicleNo.length > 0 &&
      serviceObj?.address?.pickup?.address.length > 0 &&
      serviceObj?.address?.pickup?.city.length > 0 &&
      serviceObj?.address?.pickup?.landmark.length > 0 &&
      serviceObj?.address?.delivery?.landmark.length > 0 &&
      serviceObj?.address?.pickup?.locality.length > 0 &&
      serviceObj?.address?.pickup?.pincode.length > 0 &&
      serviceObj?.address?.pickup?.state.length > 0 &&
      serviceObj?.address?.delivery?.address.length > 0 &&
      serviceObj?.address?.delivery?.city.length > 0 &&
      serviceObj?.address?.delivery?.locality.length > 0 &&
      serviceObj?.address?.delivery?.pincode.length > 0 &&
      serviceObj?.address?.delivery?.state.length > 0 &&
      serviceObj?.slot?.date &&
      serviceObj?.slot?.time
    ) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [service]);

  useEffect(() => {
    if (
      service?.address?.pickup?.pincode &&
      ["560063", "560064"].includes(service?.address?.pickup?.pincode)
    ) {
      const address: any = pincodeData[service?.address?.pickup?.pincode];
      setPickupLocality(address);
    }
    if (
      service?.address?.delivery?.pincode &&
      ["560063", "560064"].includes(service?.address?.delivery?.pincode)
    ) {
      const address: any = pincodeData[service?.address?.delivery?.pincode];
      setDeliveryLocality(address);
    }
  }, [service]);

  useEffect(() => {
    if (sessionStorage.getItem("deliveryAddress") === "sameAddress") {
      setEnabledDelivery(false);
    } else {
      setEnabledDelivery(true);
    }
  }, []);

  const handleFormDataChange = (e: any) => {
    const serviceObj = Object.assign({}, service);
    switch (e.target.name) {
      case "cust-name":
        serviceObj.usersInfo.name = e.target.value.replace(/[^a-z\s]/gi, "");
        break;
      case "cust-email":
        serviceObj.usersInfo.email = e.target.value;
        break;
      case "cust-contact":
        serviceObj.usersInfo.contact = e.target.value;
        break;
      case "cust-vehicle":
        serviceObj.vehicleInfo.vehicleNo = e.target.value;
        break;
      case "cust-house-no":
        serviceObj.address.pickup.address = e.target.value;
        if (sessionStorage.getItem("deliveryAddress") === "sameAddress") {
          serviceObj.address.delivery.address = e.target.value;
        }
        break;
      case "cust-locality":
        serviceObj.address.pickup["locality"] = e.target.value;
        if (sessionStorage.getItem("deliveryAddress") === "sameAddress") {
          serviceObj.address.delivery.locality = e.target.value;
        }
        break;
      case "cust-pincode":
        serviceObj.address.pickup["pincode"] = e.target.value;
        if (sessionStorage.getItem("deliveryAddress") === "sameAddress") {
          serviceObj.address.delivery.pincode = e.target.value;
        }
        break;
      case "cust-city":
        serviceObj.address.pickup["city"] = e.target.value;
        if (sessionStorage.getItem("deliveryAddress") === "sameAddress") {
          serviceObj.address.delivery.city = e.target.value;
        }
        break;
      case "cust-state":
        serviceObj.address.pickup["state"] = e.target.value;
        if (sessionStorage.getItem("deliveryAddress") === "sameAddress") {
          serviceObj.address.delivery.state = e.target.value;
        }
        break;
      case "cust-landmark":
        serviceObj.address.pickup["landmark"] = e.target.value;
        if (sessionStorage.getItem("deliveryAddress") === "sameAddress") {
          serviceObj.address.delivery.landmark = e.target.value;
        }
        break;
      case "cust-dlandmark":
        serviceObj.address.delivery.landmark = e.target.value;
        break;
      case "cust-pickup-date":
        serviceObj.slot.date = e.target.value;
        break;
      case "cust-time":
        serviceObj.slot.time = e.target.value;
        break;
      case "cust-dhouse-no":
        serviceObj.address.delivery.address = e.target.value;
        break;
      case "cust-dlocality":
        serviceObj.address.delivery.locality = e.target.value;
        break;
      case "cust-dpincode":
        serviceObj.address.delivery.pincode = e.target.value;
        break;
      case "cust-dcity":
        serviceObj.address.delivery.city = e.target.value;
        break;
      case "cust-dstate":
        serviceObj.address.delivery.state = e.target.value;
        break;
    }
    dispatch({ type: "service", payload: serviceObj });
  };

  const handleError = async (e: any) => {
    const serviceObj = Object.assign({}, serviceErr);

    switch (e.target.name) {
      case "cust-name":
        serviceObj.name =
          e.target.value.trim().length === 0 ||
          !textRegEx.test(e.target.value.trim());
        break;
      case "cust-email":
        // pattern validation needed
        serviceObj.email =
          e.target.value.trim().length === 0 ||
          !mailRegEx.test(e.target.value.trim());
        break;
      case "cust-contact":
        // patter needed
        serviceObj.contact = e.target.value.trim().length === 0;
        break;
      case "cust-vehicle":
        // serviceObj.vehicleNo = e.target.value.trim().length === 0 || !vehicleRegEx.test(e.target.value.trim());
        serviceObj.vehicleNo = e.target.value.trim().length === 0;
        break;
      case "cust-house-no":
        serviceObj.pAddress = e.target.value.trim().length === 0;
        break;
      case "cust-locality":
        serviceObj.pLocality = e.target.value.trim().length === 0;
        break;
      case "cust-landmark":
        serviceObj.pLandmark = e.target.value.trim().length === 0;
        break;
      case "cust-dlandmark":
        serviceObj.dLandmark = e.target.value.trim().length === 0;
        break;
      case "cust-pincode":
        // const res = await sendGetRequest(
        //   apiEndPoints.pincodeGet + e.target.value
        // );
        // serviceObj.pPincode =
        //   e.target.value.trim().length === 0 ||
        //   !pincodeRegEx.test(e.target.value.trim());
        debugger;
        serviceObj.pPincode =
          e.target.value.trim().length === 0 ||
          !["560063", "560064"].includes(e.target.value.trim());

        if (!serviceObj.pPincode) {
          const serviceObj = Object.assign({}, service);
          const address: any = pincodeData[e.target.value.trim()];
          serviceObj.address.pickup.state = address[0].State;
          serviceObj.address.pickup.city = address[0].District;
          setPickupLocality(address);
          dispatch({ type: "service", payload: serviceObj });
        }
        break;
      case "cust-city":
        serviceObj.pCity = e.target.value.trim().length === 0;
        break;
      case "cust-state":
        serviceObj.pState = e.target.value.trim().length === 0;
        break;
      case "cust-pickup-date":
        serviceObj.date = e.target.value.trim().length === 0;
        break;
      // case "cust-time":
      //   serviceObj.time = e.target.value.trim().length === 0;
      //   break;
      case "cust-dhouse-no":
        serviceObj.dAddress = e.target.value.trim().length === 0;
        break;
      case "cust-dlocality":
        serviceObj.dLocality = e.target.value.trim().length === 0;
        break;
      case "cust-dpincode":
        // serviceObj.dPincode =
        //   e.target.value.trim().length === 0 ||
        //   !pincodeRegEx.test(e.target.value.trim());
        serviceObj.dPincode =
          e.target.value.trim().length === 0 ||
          !["560063", "560064"].includes(e.target.value.trim());
        if (!serviceObj.dPincode) {
          const serviceObj = Object.assign({}, service);
          const address: any = pincodeData[e.target.value.trim()];
          serviceObj.address.delivery.state = address[0].State;
          serviceObj.address.delivery.city = address[0].District;
          setDeliveryLocality(address);
          dispatch({ type: "service", payload: serviceObj });
        }
        break;
      case "cust-dcity":
        serviceObj.dCity = e.target.value.trim().length === 0;
        break;
      case "cust-dstate":
        serviceObj.dState = e.target.value.trim().length === 0;
        break;
    }
    setServiceErr(serviceObj);
  };

  useEffect(() => {
    !Object.values(serviceErr).includes(true)
      ? setSubmittable(true)
      : setSubmittable(false);
  }, [serviceErr]);

  const handleAddress = (e: any) => {
    sessionStorage.setItem("deliveryAddress", e.target.value);
    if (e.target.value === "sameAddress") {
      setEnabledDelivery(false);
      const serviceObj = Object.assign({}, service);
      setDeliveryLocality(pickupLocality);
      serviceObj.address.delivery.address = service.address.pickup.address;
      serviceObj.address.delivery.locality = service.address.pickup.locality;
      serviceObj.address.delivery.pincode = service.address.pickup.pincode;
      serviceObj.address.delivery.city = service.address.pickup.city;
      serviceObj.address.delivery.landmark = service.address.pickup.landmark;
      serviceObj.address.delivery.state = service.address.pickup.state;
      dispatch({ type: "service", payload: serviceObj });
    } else {
      const serviceObj = Object.assign({}, service);
      serviceObj.address.delivery.address = "";
      serviceObj.address.delivery.locality = "";
      serviceObj.address.delivery.pincode = "";
      serviceObj.address.delivery.landmark = "";
      serviceObj.address.delivery.city = "";
      serviceObj.address.delivery.state = "";
      setEnabledDelivery(true);
      dispatch({ type: "service", payload: serviceObj });
    }
  };

  const submitHandler = async () => {
    try {
      const payload = { ...service, userId: getCookie("userId") };
      const res = await sendPostRequest(apiEndPoints.createService, payload);
      setOpen(true);
      setMessage("Information Saved");
      sessionStorage.setItem("serviceId", res._id);
      dispatch({ type: "loading", payload: true });
      setTimeout(() => {
        dispatch({ type: "loading", payload: false });
        navigate("/service/addon");
      }, 3000);
    } catch (error: any) {
      if (error?.name === "ValidationError") {
        setOpen(true);
        setMessage("Required Fields Missing");
      } else if (error?.name === "DuplicateRequest") {
        customDialog({
          catchOnCancel: false,
          description: [error?.message],
          link: {
            link: "/service/dashboard",
            key: "Dashboard",
            message: "Track vehicle status from your ",
          },
          customClass: "no-description",
          successLabel: "",
          maxWidthProp: "md",
          imgSrc: signOut,
        });
      }
    }
  };

  const getUserData = async () => {
    const res = await sendGetRequest(apiEndPoints.userData);
    console.log(res);
    const serviceObj = Object.assign({}, service);
    if (!serviceObj.usersInfo.name) {
      serviceObj.usersInfo.name = res.data.user.name;
    }
    if (!serviceObj.usersInfo.email) {
      serviceObj.usersInfo.email = res.data.user.email;
    }
    if (!serviceObj.usersInfo.pickup && res.data.user.address) {
      serviceObj.address.pickup = res.data.user.address;
    }
    if (!serviceObj.vehicleInfo.vehicleNo && res.data.user.vehicleNo) {
      serviceObj.vehicleInfo.vehicleNo = res.data.user.vehicleNo;
    }
    dispatch({
      type: "service",
      payload: serviceObj,
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="customer-info">
      <BookigStepper step={1} />
      <div className="container">
        {/* <h4 className="customer-info__title">Customer Info</h4> */}
        <div className="customer-info__block">
          <div className="customer-info__left">
            <h5 className="customer-info__block-title">Customer Info</h5>
            <div className="form__row form__row--three-col">
              <div className="form__group">
                <label htmlFor="custName" className="form__label">
                  Name*
                </label>
                <input
                  type="text"
                  name="cust-name"
                  id="custName"
                  className="form__input"
                  placeholder="Add your name"
                  value={service?.usersInfo?.name}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                />

                {serviceErr.name && (
                  <div className="form__input-error">
                    <p>Please enter the name.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="custEmail" className="form__label">
                  Email*
                </label>
                <input
                  type="text"
                  name="cust-email"
                  id="custEmail"
                  className="form__input msg-icon"
                  placeholder="Add your email"
                  value={service?.usersInfo?.email}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                />

                {serviceErr.email && (
                  <div className="form__input-error">
                    <p>Please enter valid email.</p>
                  </div>
                )}
              </div>
              <div className="form__group pos-relative">
                <label htmlFor="custContact" className="form__label">
                  Contact No*
                </label>
                <input
                  type="text"
                  name="cust-contact"
                  id="custContact"
                  className="form__input tel-icon text-bold"
                  placeholder="Add your contact number"
                  value={service?.usersInfo.contact}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                  disabled
                />
                {serviceErr.contact && (
                  <div className="form__input-error">
                    <p>Please enter the contact number.</p>
                  </div>
                )}
              </div>
            </div>
            <div className="form__row form__row--one-col">
              <div className="form__group">
                <label htmlFor="custVehicle" className="form__label">
                  Vehicle No*
                </label>
                <input
                  type="text"
                  name="cust-vehicle"
                  id="custVehicle"
                  className="form__input"
                  placeholder="Add your vehicle number"
                  value={service?.vehicleInfo?.vehicleNo}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                />

                {serviceErr.vehicleNo && (
                  <div className="form__input-error">
                    <p>Please enter the vehicle number.</p>
                  </div>
                )}
              </div>
            </div>

            <hr />

            <h5 className="customer-info__block-title">Pickup Address</h5>

            <div className="form__row form__row--one-col mt-0">
              {/* <div className="form__group">
                <button className="map-icon button button--light">Use My Current Location</button>
              </div> */}
              <div className="form__group">
                <FormControlLabel
                  control={<Checkbox />}
                  label="Required Towing?"
                  onChange={(e: any) => {
                    const serviceObj = Object.assign({}, service);
                    serviceObj.slot.towingRequired = e.target.checked;
                    dispatch({
                      type: "service",
                      payload: serviceObj,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form__row form__row--three-col">
              <div className="form__group">
                <label htmlFor="custPincode" className="form__label">
                  Pincode*
                </label>
                <input
                  type="number"
                  name="cust-pincode"
                  id="custPincode"
                  className="form__input"
                  placeholder="Add you Pincode"
                  value={service?.address?.pickup?.pincode}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                />
                <div className="form__input-error" style={{ color: "black" }}>
                  <p>
                    Currently Service is available at 560063 and 560064 only.
                  </p>
                </div>
                {serviceErr.pPincode && (
                  <div className="form__input-error">
                    <p>Please enter valid pickup pincode.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="custHouseNo" className="form__label">
                  House No, Building*
                </label>
                <input
                  type="text"
                  name="cust-house-no"
                  id="custHouseNo"
                  className="form__input"
                  placeholder="Add your address"
                  value={service?.address?.pickup?.address}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                />

                {serviceErr.pAddress && (
                  <div className="form__input-error">
                    <p>Please enter the pickup address.</p>
                  </div>
                )}
              </div>
              {/* <div className="form__group">
                <label htmlFor="custLocality" className="form__label">
                  Locality
                </label>
                <input
                  type="text"
                  name="cust-locality"
                  id="custLocality"
                  className="form__input"
                  placeholder="eg Jayanagar"
                  value={service?.address?.pickup?.locality}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                />

                {serviceErr.pLocality && (
                  <div className="form__input-error">
                    <p>Please enter the pickup locality.</p>
                  </div>
                )}
              </div> */}
              <div className="form__group">
                <label htmlFor="custLocality" className="form__label">
                  Locality*
                </label>
                <FormControl>
                  {!service?.address?.pickup?.locality && (
                    <InputLabel shrink={false} id="demo-simple-select-label">
                      eg Jayanagar
                    </InputLabel>
                  )}
                  <Select
                    labelId="demo-simple-select-label"
                    name="cust-locality"
                    id="custLocality"
                    value={service?.address?.pickup?.locality}
                    onChange={handleFormDataChange}
                    onBlur={handleError}
                    placeholder="Add your locality"
                  >
                    {pickupLocality &&
                      pickupLocality.map((location: any) => (
                        <MenuItem value={location.City} key={location.City}>
                          {location.City}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {serviceErr.pLocality && (
                  <div className="form__input-error">
                    <p>Please enter the pickup locality.</p>
                  </div>
                )}
              </div>
            </div>
            <div className="form__row form__row--three-col">
              <div className="form__group">
                <label htmlFor="custCity" className="form__label">
                  City*
                </label>
                <input
                  type="text"
                  name="cust-city"
                  id="custCity"
                  className="form__input"
                  placeholder="Add your city"
                  value={service?.address?.pickup?.city}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                  disabled
                />

                {serviceErr.pCity && (
                  <div className="form__input-error">
                    <p>Please enter the pickup city.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="custState" className="form__label">
                  State*
                </label>
                <input
                  type="text"
                  name="cust-state"
                  id="custState"
                  className="form__input"
                  placeholder="Add your state"
                  value={service?.address?.pickup?.state}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                  disabled
                />

                {serviceErr.pState && (
                  <div className="form__input-error">
                    <p>Please enter the pickup state.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="custLandmark" className="form__label">
                  Landmark*
                </label>
                <input
                  type="text"
                  name="cust-landmark"
                  id="custLandmark"
                  className="form__input"
                  placeholder="Add your landmark"
                  value={service?.address?.pickup?.landmark}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                />
                {serviceErr.pLandmark && (
                  <div className="form__input-error">
                    <p>Please enter the delivery address.</p>
                  </div>
                )}
              </div>
            </div>
            <div className="form__row form__row--two-col">
              <div className="form__group">
                <label htmlFor="custPickupDate" className="form__label">
                  Pickup Date*
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    label="Pickup date"
                    // defaultValue={
                    //   service?.slot?.date && new Date(service?.slot?.date)
                    // }
                    value={service?.slot?.date && dayjs(service?.slot?.date)}
                    className="time-picker-class"
                    onChange={(newVal: any) => {
                      const serviceObj = Object.assign({}, service);
                      serviceObj.slot.date = new Date(newVal.$d).toISOString();
                      dispatch({
                        type: "service",
                        payload: service,
                      });
                    }}
                  />
                </LocalizationProvider>

                {/* {serviceErr.date && (
                  <div className="form__input-error">
                    <p>Please enter the pickup Date.</p>
                  </div>
                )} */}
              </div>
              <div className="form__group">
                <label htmlFor="custTime" className="form__label">
                  Pickup Time*
                </label>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    ampm={false}
                    className="time-picker-class"
                    label="Pickup Time"
                    minTime={
                      moment(service?.slot?.date).isSame(moment(), "day")
                        ? dayjs().set("hour", moment().hour())
                        : dayjs().set("hour", 7).set("minute", 0)
                    }
                    maxTime={dayjs().set("hour", 19)}
                    value={
                      service?.slot?.time &&
                      dayjs(moment(service?.slot?.time, "HH:mm").toDate())
                    }
                    onChange={(newVal: any) => {
                      const serviceObj = Object.assign({}, service);
                      serviceObj.slot.time = moment(newVal.$d).format("HH:mm");
                      dispatch({
                        type: "service",
                        payload: service,
                      });
                    }}
                  />
                </LocalizationProvider>

                {/* {serviceErr.time && (
                  <div className="form__input-error">
                    <p>Please enter the pickup time.</p>
                  </div>
                )} */}
              </div>
            </div>
            <hr />
            <h5 className="customer-info__block-title">Delivery Address</h5>
            <div className="form__group form__group--radio">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="sameAddress"
                    control={<Radio />}
                    label="Deliver in Same Address"
                    onClick={handleAddress}
                    checked={
                      sessionStorage.getItem("deliveryAddress") ===
                      "sameAddress"
                    }
                  />
                  <FormControlLabel
                    value="newAddress"
                    control={<Radio />}
                    label="Deliver In New Address"
                    onClick={handleAddress}
                    checked={
                      sessionStorage.getItem("deliveryAddress") === "newAddress"
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="form__row form__row--three-col">
              <div className="form__group">
                <label htmlFor="custDPincode" className="form__label">
                  Pincode*
                </label>
                <input
                  type="number"
                  name="cust-dpincode"
                  id="custDPincode"
                  className="form__input"
                  placeholder="Add your pincode"
                  value={service?.address?.delivery?.pincode}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                  disabled={enabledDelivery ? false : true}
                />

                {serviceErr.dPincode && (
                  <div className="form__input-error">
                    <p>Please enter valid delivery pincode.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="cust DHouseNo" className="form__label">
                  House No, Building*
                </label>
                <input
                  type="text"
                  name="cust-dhouse-no"
                  id="cust DHouseNo"
                  className="form__input"
                  placeholder="Add your address"
                  value={service?.address?.delivery?.address}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                  disabled={enabledDelivery ? false : true}
                />

                {serviceErr.dAddress && (
                  <div className="form__input-error">
                    <p>Please enter the delivery address.</p>
                  </div>
                )}
              </div>
              {/* <div className="form__group">
                <label htmlFor="custDLocality" className="form__label">
                  Locality
                </label>
                <input
                  type="text"
                  name="cust-dlocality"
                  id="custDLocality"
                  className="form__input"
                  placeholder="eg Jayanagar"
                  value={service?.address?.delivery?.locality}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                  disabled={enabledDelivery ? false : true}
                />

                {serviceErr.dLocality && (
                  <div className="form__input-error">
                    <p>Please enter the delivery locality.</p>
                  </div>
                )}
              </div> */}
              <div className="form__group">
                <label htmlFor="custDLocality" className="form__label">
                  Locality*
                </label>
                <FormControl>
                  {!service?.address?.delivery?.locality && (
                    <InputLabel shrink={false} id="demo-simple-select-label">
                      eg Jayanagar
                    </InputLabel>
                  )}
                  <Select
                    labelId="demo-simple-select-label"
                    name="cust-dlocality"
                    id="custDLocality"
                    placeholder="Add your locality"
                    value={service?.address?.delivery?.locality}
                    onChange={handleFormDataChange}
                    onBlur={handleError}
                    disabled={enabledDelivery ? false : true}
                    variant="outlined"
                  >
                    {deliveryLocality &&
                      deliveryLocality.map((location: any) => (
                        <MenuItem value={location.City} key={location.City}>
                          {location.City}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {serviceErr.dLocality && (
                  <div className="form__input-error">
                    <p>Please enter the delivery locality.</p>
                  </div>
                )}
              </div>
            </div>
            <div className="form__row form__row--two-col">
              <div className="form__group">
                <label htmlFor="custDCity" className="form__label">
                  City*
                </label>
                <input
                  type="text"
                  name="cust-dcity"
                  id="custDCity"
                  className="form__input"
                  placeholder="Add your city"
                  value={service?.address?.delivery?.city}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                  disabled={enabledDelivery ? true : true}
                />

                {serviceErr.dCity && (
                  <div className="form__input-error">
                    <p>Please enter the delivery city.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="custDState" className="form__label">
                  State*
                </label>
                <input
                  type="text"
                  name="cust-dstate"
                  id="custDState"
                  className="form__input"
                  placeholder="Add your state"
                  value={service?.address?.delivery?.state}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                  disabled={enabledDelivery ? true : true}
                />

                {serviceErr.dState && (
                  <div className="form__input-error">
                    <p>Please enter the delivery state.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="custLandmark" className="form__label">
                  Landmark*
                </label>
                <input
                  type="text"
                  name="cust-dlandmark"
                  id="custLandmark"
                  className="form__input"
                  placeholder="Add your landmark"
                  value={service?.address?.delivery?.landmark}
                  onChange={handleFormDataChange}
                  onBlur={handleError}
                  disabled={enabledDelivery ? false : true}
                />
                {serviceErr.dLandmark && (
                  <div className="form__input-error">
                    <p>Please enter the delivery address.</p>
                  </div>
                )}
              </div>
            </div>

            <div className="form__actions">
              <button
                className={
                  submittable && enable
                    ? "button button--enabled"
                    : "button button--disabled"
                }
                onClick={submitHandler}
              >
                Save
              </button>
              {/* <button className="button">Cancel</button> */}
            </div>
          </div>
          <div className="customer-info__right">
            <SideBar disabledPayment={true} />
          </div>
          <Snackbar open={open} autoHideDuration={6000} message={message} />
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
