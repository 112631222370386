import React, { useEffect } from "react";

import "./Service-package.scss";
import BookigStepper from "./Stepper";
import { sendGetRequest } from "../../utils/helpers/requestController";
import { apiEndPoints } from "../../utils/helpers/endPoints";
import { SpannerDoorContext } from "../../utils/context/GlobalContext";
import ShowMore from "./ShowMore";
import { useNavigate } from "react-router-dom";

const ServicePackage = () => {
  const { dispatch, packages, service }: any = React.useContext(SpannerDoorContext);

  const navigate = useNavigate();

  const getServicePakage = async () => {
    const res = await sendGetRequest(`${apiEndPoints.servicePackage}`);
    dispatch({ type: "packages", payload: res });
  };
  useEffect(() => {
    getServicePakage();
  }, []);

  return (
    <div className="service-package">
      <div className="container">
        <BookigStepper step={0} />

        <h2 className="service-package__title">Select Service Package</h2>
        <p className="service-package__text">Flexible pricing plan for your Bike Services</p>

        <div className="service-package__block">
          {packages?.map((packageService: any) => (
            <div className="service-package__item">
              <div className="service-package__recomm">
                <div className="service-package__recomm-text">{packageService.isRecommended && <span>Recommended</span>}</div>
              </div>

              <div className="service-package__item-title">{packageService.name}</div>
              <div className="service-package__item-price">&#x20B9;{packageService.price}</div>
              <div className="service-package__item-action">
                <button
                  className="button button--outline "
                  onClick={() => {
                    dispatch({
                      type: "service",
                      payload: { ...service, packages: packageService._id },
                    });
                    navigate("/service/create");
                  }}
                >
                  Select Package
                </button>
              </div>
              <hr />
              <p className="service-package__block-text">What's Included</p>

              <ShowMore packagelist={packageService.serviceList} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicePackage;
