import _ from "lodash";
import React from "react";
import { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Dialog,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input,
  MenuItem,
  Select,
} from "@mui/material";

import LeftSideBar from "../LeftSideBar/LeftSideBar";
import { apiEndPoints } from "../../../utils/helpers/endPoints";
import { TaxRate, accessoriesTyp } from "../../../utils/helpers/constants";
import AddIcon from "@mui/icons-material/Add";
import {
  Accessories,
  AccessoriesError,
} from "../../../utils/models/accessories";
import {
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
} from "../../../utils/helpers/requestController";

import "./AddAccessories.scss";

import { LeftArrowCircle, UploadFile } from "../../../assets/Icons";
import { Box, padding } from "@mui/system";

let accessoryData: any = new FormData();
const AddAccessories = ({ isEdit = false }) => {
  const inputRefRegister: any = useRef();

  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [accessoryGroup, setAccessoryGroup] = React.useState([]);
  const params = useParams();
  const [accessories, setAccessories] = React.useState<any>(new Accessories());
  const [accessoriesErr, setAccessoriesErr] = React.useState(
    new AccessoriesError()
  );
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const updatedRef = useRef();
  const navigate = useNavigate();
  const getAccessories = async () => {
    const res = await sendGetRequest(
      apiEndPoints.accessories + "/" + params?.id
    );
    setAccessories(res);
    updatedRef.current = JSON.parse(JSON.stringify(res));
  };
  const [open, setOpen] = React.useState<any>(false);
  const [error, Seterror] = React.useState<any>(false);
  const [category, setCategory] = React.useState<any>();

  const handleClose = () => {
    setOpen(false);
    Seterror(false);
  };

  const addType = async () => {
    const res = await sendPostRequest(apiEndPoints.addOnGroup, {
      name: category,
    });
    if (res) {
      setOpen(false);
      getAddOnGroup();
    }
  };

  const handleErrorCategoy = () => {
    if (!category?.trim()) {
      Seterror(true);
    } else {
      Seterror(false);
    }
  };

  const updateAccessories = async () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (params?.id) {
      if (!_.isEqual(updatedRef.current, accessories)) {
        const res = await sendPutRequest(
          apiEndPoints.accessories + "/" + params?.id,
          accessories
        );
        if (accessoryData.has("addOnImage")) {
          const res = await sendPutRequest(
            apiEndPoints.accessories + "/" + params?.id + "/uploadImage",
            accessoryData,
            config
          );
          if (res) {
            navigate("/admin/accessories");
          }
        } else {
          navigate("/admin/accessories");
        }
      } else if (accessoryData.has("addOnImage")) {
        const res = await sendPutRequest(
          apiEndPoints.accessories + "/" + params?.id + "/uploadImage",
          accessoryData,
          config
        );
        if (res) {
          navigate("/admin/accessories");
        }
      } else {
        //snackbar
      }
    } else {
      delete accessories._id;
      delete accessories.__v;
      delete accessories.updatedAt;
      delete accessories.createdAt;
      const res = await sendPostRequest(apiEndPoints.accessories, accessories);
      if (res) {
        const data = await sendPutRequest(
          apiEndPoints.accessories + "/" + res?._id + "/uploadImage",
          accessoryData,
          config
        );
        if (data) {
          navigate("/admin/accessories");
        }
      }
    }
  };

  const getAddOnGroup = async () => {
    const res = await sendGetRequest(apiEndPoints.addOnGroup);

    if (res) {
      setAccessoryGroup(res);
    }
  };

  React.useEffect(() => {
    if (params?.id) {
      getAccessories();
      setBtnDisabled(false);
    }
    getAddOnGroup();

    return () => {
      accessoryData = new FormData();
    };
  }, []);

  const handleChange = (e: any) => {
    const accessoriesObj = Object.assign({}, accessories);
    switch (e.target.name) {
      case "acc-code":
        accessoriesObj.code = e.target.value;
        break;
      case "acc-hsn":
        accessoriesObj.hsnCode = e.target.value;
        break;
      case "accessories-type":
        accessoriesObj.addOnsgroup = e.target.value;
        break;
      case "type":
        accessoriesObj.name = e.target.value;
        break;
      case "vehicle-type":
        accessoriesObj.vehicleType = e.target.value;
        break;
      case "acc-tax":
        accessoriesObj.taxRate = e.target.value;
        break;
      case "acc-brand":
        accessoriesObj.brand = e.target.value;
        break;
      case "acc-description":
        accessoriesObj.description = e.target.value;
        break;
      case "acc-price":
        accessoriesObj.price = e.target.value;
        break;
    }
    setAccessories(accessoriesObj);
  };

  const handleError = (e: any) => {
    const errorObj = Object.assign({}, accessoriesErr);
    switch (e.target.name) {
      case "acc-code":
        errorObj.code = e.target.value.trim().length === 0;
        break;
      case "acc-hsn":
        // pattern validation needed
        errorObj.hsncode = e.target.value.trim().length === 0;
        break;
      case "accessories-type":
        // patter needed
        errorObj.accessoriesType = e.target.value.length === 0;
        break;
      case "type":
        errorObj.type = e.target.value.length === 0;
        break;
      case "vehicle-type":
        errorObj.vehicleType = e.target.value.length === 0;
        break;
      case "acc-tax":
        errorObj.taxRate = e.target.value === undefined;
        break;
      case "acc-description":
        errorObj.brand = e.target.value.trim().length === 0;
        break;
      case "acc-price":
        errorObj.price = e.target.value.trim().length === 0;
        break;
    }
    setAccessoriesErr(errorObj);
    setBtnDisabled(Object.values(errorObj).includes(true));
  };

  const formatFileSize = function (bytes: any) {
    const sufixes = ["B", "kB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
  };

  const handleFileUpload = ({ target: { files } }: any, number: number) => {
    if (number === 1) {
      files[0] && setUploadedFile({ name: files[0].name, size: files[0].size });
    }

    accessoryData.append("addOnImage", files[0]);
  };

  const addAccessoryHandler = () => {
    setOpen(true);
  };

  return (
    <div className="dashboard-layout admin">
      <div className="dashboard-layout__left">
        <LeftSideBar />
      </div>
      <div className="dashboard-layout__right">
        <div className="garage-list">
          <div className="service-dashboard__header">
            <h3 className="go-back">
              <LeftArrowCircle
                onClick={() => {
                  navigate("/admin/accessories");
                }}
              />
              {params?.id ? "Edit Accessories" : " Add Accessories"}
            </h3>
          </div>
          <div className="add-garage">
            <h5 className="add-garage__block-title">
              {params?.id ? " Edit Accessories Info" : "  Add Accessories Info"}
            </h5>

            <div className="form__row form__row--three-col">
              <div className="form__group">
                <label htmlFor="accCode" className="form__label">
                  Code
                </label>
                <input
                  type="text"
                  name="acc-code"
                  id="accCode"
                  className="form__input"
                  placeholder="Add Code"
                  value={accessories?.code}
                  onChange={handleChange}
                  onBlur={handleError}
                />
                {accessoriesErr.code && (
                  <div className="form__input-error">
                    <p>Please enter valid code.</p>
                  </div>
                )}
              </div>

              <div className="form__group">
                <label htmlFor="accHSN" className="form__label">
                  HSN Code
                </label>
                <input
                  type="number"
                  name="acc-hsn"
                  id="accHSN"
                  className="form__input"
                  placeholder="Add HSN Code"
                  value={accessories?.hsnCode}
                  onChange={handleChange}
                  onBlur={handleError}
                />
                {accessoriesErr.hsncode && (
                  <div className="form__input-error">
                    <p>Please enter valid HSN code.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="vehicleType" className="form__label">
                  Vehicle Type*
                </label>
                <FormControl>
                  {/* <InputLabel shrink={false} id="demo-simple-select-label">
                    Select Type
                  </InputLabel> */}

                  <Select
                    labelId="demo-simple-select-label"
                    name="vehicle-type"
                    id="vehicleType"
                    value={accessories?.vehicleType}
                    onChange={handleChange}
                    onBlur={handleError}
                  >
                    {accessoriesTyp.map((acctype) => (
                      <MenuItem value={acctype.option} key={acctype.id}>
                        {acctype.option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {accessoriesErr.vehicleType && (
                  <div className="form__input-error">
                    <p>Please select the vehicle type.</p>
                  </div>
                )}
              </div>
            </div>
            <div className="form__row form__row--three-col">
              <div className="form__group">
                <div className="accessory_type">
                  <label htmlFor="accessoriesType" className="form__label">
                    Accessories Type*
                  </label>
                  <div className="add__container" onClick={addAccessoryHandler}>
                    <AddIcon
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        color: "#2f80ed",
                      }}
                    />
                  </div>
                </div>

                <FormControl>
                  {/* <InputLabel shrink={false} id="demo-simple-select-label">
                    Select Type
                  </InputLabel> */}

                  <Select
                    labelId="demo-simple-select-label"
                    name="accessories-type"
                    id="accessoriesType"
                    value={accessories?.addOnsgroup}
                    onChange={handleChange}
                    onBlur={handleError}
                  >
                    {accessoryGroup.map((acctype: any) => (
                      <MenuItem value={acctype._id} key={acctype._id}>
                        {acctype.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {accessoriesErr.accessoriesType && (
                  <div className="form__input-error">
                    <p>Please select the accessories type.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="type" className="form__label">
                  Type*
                </label>
                <FormControl>
                  {/* <InputLabel shrink={false} id="demo-simple-select-label">
                    Select Type
                  </InputLabel> */}

                  <input
                    type="text"
                    name="type"
                    id="accCode"
                    className="form__input"
                    placeholder="Add Type"
                    value={accessories?.name}
                    onChange={handleChange}
                    onBlur={handleError}
                  />
                </FormControl>
                {accessoriesErr.type && (
                  <div className="form__input-error">
                    <p>Please select the type.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="accDescription" className="form__label">
                  Sub Type
                </label>
                <input
                  type="text"
                  name="acc-brand"
                  id="accDescription"
                  className="form__input"
                  placeholder="Add Sub type"
                  value={accessories?.brand}
                  onChange={handleChange}
                  onBlur={handleError}
                />
                {accessoriesErr.brand && (
                  <div className="form__input-error">
                    <p>Please enter Sub type.</p>
                  </div>
                )}
              </div>
            </div>
            <div className="form__row form__row--two-col">
              <div className="form__group">
                <label htmlFor="accDescription" className="form__label">
                  Descriptions
                </label>
                <input
                  type="text"
                  name="acc-description"
                  id="description"
                  className="form__input"
                  placeholder="Add Description"
                  value={accessories?.description}
                  onChange={handleChange}
                  onBlur={handleError}
                />
                {accessoriesErr.description && (
                  <div className="form__input-error">
                    <p>Please enter descriptions.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="accPrice" className="form__label">
                  Price
                </label>
                <input
                  type="text"
                  name="acc-price"
                  id="accPrice"
                  className="form__input"
                  placeholder="0"
                  value={accessories?.price}
                  onChange={handleChange}
                  onBlur={handleError}
                />
                {accessoriesErr.price && (
                  <div className="form__input-error">
                    <p>Please enter price.</p>
                  </div>
                )}
              </div>
              <div className="form__group">
                <label htmlFor="accTax" className="form__label">
                  Tax Rate
                </label>
                <Select
                  labelId="demo-simple-select-label"
                  name="acc-tax"
                  id="vehicleType"
                  value={accessories?.taxRate}
                  onChange={handleChange}
                  onBlur={handleError}
                >
                  {TaxRate.map((tax: any) => (
                    <MenuItem value={tax.option} key={tax.option}>
                      {tax.option}%
                    </MenuItem>
                  ))}
                </Select>
                {accessoriesErr.taxRate && (
                  <div className="form__input-error">
                    <p>Please enter tax.</p>
                  </div>
                )}
              </div>
            </div>
            <div className="form__actions-btn">
              <label htmlFor="custLocality" className="form__label">
                Add Image*
              </label>
              <div className="file-uploader">
                <div
                  className="file-upload-button"
                  onClick={() => inputRefRegister.current?.click()}
                >
                  <input
                    id="file-upload"
                    type="file"
                    className="file-upload-input input-file"
                    hidden
                    ref={inputRefRegister}
                    onChange={({ target: { files } }) =>
                      handleFileUpload({ target: { files } }, 1)
                    }
                  />

                  {accessories.image && uploadedFile === null ? (
                    <div className="uploaded-file-list-box">
                      <div className="uploaded-file-details">
                        {/* <UploadFile /> */}
                        <img
                          src={accessories?.image}
                          alt=""
                          style={{ height: "40px" }}
                        />
                        <div className="uploaded-file-name-content">
                          {/* <span className="uploaded-file-name">
                            {formatFileSize(uploadedFile["size"])}
                          </span> */}
                          <div className="upload-btn-text">Change Image</div>
                        </div>
                      </div>
                    </div>
                  ) : uploadedFile != null ? (
                    <div className="uploaded-file-list-box">
                      <div className="uploaded-file-details">
                        <UploadFile />
                        <div className="uploaded-file-name-content">
                          <span className="uploaded-file-name">
                            {uploadedFile["name"]}
                          </span>

                          <span className="uploaded-file-name">
                            {formatFileSize(uploadedFile["size"])}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="upload-btn-wrap">
                      <UploadFile />
                      <div className="upload-btn-content">
                        <div className="upload-btn-text">Upload Image</div>
                        <div className="upload-btn-text-size">
                          Max file size: 500KB Supported file types PNG
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="form__actions">
              <button
                className={
                  btnDisabled
                    ? "button button--disabled"
                    : "button button--enabled"
                }
                disabled={btnDisabled}
                onClick={updateAccessories}
              >
                {params?.id ? "Update" : "Save"}
              </button>
              <button
                className="button  button--disabled"
                onClick={() => {
                  navigate("/admin/accessories");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <Box style={{ padding: "40px" }}>
          <DialogTitle style={{ paddingLeft: "0" }}>Add Category</DialogTitle>
          <DialogContentText style={{ paddingBottom: "10px" }}>
            Enter Accessory Type*
          </DialogContentText>
          <input
            className="form__input"
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            onBlur={handleErrorCategoy}
          />
          {error && (
            <div className="form__input-error">
              <p>Please enter the Type.</p>
            </div>
          )}
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              className="button button--outline"
              style={{ marginTop: "10px" }}
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className={
                category && category?.trim()?.length > 0
                  ? "button button--outline cancel"
                  : "button button--disabled"
              }
              style={{ marginTop: "10px" }}
              onClick={addType}
            >
              Add Type
            </button>
          </div>
        </Box>
      </Dialog>
    </div>
  );
};

export default AddAccessories;
