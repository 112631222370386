import React from "react";

import "./Privacy.scss";
const Privacy = () => {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="terms">
      <div className="terms-container container">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2">
            <section className="terms-title">
              <h1>Privacy &amp; Policy</h1>
            </section>

            <div className="terms-body">
              <h4>
                <div className="mb-10">
                  <strong>
                    Spannerdoor ("We", "Us", "Spannerdoor", or the "Site") is committed to user privacy and disclosure of user (hereinafter referred to as
                    “You”, “Your” and “Yourself”) information. This Privacy Policy is designed to help you understand how we collect and use the information you
                    decide to share with us
                  </strong>
                </div>
              </h4>

              <h3>1.GENERAL</h3>

              <p>
                1.1 www.Spannerdoor.com is an Internet-based portal owned and operated by Spannerdoor, a company incorporated under the laws of India. Use of
                the Website is offered to You conditioned on acceptance of all the terms, conditions and notices contained in these Terms, along with any
                amendments made by Spannerdoor at its sole discretion and posted on the Website, including by way of imposing an additional charge for access to
                or use of a Service.
              </p>
              <p>
                1.2 Spannerdoor shall not be required to notify You, whether as a registered user or not, of any changes made to the Terms of Use. The revised
                Terms of Use shall be made available on the Website. Your use of the Website and the Services is subject to the most current version of the
                Terms of Use made available on the Website at the time of such use. You are requested to regularly visit the home page www.Spannerdoor.com to
                view the most current Terms of Use.
              </p>
              <p>
                1.3 By (i) using this Website or any facility or Service provided by this Website in any way; or (ii) merely browsing the Website, You agree
                that You have read, understood and agreed to be bound by these Terms of Use and the Website’s Privacy Policy available at the homepage,
                www.Spannerdoor.com.
              </p>

              <h3>2. SERVICES</h3>
              <p>
                2.1 The Website is a platform that facilitates Reliable and Economic Vehicle Services and the resale of the vehicles offered by Spannerdoor
                (“Services”). The purchase of products and services on the Website shall be governed by the Terms of Offer for Sale (“Terms of Offer for Sale”).
              </p>

              <h3>3. ELIGIBILITY TO USE</h3>
              <p>
                3.1 The Services are not available to minors under the age of eighteen (18) or to any Users suspended or removed from the Spannerdoor system by
                Spannerdoor for any reason whatsoever. If You do not conform to the above qualification, you shall not be permitted to avail of the Services or
                use the Website. You represent that You are of legal age and eligible in all respects to form a binding contract and are not a person barred
                from receiving services under the laws as applicable in India. Notwithstanding the foregoing, if You are below the age of eighteen (18) years,
                you may avail of the Services provided by Spannerdoor through Your legal guardian in accordance with the applicable laws.
              </p>
              <p>
                3.2 Spannerdoor reserves the right to refuse access to use the Services offered at the Website to new Users or to terminate access granted to
                existing Users at any time without according to any reasons for doing so.
              </p>
              <p>
                3.3 You shall not have more than one active Account on the Website. Additionally, you are prohibited from selling, trading, or otherwise
                transferring Your Account to another party.
              </p>

              <h3>4. USER ACCOUNT, PASSWORD, AND SECURITY</h3>
              <p>
                4.1 Spannerdoor makes the Services available to You through the Website only if You have provided Spannerdoor certain required User information
                and created an account (“Account”) through Spannerdoor ID and password (collectively, the “Account Information”). The Services may also be
                subject to procedures for use of the Website, Terms of Offer for Sale, uploaded guidelines, rules, additional terms of service, or other
                disclaimers & notices, if any (“Additional Terms”). If there is any conflict between the Terms of Use and the Additional Terms, the Additional
                Terms shall take precedence in relation to that service.
              </p>
              <p>
                4.2 The Website requires You to register as a User by creating an Account to avail of the Services provided by the Website. You will be
                responsible for maintaining the confidentiality of the Account Information and are fully responsible for all activities that occur under Your
                Account. You agree to (a) immediately notify Spannerdoor of any unauthorized use of Your Account Information or any other breach of security,
                and (b) ensure that You exit from Your Account at the end of each session. Spannerdoor cannot and will not be liable for any loss or damage
                arising from Your failure to comply with this Section 4.2. You may be held liable for losses incurred by Spannerdoor or any other user of or
                visitor to the Website due to authorized or unauthorized use of Your Account because of Your failure in keeping Your Account Information secure
                and confidential.
              </p>
              <p>4.3 The Website also allows restricted access to the Services for unregistered Users.</p>
              <p>
                4.4 You shall ensure that the Account Information provided by You in the Website’s registration form is complete, accurate and up to date. Use
                of another user’s Account Information for availing of the Services is expressly prohibited.
              </p>
              <p>
                4.5 If You provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or
                incomplete), or Spannerdoor has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete,
                Spannerdoor has the right to suspend or terminate Your Account and refuse any and all current or future use of the Website (or any portion
                thereof) and further reserves it right to proceed against you as per law in case of any illegal or malicious activity in a whatsoever manner.
              </p>

              <h3>5. USE OF MATERIALS</h3>
              <p>
                5.1 Except as expressly indicated to the contrary in any applicable Additional Terms, Spannerdoor hereby grants You a non-exclusive, freely
                revocable (upon notice from Spannerdoor), non-transferable access to view, download and print product catalogues or any other materials
                available on the Website.
              </p>
              <p>
                5.2 The rights granted to You in the product catalogues, or any other materials as specified above are not applicable to the design, layout or
                look and feel of the Website. Such elements of the Website are protected by intellectual property rights and may not be copied or imitated in
                whole or in part. The product catalogues or any other materials available on the Website cannot be copied or retransmitted unless expressly
                permitted by Spannerdoor.
              </p>
              <p>
                5.3 Any purchase of the Reliable and Economic Vehicle Services and the resale of the vehicles from the Website will be strictly for the personal
                use of the User. The User hereby expressly agrees that any product or Services purchased by the User will not be sold, resold, bartered or in
                any way used for any commercial purposes or for profit. The User hereby acknowledges that the Services or products purchased is not
                transferrable to any third party for profit. In case of any breach of the aforesaid conditions, You would be liable to proceed in accordance
                with the appropriate law at your own risk and consequences and You accept the liability thereof.
              </p>
              <p>5.4 THIRD-PARTY CONTENT</p>
              <p>
                5.5 The Website makes available general third-party information such as, product catalogues, lists of authorized dealers, reports on news,
                entertainment, technology and features, advertisements including videos, images and photographs of the products and other data from external
                sources (“Third Party Content”). Similar Third-Party Content would also be available to You on the email received by You from Spannerdoor. The
                provision of Third-Party Content is for general informational purposes only. You acknowledge that the Third-Party Content provided to You is
                obtained from sources believed to be reliable. Spannerdoor does not provide any guarantee with respect to any Third-Party Content and
                Spannerdoor shall not be held liable for any loss suffered by You based on Your reliance on or use of such data.
              </p>
              <h3>6. TERMINATION</h3>
              <p>
                6.1 The Terms of Use will continue to apply until terminated by either You or Spannerdoor as set forth below. If You want to terminate Your
                agreement with Spannerdoor, you may do so by (i) not accessing the Website; or (ii) closing Your accounts for all the Services that You use,
                where Spannerdoor has made this option available to You.
              </p>
              <p>
                6.2 Spannerdoor may, at any time, with or without notice, terminate the Terms of Use (or portion thereof, such as any individual Additional
                Terms) with You if: You breach any of the provisions of the Terms of Use, the Privacy Policy or any other terms, conditions, or policies that
                may be applicable to You from time to time (or have acted in a manner that clearly shows that You do not intend to, or are unable to, comply
                with the same); Spannerdoor is required to do so by law (for example, where the provision of the Services to You is, or becomes, unlawful); The
                provision of the Services to You by Spannerdoor is, in Spannerdoor ‘s opinion, no longer commercially viable; Spannerdoor has elected to
                discontinue, with or without reason, access to the Website, the Services (or any part thereof); or
              </p>
              <p>
                6.3 Spannerdoor may also terminate or suspend all or a portion of Your account or access to the Services with or without reason. Except as may
                be set forth in any Additional Terms applicable to a particular Service, termination of Your Account may include (i) removal of access to all
                offerings within the Website or with respect to the Services; (ii) deletion of Your materials and Account Information, including Your personal
                information, log-in ID and password, and all related information, files and materials associated with or inside Your Account (or any part
                thereof); and (iii) barring of further use of the Services
              </p>
              <p>
                6.4 You agree that all terminations shall be made in Spannerdoor ‘s sole discretion and that Spannerdoor shall not be liable to You or any third
                party for any termination of Your Account (and accompanying deletion of Your Account Information), or Your access to the Website and Services.
              </p>
              <p>6.5 Notwithstanding the foregoing, these Terms of Use will survive indefinitely unless and until Spannerdoor chooses to terminate them.</p>

              <h3>7. REPORT ABUSE</h3>
              <p>
                7.1 In the event You come across any abuse or violation of these Terms of Use or if You become aware of any objectionable content on the
                Website, please report to Spannerdoor customer support
              </p>

              <h4>8 COMMUNICATIONS</h4>
              <p>
                8.1 You hereby expressly agree to receive communications by way of SMS and e-mails from Spannerdoor/authorized third party relating to Services
                provided through the Website.
              </p>
              <p>Type of information we collect</p>
              <p>We generally collect two types of information: personally submitted information and usage information:</p>
              <ul>
                <li>Personally Submitted Information</li>
                <p>
                  When you use Spannerdoor, any information you submit is stored in our servers. This information includes, but is not limited to, your name,
                  email address, contact number, vehicle details like make, model, variant, KM reading, registration number and other general information we ask
                  during registration on our platform. This information is stored in order to enable us deliver our services to you.
                </p>
                <p>
                  We will not disclose your personal information, such as contact number and email address, to third parties unless it is required for
                  fulfilment of services purchased, booked or availed by you through the Site. We shall use third party services needed to communicate with you
                  for providing service updates and reminders. Your privacy is very important to us.
                </p>
                <li>Usage Information</li>
                <p>
                  When you use Spannerdoor, we collect information pertaining to your IP address and browser type. This information is gathered from everyone
                  that uses the Spannerdoor site and mobile application. We also collect information on page views to help users see which articles are popular.
                </p>
                <li>Location Data</li>
                <p>
                  Location data may be used to display aggregate non-identifying user information such as total users in a given city, state, or zip code.
                  Location data will not be sold to third parties but may be used to display service providers and other services relevant to your location.
                </p>
              </ul>
              <strong>How we use information we collect</strong>
              <p>
                We store and interpret private information you submit only to the extent necessary to provide you with the services you want. We do not release
                information about any individual, including but not limited to their name, email address, and contact number.
              </p>
              <p>
                We may use aggregate, or collective, information in expansion of services or research. This information will not be tied to any individual name
                and will only be used to interpret certain trends, preferences of users registered.
              </p>
              <strong>Cookies</strong>
              <p>We employ the use of cookies, small packets of information stored on your computer, in order to enhance your use of the site.</p>
              <strong>User Controlled Privacy</strong>
              <p>
                At Spannerdoor, we respect user privacy, but also understand that users may want to share information with others and see what information
                others want to share. We give users the right to select how much of their profile is viewed by levels of users, such as registered users,
                garages, and the general public.
              </p>
              <p>Please use caution if electing to post private information to the public. This information would be available to anyone looking for it.</p>
              <p>
                The User hereby consents, expresses, and agrees that he has read and fully understands the Privacy Policy of Spannerdoor in respect of the
                Website. You further consent that the terms and contents of such Privacy Policy are acceptable to You.
              </p>
              <strong>Advertisements</strong>
              <p>
                We may use third parties to serve advertisements when you visit our Site. These parties may use information about your visits to this and other
                websites in order to provide advertisements about goods and services of interest to you. They do not have access to the account information that
                you have provided to Spannerdoor nor will we share this information with any third-party advertiser. To opt out of any data collection or other
                feature of third-party advertising, please contact the respective advertiser.
              </p>
              <h4>9 FEEDBACK AND INFORMATION</h4>
              <p>
                9.1 Any feedback You provide to this Website shall be deemed to be non-confidential. Spannerdoor shall be free to use such information on an
                unrestricted basis. Further, by submitting the feedback, You represent and warrant that (i) Your feedback does not contain confidential or
                proprietary information of You or of third parties; (ii) Spannerdoor is not under any obligation of confidentiality, express or implied, with
                respect to the feedback; (iii) Spannerdoor may have something similar to the feedback already under consideration or in development; and (iv)
                You are not entitled to any compensation or reimbursement of any kind from Spannerdoor for the feedback under any circumstances.
              </p>
              <p>Please Remember</p>
              <p>By using or accessing ‘Spannerdoor.in’, you accept the practices described in this Privacy Policy.</p>
              <p>
                If you have any doubts, err on the side of caution. If you have any questions or concerns, please feel free to contact us at
                info@Spannerdoor.com
              </p>
              <p>Thank you for using Spannerdoor.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
