import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  JobSheet,
  User,
  Settings,
  PhoneIcon,
  LeftArrowCircle,
} from "../../../assets/Icons";
import "./ViewBooking.scss";

import VerticalStepper from "../Stepper/Stepper";
import {
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
} from "../../../utils/helpers/requestController";
import { apiEndPoints } from "../../../utils/helpers/endPoints";
import LeftSideBar from "../LeftSideBar/LeftSideBar";
import moment from "moment";
import {
  Select,
  Slider,
  FormControl,
  InputLabel,
  Snackbar,
  Checkbox,
} from "@mui/material";

import MenuItem from "@material-ui/core/MenuItem";
import { useCustomDialog } from "../../../components/Dialog/CustomDialog/CustomDialogService";
import signOut from "../../../assets/images/signOut.svg";
import CloseIcon from "@mui/icons-material/Close";

const year = 2000;
const years = Array.from(new Array(24), (val, index) => index + year);

const ViewBooking = () => {
  const [service, setService] = React.useState<any>();
  const [category, setCategory] = React.useState<any>("");
  const [addons, setAddons] = React.useState<any>("");
  const [addonType, setAddonType] = React.useState<any>("");
  const [message, setMessage] = React.useState<any>("");
  const [categoryList, setCategoryList] = React.useState<any>();
  const [addOnList, setAddOnList] = React.useState<any>();

  const [open, setOpen] = React.useState(false);

  const [additionalSpares, setAdditionalSpares] = React.useState<any>([]);

  const additionalSpareRef = React.useRef<any>([]);

  const invoiceRef = React.useRef<any>();

  const [isEnabled, setIsEnabled] = React.useState<any>(false);

  const params = useParams();
  const navigate = useNavigate();
  const customDialog = useCustomDialog();

  const jobsheetRef = React.useRef<any>();

  const getOngoingService = async () => {
    const res = await sendGetRequest(
      `${apiEndPoints.createService}/${params.id}`
    );
    setService(res);
    additionalSpareRef.current = res?.additionalSpares;
    const sparesArray: any = [];
    res.additionalSpares.forEach((adSpare: any) => {
      const spares = {
        addOnGroup: adSpare?.addOnGroup._id,
        addOn: adSpare?.addOn?._id,
        type: adSpare?.type,
      };
      sparesArray.push(spares);
    });
    setAdditionalSpares([...additionalSpares, ...sparesArray]);
  };

  const clearAddOnFields = () => {
    setCategory("");
    setAddons("");
    setAddonType("");
  };

  const getAddOnGroup = async () => {
    const res = await sendGetRequest(apiEndPoints.addOnGroup);
    setCategoryList(res);
  };

  const getAddOnGroupData = async (id: any) => {
    const res = await sendGetRequest(`${apiEndPoints.addOns}/${id}`);
    setAddOnList(res);
  };

  useEffect(() => {
    getAddOnGroup();
  }, []);

  useEffect(() => {
    if (category && addons && addonType) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [category, addons, addonType]);

  const saveOngoingService = async () => {
    let res = await sendGetRequest(`${apiEndPoints.service}/${params.id}`);
    if (res) {
      res["vehicleStatus"] = service["vehicleStatus"] || {};
      res["vehicleInfo"] = service["vehicleInfo"] || {};
      res["mechanicInfo"] = service["mechanicInfo"] || {};
      res["emissionTestNeeded"] = service["emissionTestNeeded"];
      res["towingRequired"] = service["towingRequired"];
      if (service.markAsPaid) {
        res["markAsPaid"] = service["markAsPaid"] || false;
      }
      if (service.paymentMode) {
        res["paymentMode"] = service["paymentMode"];
      }

      if (additionalSpares && additionalSpares.length > 0) {
        res["additionalSpares"] = additionalSpares || [];
      }
      await sendPutRequest(apiEndPoints.createService + "/" + params.id, res);
      customDialog({
        catchOnCancel: false,
        description: ["Service info updated successfully"],
        customClass: "no-description",
        successLabel: "",
        maxWidthProp: "md",
        imgSrc: signOut,
      });
    }
  };

  const removeAddons = (index: number) => {
    additionalSpareRef.current.splice(index, 1);
    const additionalSpareObj = additionalSpares.slice();
    additionalSpareObj.splice(index, 1);
    setAdditionalSpares(additionalSpareObj);
  };

  const handleChange = (type: string, value: any) => {
    const serviceObj = Object.assign({}, service);
    serviceObj["vehicleStatus"] = {
      ...serviceObj["vehicleStatus"],
      [type]: value,
    };
    setService(serviceObj);
  };

  const handleDataChange = (type: string, value: any) => {
    const serviceObj = Object.assign({}, service);
    serviceObj[type] = value;
    setService(serviceObj);
  };
  const handleVehicleDetailsChange = (evt: any) => {
    const serviceObj = Object.assign({}, service);
    serviceObj["vehicleInfo"] = {
      ...serviceObj["vehicleInfo"],
      [evt.target.name]: evt?.target.value,
    };
    setService(serviceObj);
  };

  const handleTechnician = (evt: any) => {
    const serviceObj = Object.assign({}, service);
    serviceObj["mechanicInfo"] = {
      ...serviceObj["mechanicInfo"],
      [evt.target.name]: evt?.target.value,
      contact:
        evt?.target.value === "Abhishek" ? "+918660275287" : "+919019812263",
    };
    setService(serviceObj);
  };

  React.useEffect(() => {
    getOngoingService();
  }, []);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleChangeCateory = (evt: any) => {
    setCategory(evt.target.value);
    getAddOnGroupData(evt.target.value);
  };

  const handleChangeAddons = (evt: any) => {
    setAddons(evt.target.value);
  };

  const handleChangeAddonType = (evt: any) => {
    setAddonType(evt.target.value);
  };

  const uploadJobsheet = async (e: any) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const jobData = new FormData();
    jobData.append("image", e.target.files[0]);
    const res = await sendPostRequest(
      apiEndPoints.uploadJobSheet + "/" + params.id,
      jobData,
      config
    );
    if (res) {
      setMessage("Jobsheet Uploaded");
      setOpen(true);
    }
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const uploadInvoice = async (e: any) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    console.log(e.target.files);
    const jobData = new FormData();
    jobData.append("image", e.target.files[0]);
    const res = await sendPostRequest(
      apiEndPoints.uploadInvoice + "/" + params.id,
      jobData,
      config
    );
    if (res) {
      setMessage("Invoice Uploaded");
      setOpen(true);
    }
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  return (
    <div className="dashboard-layout admin">
      <div className="dashboard-layout__left">
        <LeftSideBar />
      </div>
      <div className="dashboard-layout__right">
        <div className="service-dashboard__header">
          <h3 className="go-back" onClick={handleBackClick}>
            <LeftArrowCircle /> Go Back
          </h3>
        </div>
        {service && (
          <>
            <div className="service-details view-booked">
              <div className="service-details__left">
                <div className="service-details__register">
                  <div className="service-details__register-item">
                    <User />
                    <div className="service-details__register-data serviceId">
                      <span className="service-details__register-label">
                        Service ID :{" "}
                      </span>
                      <span className="service-details__register-value">
                        SPDR{service._id}
                      </span>
                    </div>
                  </div>
                  <div className="service-details__register-item">
                    <Settings />
                    <div className="service-details__register-data">
                      <span className="service-details__register-label">
                        Assigned to :{" "}
                      </span>
                      <span className="service-details__register-value">
                        <Select
                          labelId="demo-simple-select-label"
                          name="name"
                          id="technician"
                          placeholder="Select the technician"
                          onChange={handleTechnician}
                          className="technicianSelect"
                          value={service?.mechanicInfo?.name}
                        >
                          <MenuItem value={"Abhishek"}>Abhishek</MenuItem>
                          <MenuItem value={"Thirtharaj"}>Thirtharaj</MenuItem>
                        </Select>
                      </span>
                    </div>
                  </div>
                  <div className="service-details__register-item">
                    <PhoneIcon />
                    <div className="service-details__register-data">
                      <span className="service-details__register-label">
                        Technician Contact :
                      </span>
                      <span className="service-details__register-value">
                        {service?.mechanicInfo?.contact || "--"}{" "}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="service-details__block">
                  <div className="service-details__item">
                    <div className="service-details__item-img">
                      <img
                        src={service?.vehicleInfo?.model?.image}
                        alt="logo"
                      />
                    </div>
                    <div className="service-details__item-block">
                      <div className="sm-text-light">Vehicle Info</div>
                      <div className="lg-text-dark">
                        {service?.vehicleInfo?.brand?.name || "--"}{" "}
                        {service?.vehicleInfo?.model?.name || "--"}
                      </div>
                    </div>

                    <div className="service-details__item-block d-flexx">
                      <span className="sm-text-light">Vehicle No :</span>
                      <span className="sm-text-dark">
                        {service?.vehicleInfo?.vehicleNo.toUpperCase() || "--"}
                      </span>
                    </div>

                    <div className="service-details__item-block d-flexx">
                      <span className="sm-text-light">Year :</span>
                      {/* <span className="sm-text-dark">{service?.vehicleInfo?.year || "--"}</span> */}
                      <span className="sm-text-dark">
                        <Select
                          labelId="demo-simple-select-label"
                          name="year"
                          id="year"
                          placeholder="Select the Fuel Type"
                          onChange={handleVehicleDetailsChange}
                          className="selectVehicleType"
                          value={service?.vehicleInfo?.year}
                        >
                          {years.map((year) => (
                            <MenuItem value={year.toString()} key={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </span>
                    </div>

                    <div className="service-details__item-block d-flexx">
                      <span className="sm-text-light">Fuel Type : </span>
                      <span className="sm-text-dark">
                        <Select
                          labelId="demo-simple-select-label"
                          name="fuelType"
                          id="fuelType"
                          placeholder="Select the Fuel Type"
                          onChange={handleVehicleDetailsChange}
                          className="selectVehicleType"
                          value={service?.vehicleInfo?.fuelType}
                        >
                          <MenuItem value={"Petrol"}>Petrol</MenuItem>
                          <MenuItem value={"Diesel"}>Diesel</MenuItem>
                          <MenuItem value={"EV"}>EV</MenuItem>
                        </Select>
                      </span>
                    </div>

                    <div className="service-details__item-block d-flexx">
                      <span className="sm-text-light">Km Driven :</span>
                      <span className="sm-text-dark">
                        <input
                          type="number"
                          name="kmDriven"
                          id="custDPincode"
                          className="form__input"
                          value={service?.vehicleInfo?.kmDriven}
                          onChange={handleVehicleDetailsChange}
                        />
                      </span>
                    </div>

                    <div className="service-details__item-block d-flexx">
                      <span className="sm-text-light">Last Service : </span>
                      {/* <span className="sm-text-dark">{service?.vehicleInfo?.fuelTlastServiceype || "--"}</span> */}
                      <span className="sm-text-dark">
                        <input
                          type="text"
                          name="lastService"
                          id="custDPincode"
                          className="form__input"
                          value={service?.vehicleInfo?.lastService}
                          onChange={handleVehicleDetailsChange}
                        />
                      </span>
                    </div>
                    <div className="service-details__item-block d-flexx">
                      <span className="sm-text-light">
                        Front Tyre Pressure :{" "}
                      </span>
                      {/* <span className="sm-text-dark">{service?.vehicleInfo?.fuelTlastServiceype || "--"}</span> */}
                      <span className="sm-text-dark">
                        <input
                          type="text"
                          name="frontTyrePressure"
                          id="frontTyrePressure"
                          className="form__input"
                          value={service?.vehicleInfo?.frontTyrePressure}
                          onChange={handleVehicleDetailsChange}
                        />
                      </span>
                    </div>
                    <div className="service-details__item-block d-flexx">
                      <span className="sm-text-light">
                        Back Tyre Pressure :{" "}
                      </span>
                      {/* <span className="sm-text-dark">{service?.vehicleInfo?.fuelTlastServiceype || "--"}</span> */}
                      <span className="sm-text-dark">
                        <input
                          type="text"
                          name="backTyrePressure"
                          id="backTyrePressure"
                          className="form__input"
                          value={service?.vehicleInfo?.backTyrePressure}
                          onChange={handleVehicleDetailsChange}
                        />
                      </span>
                    </div>
                    <div className="service-details__item-block d-flexx">
                      <span className="sm-text-light">Remarks : </span>
                      <span className="sm-text-dark">
                        <input
                          type="text"
                          name="remark"
                          id="remark"
                          className="form__input"
                          value={service?.vehicleInfo?.remark}
                          onChange={handleVehicleDetailsChange}
                        />
                      </span>
                    </div>
                    <div className="service-details__item-block d-flexx">
                      <span className="sm-text-light">Emission needed:</span>
                      <span className="sm-text-dark">
                        <Checkbox
                          checked={service?.emissionTestNeeded}
                          onChange={(evt) =>
                            handleDataChange(
                              "emissionTestNeeded",
                              evt.target.checked
                            )
                          }
                        />
                      </span>
                    </div>
                    <div className="service-details__item-block d-flexx">
                      <span className="sm-text-light">Towing needed:</span>
                      <span className="sm-text-dark">
                        <Checkbox
                          checked={service?.slot?.towingRequired}
                          onChange={(evt) =>
                            handleDataChange(
                              "towingRequired",
                              evt.target.checked
                            )
                          }
                        />
                      </span>
                    </div>
                    <div className="service-details__item-block d-flexx">
                      <span className="sm-text-light">Mark As Paid:</span>
                      <span className="sm-text-dark">
                        <Checkbox
                          checked={service?.markAsPaid}
                          onChange={(evt) =>
                            handleDataChange("markAsPaid", evt.target.checked)
                          }
                        />
                      </span>
                    </div>
                    <div className="service-details__item-block d-flexx">
                      <span className="sm-text-light">Payment Mode:</span>
                      <span className="sm-text-dark">
                        <Select
                          labelId="demo-simple-select-label"
                          name="paymentMode"
                          id="paymentMode"
                          placeholder="Select the Payment Mode"
                          onChange={(e) =>
                            handleDataChange("paymentMode", e.target.value)
                          }
                          className="selectVehicleType"
                          value={service?.paymentMode}
                        >
                          <MenuItem value={"cash"}>Cash</MenuItem>
                          <MenuItem value={"netBanking"}>Net Banking</MenuItem>
                          <MenuItem value={"upi"}>UPI</MenuItem>
                          <MenuItem value={"card"}>Card</MenuItem>
                        </Select>
                      </span>
                    </div>
                  </div>

                  <div className="service-details__item">
                    <div className="service-details__item-block mb-16">
                      <div className="sm-text-light">Customer Name</div>
                      <div className="lg-text-dark">
                        {service?.usersInfo?.name || "--"}
                      </div>
                    </div>

                    <div className="service-details__item-block mb-43">
                      <div className="sm-text-light">Contact</div>
                      <div className="lg-text-dark">
                        {service?.usersInfo?.contact || "--"}
                      </div>
                    </div>

                    <div className="service-details__item-block address mb-16">
                      <div className="sm-text-light mb-16">
                        Pick Up & Delivery Location
                      </div>
                      <div className="sm-text-dark">Pick Up</div>
                      <div className="address__pickup">
                        <p>
                          {moment(service?.slot?.date).format("DD/MM/YYYY")} |{" "}
                          {service?.slot?.time}
                        </p>
                        <p>
                          {service?.address?.pickup?.address}&nbsp;
                          {service?.address?.pickup?.locality}
                        </p>
                        <p>
                          {service?.address?.pickup?.city}&nbsp;
                          {service?.address?.pickup?.pincode}
                        </p>
                      </div>
                    </div>
                    <div className="service-details__item-block address">
                      <div className="sm-text-dark">Drop</div>
                      <div className="address__pickup">
                        <p>
                          {service?.address?.delivery?.address}&nbsp;
                          {service?.address?.delivery?.locality}
                        </p>
                        <p>
                          {service?.address?.delivery?.city}&nbsp;
                          {service?.address?.delivery?.pincode}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="service-details__item">
                    <div className="service-details__item-block mb-8">
                      <div className="sm-text-light">Vehicle Condition</div>
                    </div>
                    <div className="service-details__progress">
                      <span>Engine</span>
                      <Slider
                        onChange={(event: Event, newValue: number | number[]) =>
                          handleChange("engine", newValue)
                        }
                        size="medium"
                        value={service?.vehicleStatus?.engine || 0}
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={100}
                        aria-labelledby="non-linear-slider-engine"
                      />
                    </div>
                    <div className="service-details__progress">
                      <span>Brake</span>
                      <Slider
                        onChange={(event: Event, newValue: number | number[]) =>
                          handleChange("brake", newValue)
                        }
                        size="medium"
                        value={service?.vehicleStatus?.brake || 0}
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={100}
                        aria-labelledby="non-linear-slider-brake"
                      />
                    </div>
                    <div className="service-details__progress">
                      <span>Oil</span>
                      <Slider
                        onChange={(event: Event, newValue: number | number[]) =>
                          handleChange("oil", newValue)
                        }
                        size="medium"
                        value={service?.vehicleStatus?.oil || 0}
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={100}
                        aria-labelledby="non-linear-slider-oil"
                      />
                    </div>
                    <div className="service-details__progress">
                      <span>Tyres</span>
                      <Slider
                        onChange={(event: Event, newValue: number | number[]) =>
                          handleChange("tyres", newValue)
                        }
                        size="medium"
                        value={service?.vehicleStatus?.tyres || 0}
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={100}
                        aria-labelledby="non-linear-slider-tyre"
                      />
                    </div>
                    <div className="service-details__progress">
                      <span>Coolent</span>
                      <Slider
                        onChange={(event: Event, newValue: number | number[]) =>
                          handleChange("coolent", newValue)
                        }
                        size="medium"
                        value={service?.vehicleStatus?.coolent || 0}
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={100}
                        aria-labelledby="non-linear-slider-coolent"
                      />
                    </div>
                    <div className="service-details__progress">
                      <span>Other Parts</span>
                      <Slider
                        onChange={(event: Event, newValue: number | number[]) =>
                          handleChange("other", newValue)
                        }
                        size="medium"
                        value={service?.vehicleStatus?.other || 0}
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={100}
                        aria-labelledby="non-linear-slider-other"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-details__right">
                <div className="service-details__item-block mb-8">
                  <div className="lg-text-dark">Status</div>
                </div>
                {service.serviceAccepted ? (
                  <VerticalStepper
                    service={service}
                    activeStatus={service?.serviceStatus}
                    serviceId={params?.id}
                  />
                ) : (
                  <p className="form__input-error">Service not accepted!!</p>
                )}
              </div>
            </div>
            <div className="service-addon">
              <div className="service-addon__block">
                <div className="service-addon__left">
                  <div className="service-details__item-block mb-8">
                    <div className="lg-text-dark">Additional Spare Add Ons</div>
                  </div>
                  <div className="career__fiter">
                    <form>
                      <div className="form__row form__row--three-col">
                        <div className="form__group">
                          <label htmlFor="category" className="form__label">
                            Category
                          </label>
                          <FormControl>
                            <Select
                              labelId="demo-simple-select-label"
                              name="category"
                              id="category"
                              value={category}
                              placeholder="Category"
                              onChange={handleChangeCateory}
                            >
                              {categoryList?.map((item: any) => {
                                return (
                                  <MenuItem value={item?._id}>
                                    {item?.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="form__group">
                          <label htmlFor="addons" className="form__label">
                            Addons
                          </label>
                          <FormControl>
                            <Select
                              labelId="demo-simple-select-label"
                              name="addons"
                              id="addons"
                              value={addons}
                              placeholder=""
                              onChange={handleChangeAddons}
                              // onBlur={handleError}
                            >
                              {addOnList?.map((item: any) => {
                                return (
                                  <MenuItem value={item}>{item?.name}</MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>

                          {/* <div className="form__input-error">
                    <p>Please select the location.</p>
                  </div> */}
                        </div>

                        <div className="form__group">
                          <label htmlFor="addontype" className="form__label">
                            Addons Type
                          </label>
                          <FormControl>
                            <Select
                              labelId="demo-simple-select-label"
                              name="addontype"
                              id="addontype"
                              value={addonType}
                              onChange={handleChangeAddonType}
                            >
                              <MenuItem value={"mandatory"}>Mandatory</MenuItem>
                              <MenuItem value={"recommended"}>
                                Recommended
                              </MenuItem>
                              <MenuItem value={"optional"}>Optional</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className="view-booking-action">
                        <button
                          className={
                            isEnabled
                              ? "button button--enabled"
                              : "button button--disabled"
                          }
                          type="button"
                          onClick={() => {
                            const spares = {
                              addOnGroup: category,
                              addOn: addons?._id,
                              type: addonType,
                            };

                            const addOnObj = Object.assign({}, addons);
                            addOnObj["type"] = addonType;
                            additionalSpareRef.current = [
                              ...additionalSpareRef.current,
                              addOnObj,
                            ];

                            setAdditionalSpares([...additionalSpares, spares]);
                            clearAddOnFields();
                          }}
                          disabled={!isEnabled}
                          // style={{ opacity: isEnabled ? "1" : "0.5" }}
                        >
                          Add
                        </button>
                      </div>
                    </form>
                    <div className="mb-16">
                      <strong>Additional Spare Add Ons </strong>
                      <span>({additionalSpares.length} Parts Added)</span>
                    </div>
                  </div>
                  <div className="service-addon__items view-booking">
                    {additionalSpareRef.current?.map(
                      (addOn: any, index: number) => (
                        <div className="service-addon__inner">
                          <div
                            style={{
                              width: "15px",
                              borderRadius: "50%",
                              border: "1px solid red",
                              position: "absolute",
                              top: "-8px",
                              left: "-8px",
                              height: "15px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            <CloseIcon
                              style={{ width: "12px", color: "red" }}
                              onClick={() => removeAddons(index)}
                            />
                          </div>
                          <div className="service-addon__item">
                            <div className="service-addon__item-img">
                              <img
                                src={addOn?.addOn?.image || addOn.image}
                                alt=""
                              />
                            </div>
                            <div className="service-addon__item-info">
                              <span className="view-booking__badge">
                                {addOn.type}
                              </span>
                              <div className="service-details__item-block ">
                                <div className="xs-text-light">
                                  {" "}
                                  {addOn?.addOn?.name || addOn.name}
                                </div>
                              </div>
                              <div className="service-details__item-block ">
                                <div className="sm-text-light">
                                  {addOn?.addOn?.brand || addOn.brand}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="service-addon__right">
                  <div className="service-addon__total">
                    <div className="service-details__item">
                      <div className="service-details__item-block mb-16">
                        <div className="sm-text-light">Subtotal</div>
                        <div className="lg-text-dark">
                          ₹ {service?.totalCost - service?.discountAmount}
                        </div>
                      </div>
                    </div>
                    <div className="service-details__item">
                      <div className="service-details__item-block mb-16">
                        <div className="sm-text-light">Discount</div>
                        <div className="lg-text-dark discount">
                          ₹ -{service?.discountAmount}
                        </div>
                      </div>
                    </div>
                    {/* <div className="service-details__item">
                  <div className="service-details__item-block mb-16">
                    <div className="sm-text-light">18% GST</div>
                    <div className="lg-text-dark">₹ 161</div>
                  </div>
                </div> */}
                    <div className="service-details__item">
                      <div className="service-details__item-block mb-16">
                        <div className="sm-text-dark">Total Cost</div>
                        <div className="sm-text-dark">
                          ₹ {service?.totalCost}
                        </div>
                      </div>
                    </div>
                    <div className="service-details__item">
                      <div className="service-details__item-block">
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={jobsheetRef}
                          accept="application/pdf,image/*"
                          onChange={uploadJobsheet}
                          onClick={(e: any) => {
                            e.target.value = "";
                          }}
                        />
                        <button
                          className="button button--outline service-dashboard__jobsheet"
                          disabled={!!!service.serviceAccepted}
                          onClick={() => {
                            // jobsheetRef.current.click();
                            navigate(`/admin/createJobsheet/${params.id}`);
                          }}
                        >
                          <JobSheet />
                          Upload JobSheet
                        </button>
                      </div>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={invoiceRef}
                        accept="application/pdf,image/*"
                        onChange={uploadInvoice}
                      />
                      <button
                        className="button button--outline service-dashboard__jobsheet"
                        disabled={!!!service.serviceAccepted}
                        style={{ width: "100%", marginTop: "10px" }}
                        // onClick={() => {
                        //   // invoiceRef.current.click();

                        // }}
                        onClick={() => {
                          // jobsheetRef.current.click();
                          navigate(`/admin/createInvoice/${params.id}`);
                        }}
                      >
                        <JobSheet />
                        Upload Invoice
                      </button>
                      <button
                        className="button button--outline accept"
                        onClick={() => {
                          saveOngoingService();
                        }}
                        style={{ width: "100%", marginTop: "10px" }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <Snackbar open={open} autoHideDuration={6000} message={message} />
      </div>
    </div>
  );
};

export default ViewBooking;
