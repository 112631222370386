export interface IService {
  usersInfo: IUsersInfo;
  vehicleInfo: IVehicleInfo;
  address: IAddress;
  slot: ISlot;
  packages: string;
  emissionTestNeeded: boolean;
  addOns: string[];
  userId: string;
}

export interface IAddress {
  pickup: IDelivery;
  delivery: IDelivery;
}

export interface IDelivery {
  address: string;
  locality: string;
  pincode: string;
  city: string;
  state: string;
  landmark: string;
}

export interface ISlot {
  date: string;
  time: string;
  towingRequired: boolean;
}

export interface IUsersInfo {
  name: string;
  email: string;
  contact: string;
}

export interface IVehicleInfo {
  vehicleNo: string;
  brand: string;
  model: string;
}

export class Service implements IService {
  usersInfo: IUsersInfo;
  vehicleInfo: IVehicleInfo;
  address: IAddress;
  slot: ISlot;
  packages: string;
  emissionTestNeeded: boolean;
  addOns: string[];
  discountCode: string;
  discountAmount: number;
  totalCost: number;
  paymentStatus: string;
  paymentId: string;
  userId: string;
  constructor(data: any = {}) {
    this.usersInfo = data.usersInfo || new UsersInfo();
    this.vehicleInfo = data.vehicleInfo || new VehicleInfo();
    this.address = data.address || new Address();
    this.slot = data.slot || new Slot();
    this.packages = data.packages || "";
    this.emissionTestNeeded = data.emissionTestNeeded || false;
    this.addOns = data.addOns || [];
    this.discountCode = data.discountCode || "";
    this.discountAmount = data.discountAmount || 0;
    this.totalCost = data.totalCost || 0;
    this.paymentStatus = data.paymentStatus || "";
    this.paymentId = data.paymentId || "";
    this.userId = data.userId || "";
  }
}

export interface IServiceError {
  name: boolean;
  email: boolean;
  contact: boolean;
  vehicleNo: boolean;
  brand: boolean;
  model: boolean;
  pAddress: boolean;
  pLocality: boolean;
  pLandmark: boolean;
  dLandmark: boolean;
  pPincode: boolean;
  pCity: boolean;
  pState: boolean;
  dAddress: boolean;
  dLocality: boolean;
  dPincode: boolean;
  dCity: boolean;
  dState: boolean;
  date: boolean;
  time: boolean;
}
export class ServiceError implements IServiceError {
  name: boolean;
  email: boolean;
  contact: boolean;
  vehicleNo: boolean;
  brand: boolean;
  model: boolean;
  pAddress: boolean;
  pLocality: boolean;
  pPincode: boolean;
  pCity: boolean;
  pState: boolean;
  pLandmark: boolean;
  dLandmark: boolean;
  dAddress: boolean;
  dLocality: boolean;
  dPincode: boolean;
  dCity: boolean;
  dState: boolean;
  date: boolean;
  time: boolean;
  constructor(data: any = {}) {
    this.name = data.name || false;
    this.email = data.email || false;
    this.contact = data.contact || false;
    this.vehicleNo = data.vehicleNo || false;
    this.brand = data.brand || false;
    this.model = data.model || false;
    this.pAddress = data.pAddress || false;
    this.pLocality = data.pLocality || false;
    this.pPincode = data.pPincode || false;
    this.pLandmark = data.pLandmark || false;
    this.dLandmark = data.pLandmark || false;
    this.pCity = data.pCity || false;
    this.pState = data.pState || false;
    this.dAddress = data.dAddress || false;
    this.dLocality = data.dLocality || false;
    this.dPincode = data.dPincode || false;
    this.dCity = data.dCity || false;
    this.dState = data.dState || false;
    this.date = data.date || false;
    this.time = data.time || false;
  }
}
class UsersInfo implements IUsersInfo {
  name: string;
  email: string;
  contact: string;
  constructor(data: any = {}) {
    this.name = data.name || "";
    this.email = data.email || "";
    this.contact = data.contact || "";
  }
}

class VehicleInfo implements IVehicleInfo {
  vehicleNo: string;
  brand: string;
  model: string;
  constructor(data: any = {}) {
    this.vehicleNo = data.vehicleNo || "";
    this.brand = data.brand || "";
    this.model = data.model || "";
  }
}

class Address implements IAddress {
  pickup: IDelivery;
  delivery: IDelivery;
  constructor(data: any = {}) {
    this.pickup = data.pickup || new Delivery();
    this.delivery = data.pickup || new Delivery();
  }
}

class Slot implements ISlot {
  date: string;
  time: string;
  towingRequired: boolean;
  constructor(data: any = {}) {
    this.date = data.date || "";
    this.time = data.time || "";
    this.towingRequired = data.towingRequired || false;
  }
}

class Delivery implements IDelivery {
  address: string;
  locality: string;
  pincode: string;
  city: string;
  state: string;
  landmark: string;
  constructor(data: any = {}) {
    this.address = data.address || "";
    this.locality = data.locality || "";
    this.pincode = data.pincode || "";
    this.city = data.city || "";
    this.state = data.state || "";
    this.landmark = data.landmark || "";
  }
}
