import React from "react";
import { FaqIcon } from "../../../assets/Icons";
import "./Faq.scss";
import { useNavigate } from "react-router-dom";

const Faq = () => {
  const navigate = useNavigate();
  return (
    <div className="faq">
      <div className="container">
        <div className="faq__header">
          <div className="faq__title">Frequently Asked Questions</div>
          <div className="faq__text">Unlock answers to your service queries: Discover our Frequently Asked Questions!</div>
        </div>

        <div className="faq__block">
          <div className="faq__item">
            <span>
              <FaqIcon />
            </span>
            <div className="faq__item-title">How can I book a service for my vehicle?</div>
            <div className="faq__item-text">You can book a service for your vehicle through our website or by clicking the "Home" button.</div>
          </div>
          <div className="faq__item">
            <span>
              <FaqIcon />
            </span>
            <div className="faq__item-title">What types of services do you offer?</div>
            <div className="faq__item-text">
              We offer periodic services, repair services, accidental damage fixes, tyre replacements, battery replacements, and inspections.
            </div>
          </div>
          <div className="faq__item">
            <span>
              <FaqIcon />
            </span>
            <div className="faq__item-title">Can I select a service plan that suits my needs?</div>
            <div className="faq__item-text">
              Yes, for periodic service, we offer multiple packages suitable for your motorcycle or scooter usage. You can select the plan that fits your
              requirements.
            </div>
          </div>
        </div>
        <div className="faq__block">
          <div className="faq__item">
            <span>
              <FaqIcon />
            </span>
            <div className="faq__item-title">How do I select the date and time for my service?</div>
            <div className="faq__item-text">
              You can fill in your preferred date and time during the booking process. However, service bookings are subject to slot availability, and if your
              preferred slot is not available, our service team will contact you to reschedule.
            </div>
          </div>
          <div className="faq__item">
            <span>
              <FaqIcon />
            </span>
            <div className="faq__item-title">What should I do if I have specific requirements for spares or repairs?</div>
            <div className="faq__item-text">
              You can mention your specific requirements during the booking process. This helps us ensure that we bring the necessary spares for your
              appointment.
            </div>
          </div>
          <div className="faq__item faq-align">
            <button className="button" onClick={() => navigate("/faq")}>
              See more
            </button>
            {/* <span>
              <FaqIcon />
            </span>
            <div className="faq__item-title">
              How long does it take to ship my order?
            </div>
            <div className="faq__item-text">
              Orders are usually shipped within 1-2 business days after placing
              the order.
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
