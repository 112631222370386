import React from "react";
import LeftSideBar from "./LeftSideBar/LeftSideBar";
import OngoingService from "./OngoingService/OngoingService";
import "./ServicesDashboard.scss"
import ServiceHistory from "./ServiceHistory/ServiceHistory";
const ServicesDashboard = () => {
  return (
    <div>
      <div className="dashboard-layout">
        <div className="dashboard-layout__left">
          <LeftSideBar />
        </div>
        <div className="dashboard-layout__right">
          <OngoingService />
          {/* <ServiceHistory /> */}
        </div>
      </div>
    </div>
  );
};

export default ServicesDashboard;
