import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { apiEndPoints } from "../../../utils/helpers/endPoints";
import {
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
} from "../../../utils/helpers/requestController";

const VerticalStepper = ({ service = {}, activeStatus, serviceId }: any) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = async (value: any) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    const res = await sendGetRequest(`${apiEndPoints.service}/${serviceId}`);
    res.serviceStatus = value;
    const response = await sendPutRequest(
      `${apiEndPoints.service}/${serviceId}/changeStatus`,
      res
    );

    // get service based on serviceId
    // put ${apiEndPoints.service}/${serviceId}/changeStatus
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  React.useEffect(() => {
    setActiveStep(activeStatus);
  }, [activeStatus]);
  const steps = [
    {
      label: "Booked",
      value: 1,
      // description: `Vehicle Booked Successfully. 1 Jan, 8:50 am`,
    },
    {
      label: "PickUp",
      value: 2,
      // description: `Done 1 Jan, 10:30 am`,
    },
    {
      label: "Diagnosing",
      value: 3,
      // description: `Vehicle under Inspection 1 Jan, 8:50 am`,
    },
    {
      label: "Repair",
      value: 4,
    },
    {
      label: "Delivered",
      value: 5,
    },
  ];
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps?.map((step: any, index: Number) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={() => handleNext(step?.value)}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default VerticalStepper;
