import React from "react";
import "./LeftSideBar.scss";
import { NavLink } from "react-router-dom";
import { Help, Orders, Garage, Accessories, Logout } from "../../../assets/Icons";
import { redirectToLogin } from "../../../utils/helpers/requestController";
const LeftSideBar = () => {
  return (
    <div className="leftsidebar">
      <div className="leftsidebar__nav">
        <ul className="leftsidebar__nav-list">
          <li className="leftsidebar__nav-item">
            <NavLink className="leftsidebar__nav-link" to="/admin/dashboard">
              <Orders />
              <span>Orders</span>
            </NavLink>
          </li>
          <li className="leftsidebar__nav-item">
            <NavLink className="leftsidebar__nav-link" to="/admin/garage-list">
              <Garage />
              <span>
                <span className="mob-hide">Garage </span>List
              </span>
            </NavLink>
          </li>
          <li className="leftsidebar__nav-item">
            <NavLink className="leftsidebar__nav-link" to="/admin/accessories">
              <Accessories />
              <span>
                <span>Accessories</span>
              </span>
            </NavLink>
          </li>

          {/* <li className="leftsidebar__nav-item">
            <NavLink className="leftsidebar__nav-link" to="/admin/help">
              <Help />
              <span>
                Help <span className="mob-hide">& Support</span>
              </span>
            </NavLink>
          </li> */}
        </ul>
        <div
          className="leftsidebar__nav-item mouse-pointer"
          onClick={() => {
            redirectToLogin();
          }}
        >
          <div className="leftsidebar__nav-link">
          <Logout />
          <span>Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSideBar;
