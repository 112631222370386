import React from "react";
import ContactUs from "../../ContactUs/ContactUs";
import LeftSideBar from "../LeftSideBar/LeftSideBar";
import "./HelpSupport.scss"

const HelpSupport = () => {
  return (
    <div className="dashboard-layout">
      <div className="dashboard-layout__left">
        <LeftSideBar />
      </div>
      <div className="dashboard-layout__right help-support-bg">
      <div className="contact__top">
          <div className="container">
            <div className="contact__block">
              <div className="contact__left">
                <h1>Let’s get connected</h1>
                <p>Can’t find the solution you are looking for? Here’s how to get help from our spannerdoor community</p>
              </div>
             
     
            
          </div>
         </div>
        </div>
        <ContactUs type={"admin"} />
      </div>
    </div>
  );
};

export default HelpSupport;
