export class PartDetails {
  hsnCode: string;
  sdCode: string;
  partName: string;
  price: string;
  quantity: string;
  discountPercentage: string;
  gstPercentage: string;

  constructor(data: any = {}) {
    this.hsnCode = data.hsnCode || "";
    this.sdCode = data.sdCode || "";
    this.partName = data.partName || "";
    this.price = data.price || "";
    this.quantity = data.quantity || "";
    this.discountPercentage = data.discountPercentage || "";
    this.gstPercentage = data.gstPercentage || "";
  }
}

export class LabourDetails {
  hsnCode: string;
  sdCode: string;
  partName: string;
  price: string;
  quantity: string;
  discountPercentage: string;
  gstPercentage: string;

  constructor(data: any = {}) {
    this.hsnCode = data.hsnCode || "";
    this.sdCode = data.sdCode || "";
    this.partName = data.partName || "";
    this.price = data.price || "";
    this.quantity = data.quantity || "";
    this.discountPercentage = data.discountPercentage || "";
    this.gstPercentage = data.gstPercentage || "";
  }
}

export class PartDetailsErr {
  hsnCode: boolean;
  sdCode: boolean;
  partName: boolean;
  price: boolean;
  quantity: boolean;
  discountPercentage: boolean;
  gstPercentage: boolean;

  constructor(data: any = {}) {
    this.hsnCode = data.hsnCode || false;
    this.sdCode = data.sdCode || false;
    this.partName = data.partName || false;
    this.price = data.price || false;
    this.quantity = data.quantity || false;
    this.discountPercentage = data.discountPercentage || false;
    this.gstPercentage = data.gstPercentage || false;
  }
}

export class LabourDetailsErr {
  hsnCode: boolean;
  sdCode: boolean;
  partName: boolean;
  price: boolean;
  quantity: boolean;
  discountPercentage: boolean;
  gstPercentage: boolean;

  constructor(data: any = {}) {
    this.hsnCode = data.hsnCode || false;
    this.sdCode = data.sdCode || false;
    this.partName = data.partName || false;
    this.price = data.price || false;
    this.quantity = data.quantity || false;
    this.discountPercentage = data.discountPercentage || false;
    this.gstPercentage = data.gstPercentage || false;
  }
}
