import React from "react";
import "./Careers.scss";

import { LocationBlue, Exp, WorkType } from "../../assets/Icons";
import { Link } from "react-router-dom";

const CareerDetails = () => {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div className="career">
        <div className="container">
          <span className="career__badge">We are hiring</span>
          <h2 className="career__title">Frontend Developer</h2>
          <div className="career__details">
            <div className="career__place">
              <LocationBlue /> Bangalore, India
            </div>
            <div className="career__type">
              <WorkType /> Full time
            </div>
            <div className="career__exp">
              <Exp /> Min 5+ Years Experience
            </div>
          </div>
        </div>
      </div>
      <div className="career career--bg">
        <div className="container">
          <div className="career__info">
            <div className="career__info-title">Responsibilities</div>
            <ul className="career__info-list-block">
              <li className="career__info-list">
                Works as part of a team to continually refresh and enhance our
                users’ web experiences using an Agile Development Methodology.
              </li>
              <li className="career__info-list">
                Build and ship features with an understanding of Javascript, CSS
                and HTML best practices.
              </li>
              <li className="career__info-list">
                Coordinate with back-end engineers to provide a cohesive
                experience.
              </li>
            </ul>
            <div className="career__info-title">Requirements & Skills</div>
            <ul className="career__info-list">
              <li className="career__info-list">
                5+ years of extensive exposure in developing web applications
                using the latest front-end technologies
              </li>
              <li className="career__info-list">
                Expertise in the following area:
              </li>
              <li className="career__info-list">
                HTML5, CSS3, JavaScript and JS frameworks, such as jQuery,
              </li>
              <li className="career__info-list">
                Responsive &amp; Adaptive web design
              </li>
              <li className="career__info-list">
                CSS frameworks like SASS/LESS
              </li>
              <li className="career__info-list">
                Able to work both independently and in a team environment.
              </li>
              <li className="career__info-list">
                Adequate knowledge of Version Control Systems like Git
              </li>
            </ul>
            <div className="career__info-title">Mandatory Skills to have</div>
            <ul className="career__info-list">
              <li className="career__info-list">HTML5, CSS3 Must-Have.</li>
              <li className="career__info-list">
                Prior hands-on experience with HTML5, CSS3 Projects.
              </li>
              <li className="career__info-list">
                Working experience in Drupal Custom base/sub-theme building,
                theme overriding, theme preprocess
              </li>
              <li className="career__info-list">
                Must have knowledge of Twig and twig filters
              </li>
              <li className="career__info-list">
                Experience in Paragraph, layout builder, node, views.
              </li>
            </ul>
          </div>
          <Link to="mailto:info@spannerdoor.com" className="button">
          Send Your Resume Here
          </Link>
        </div>
      </div>
    </>
  );
};

export default CareerDetails;
