import React from "react";

import "./TermsCondition.scss";
const TermsCondition = () => {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="terms">
      <div className="terms-container container">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2">
            <section className="terms-title">
              <h1>Terms &amp; Conditions</h1>
             
            </section>

            <div className="terms-body">
              <h4>
                <div className="mb-10">
                  <strong>
                    These Terms of Use ("Terms/Agreement") govern the access or
                    use by you, an individual or an entity, with the Services
                    (the “Services”) made available by Spannerdoor Private
                    Limited, a private limited company established in India,
                    having its registered office at 88/89, Gurukrupa Nilaya 1st
                    floor,, Kempegowda Layout, Kattigenahalli, Bangalore, KA,
                    India – 560064 (“Spannerdoor”).
                  </strong>
                </div>
                <div className="mb-10">
                  <strong>
                    By downloading the Spannerdoor Application (the
                    “Application”), accessing and/or using the Spannerdoor
                    website (the “Website”) and other platforms owned by
                    www.Spannerdoor.com you agree that You have read,
                    understood, and agreed to be bound by all of the terms and
                    conditions as set forth in the following Agreement.
                    Spannerdoor may change the Terms at any time and without
                    notice, effective upon the posting of the revised Agreement.
                    Your continued use of Spannerdoor platforms will be
                    considered your acceptance of the revised Agreement. If you
                    do not wish to be bound by the Terms laid down in this
                    document (Agreement), please do not make use of the
                    platform/website.
                  </strong>
                </div>
                <div className="mb-10">
                  <strong>
                    Subscription Plan or the Annual Maintenance Contract (AMC)
                    Plan shall refer to an annual membership or plan or scheme
                    availed or subscribed by you for obtaining services provided
                    by Spannerdoor at beneficial rates.
                  </strong>
                </div>
              </h4>
            
              <h2>
                PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR WHILE
                USING THE SERVICES
              </h2>
              <h3>1. PRIVACY POLICY</h3>
              <p>
                Spannerdoor has established a Privacy Policy that explains to
                users how their personal information is collected and used.
              </p>
              <p>
                The Privacy Policy is located at:
                https://spannerdoor.com/privacy The Privacy Policy contains
                information about how users may seek access to and correction of
                their personal information held by Spannerdoor.
              </p>
              <p>
                The Privacy Policy is hereby incorporated into the Terms of Use
                set forth herein. Your use of this Website and/ or the
                Applications is governed by the Privacy Policy.
              </p>
              <p>
                Spannerdoor may also provide to a law enforcement agency,
                statutory body, governmental agency and/or investigative agency
                any necessary information (including your contact information)
                if required by law or in furtherance of any investigation
                involving you and a third party and such information or data is
                necessary towards the enquiry/investigation that is being
                carried out by the said body and/or agency as the case may be.
              </p>
             

              <h3>2. WARRANTY AND DISCLAIMER</h3>
              <p>
                The services provided by Spannerdoor are subject to all the
                Terms set forth on the Website. Spannerdoor does not warrant
                that:
              </p>
              <ul>
                <li>
                  The Website will be constantly available, or available at all.
                </li>
                <li>
                  The information on the website is complete, true, and
                  accurate.
                </li>
                <li>
                  Your opt-out choices will be successfully executed in all
                  cases.
                </li>
                <li>The website is free of defects or errors.</li>
              </ul>
              <hr />
              <h3>3. LIMIT OF LIABILITY AND INDEMNIFICATION</h3>
              <p>
                The downloading and/or use of the application or accessing the
                Website is entirely at your own risk and in no event shall
                Spannerdoor be liable (whether under the law of contracts,
                torts, or otherwise) for any direct, indirect, incidental,
                consequential, special, exemplary, punitive or any other
                monetary or other damages, fees, fines, penalties or liabilities
                (collectively "damages") whatsoever arising out of or relating
                to this Website or Application. Your sole and exclusive remedy
                for dissatisfaction with the Website or Application is to stop
                using the Website or Application and cease to avail any
                services. You agree to defend, indemnify, and hold Spannerdoor,
                and its officers, directors, employees, representatives, and
                agents harmless from and against any claims, actions, demands,
                liabilities, judgments, and settlements, including without
                limitations, reasonable legal fees resulting from or alleged to
                result from your use of Spannerdoor’ Website, Application, or
                services.
              </p>

             
              <h3>4. OWNERSHIP OF INTELLECTUAL PROPERTY RIGHTS</h3>
              <p>
                All intellectual property on this website including all website
                material is protected by copyright, trademark, or patent laws,
                and is owned exclusively by Spannerdoor. Intellectual property
                includes but is not limited to, computer or software code,
                scripts, design elements, graphics, interactive features,
                artwork, text communication, and any other content that may be
                found on or on the website. All trademarks, service marks and
                trade names are owned, registered and/or licensed by
                Spannerdoor. Your usage of the Website or Application does not
                create any right, ownership, or any other relation on you, to
                use the Intellectual property of Spannerdoor.
              </p>
              
              <h3>5. ENFORCEMENT OF INTELLECTUAL PROPERTY RIGHTS </h3>
              <p>
                If you decide that you no longer want your order for any reason
                you may cancel it as long as the campaign period is still
                active. However, once a campaign ends, we are unable to cancel
                an order as the information has already been sent to the printer
                for manufacturing and fulfillment.If Spannerdoor discovers that
                You have used its intellectual property or other protected
                materials in contravention of these Terms, Spannerdoor may bring
                legal proceedings against you seeking monetary damages and an
                injunction against You. You could also be ordered to pay legal
                fees and costs.
              </p>
              <p>
                If you become aware of any use of Spannerdoor' intellectual
                property that contravenes or may contravene the Terms above,
                immediately report this by email to info@spannerdoor.com or by
                first-class mail to Spannerdoor Private Limited, 1st floor,
                88/89, Gurukrupa Nilaya, Kempegowda Layout, Kattigenahalli,
                Bangalore, KA India – 560064, in care of its Legal Department.
              </p>
             
              <h3>6. PRICING INFORMATION</h3>
              <p>
                6.1 Spannerdoor strives to provide You with the best prices
                possible on products and services You buy or avail of from the
                Website. The pricing details for the purchase of products and
                services from the Website are detailed under the Terms of Offer
                for Sale.
              </p>
              <p>
                6.2 You agree to provide correct and accurate credit/ debit card
                details to the approved payment gateway for availing Services on
                the Website. You shall not use a credit/ debit card, which is
                not lawfully owned by You, i.e., in any transaction, you must
                use Your own credit/ debit card. The information provided by You
                will not be utilized or shared with any third party unless
                required in relation to fraud verifications or by law,
                regulation, or court order. You will be solely responsible for
                the security and confidentiality of Your credit/ debit card
                details. Spannerdoor expressly disclaims all liabilities that
                may arise as a consequence of any unauthorized use of Your
                credit/ debit card.
              </p>
              <p>
                5.3 Spannerdoor shall be under no liability whatsoever in
                respect of any loss or damage arising directly or indirectly out
                of the decline of authorization for any transaction, on account
                of you/cardholder having exceeded the present limit mutually
                agreed by Spannerdoor with our acquiring bank from time to time.
              </p>
              
              <h3>7. SERVICE DELIVERY TERMS</h3>
              <p>
                You agree to abide by the following terms of service delivery by
                Spannerdoor.
              </p>

              
              <h4>7.1 Service Booking & Case Assigning</h4>
              <ul>
                <li>
                  You can book a service with Spannerdoor through the website,
                  application.
                </li>
                <li>
                  At the time of booking a service, you shall ensure to provide
                  maximum information about the vehicle. This shall help us
                  diagnose the requirements of repairs and spares more
                  accurately.
                </li>
                <li>
                  Once you have booked a service appointment with Spannerdoor
                  through its website, application, you shall receive a
                  confirmation email and/or SMS notifying the acceptance of the
                  service request.
                </li>
                <li>
                  If the booking slots chosen by you are already occupied, our
                  appointment desk shall contact you and assist you with
                  rescheduling the appointment.
                </li>
                <li>
                  Post the acceptance of the service request, our technical
                  advisor will review the details submitted by you and contact
                  you if further information is needed.
                </li>
                <li>
                  The technical advisor will make 3 attempts to contact you via
                  call on the contact number provided by you at the time of
                  booking.
                </li>
                <li>
                  Once the technical advisor has contacted and spoken to you,
                  he/she shall create a job card and assign the case to a
                  mechanic.
                </li>
                <li>
                  If the technical advisor does not get to speak with you,
                  he/she at his / her own discretion may decide if the job card
                  is to be created and the case is to be assigned to the
                  mechanic or not.
                </li>
              </ul>

              <h4>7.2 Advance Fee & Service Fee</h4>
              <ul>
                <li>
                  Spannerdoor may charge you an advance fee for confirming your
                  service request placed with Spannerdoor.
                </li>
                <li>
                  The advance fee paid will be adjusted in the final invoice
                  raised at end of service delivery.
                </li>
                <li>
                  In case of cancellation of service booking, refund of Advance
                  Fee is subject to terms specified in Clause 8: Cancellations &
                  Refunds
                </li>

                <li>
                  In case of specific spares to be procured for delivery of
                  service, Spannerdoor may demand a Service Fee in order to
                  confirm the procurement of spares.
                </li>

                <li>
                  The Service Fee paid shall be adjusted in the final invoice
                  payable in case the specific spares have been utilized in the
                  service booking.
                </li>

                <li>
                  In case you refuse to use the specific spares ordered by
                  Spannerdoor, the Service Fee shall be non-refundable and
                  non-adjustable in the final invoice.
                </li>
              </ul>

              <h4>7.3 Technician arrival at the site</h4>
              <ul>
                <li>
                  On the day of the service appointment, the technician will
                  contact you approx. 15 to 45 minutes prior to your scheduled
                  appointment.
                </li>
                <li>
                  On arrival of the technician at your given location, the
                  technician shall contact you and wait for the handover of the
                  keys.
                </li>
                <li>
                  We request all our customers to hand over the keys to the
                  technician as soon as he reaches. This ensures he completes
                  his service delivery on time and not making subsequent
                  appointments for customers to wait.
                </li>
                <li>
                  Waiting Period: Once the technician has reached the service
                  location, he shall wait only for a maximum of 20 minutes for
                  you to hand over the keys. If the service appointment gets
                  delayed by more than 20 minutes, subject to his appointment
                  slots, he shall have the right to cancel the booking due to
                  customer unavailability.
                </li>
                <li>
                  Delay in Mechanic Arrival: If the mechanic arrives at the
                  customer location later than 25 minutes from the scheduled
                  appointment time, the customer shall have the choice to
                  reschedule or cancel the appointment.
                </li>
              </ul>
              <h4>7.4 Service Delivery and Payment</h4>
              <ul>
                <li>
                  After you have handed over the vehicle keys to the technician,
                  the technician will take a test drive of the vehicle and
                  conduct a thorough inspection.
                </li>
                <li>
                  On completing the inspection, the technical advisor will
                  update the job card in the system and contact you with the
                  final scope of work for final approval.
                </li>
                <li>
                  You shall also receive a link to review and approve the job
                  card through SMS and/or Email.
                </li>
                <li>
                  Once the job card has been approved, the technician will start
                  to work on the vehicle as per the approved job card.
                </li>
                <li>
                  Any spares, service or repair once approved cannot be revoked.
                </li>
                <li>
                  After the service has been completed, you are requested to
                  take a test drive and adjudge the quality of vehicle
                  performance.
                </li>
                <li>
                  In case of any pertaining issues, you may inform the mechanic
                  and get the issue rectified.
                </li>
                <li>
                  Spannerdoor or the technician shall not be liable for any
                  issues pertaining due to service, repair or spare recommended
                  by the technical advisor but not approved by you.
                </li>
                <li>
                  After the service delivery, vehicle keys shall be handed over
                  to the customer only after the receipt of payment to
                  Spannerdoor.
                </li>
              </ul>

              <h3>8. CANCELLATION AND REFUND</h3>
              <h4>8.1 Cancellations</h4>
              <ul>
                <li>
                  You may cancel a booking at any time before the technician has
                  started his journey towards your location during the assigned
                  time slot.
                </li>
                <li>
                  You cannot cancel the order if the technician has arrived at
                  your location within the assigned time slot.
                </li>
                <li>
                  The Company may cancel your booking for the following reasons:
                </li>
                <ul>
                  <li>
                    When our technical team is not able to speak with you to
                    create a job card, wherein there is reasonable justification
                    that our team needs to speak with you for defining the right
                    scope of work to arrange for the right spares; or
                  </li>
                  <li>
                    When our technical team is not able to speak with you to
                    create a job card, wherein there is reasonable justification
                    that our team needs to speak with you for defining the right
                    scope of work to arrange for the right spares; or
                  </li>
                </ul>
              </ul>
              <h4>8.2 Refunds</h4>
              <ul>
                <li>
                  Please be informed that any advance or doorstep visit fee paid
                  by you will be treated in the following ways when You opt to
                  cancel the service appointment.
                </li>
                <li>
                  You can opt to receive a 100% refund of the doorstep visit fee
                  for the following reasons:
                </li>
                <ul>
                  <li>
                    If you cancel the service appointment prior to the day of
                    the scheduled timeslot.
                  </li>
                  <li>
                    If the technician doesn’t reach your location within the
                    assigned timeslot
                  </li>
                  <li>
                    If you choose to cancel the appointment because the service
                    has not been delivered due to operational issues by
                    Spannerdoor
                  </li>
                  <li>
                    Your doorstep visit fee will get credited into your
                    Spannerdoor wallet (SD Wallet) for the following reasons:
                    <ul>
                      <li>
                        If you cancel the service appointment on the day of the
                        service at least two (2) hours before the scheduled
                        time-slot
                      </li>
                      <li>
                        If the service appointment gets cancelled due to
                        incorrect vehicle information
                      </li>
                      <li>
                        If the service appointment gets cancelled due to the
                        non-availability of appropriate parking space
                      </li>
                    </ul>
                  </li>
                  <li>
                    You shall not be entitled to a refund or Spannerdoor wallet
                    (SD Wallet) credit if the service appointment is cancelled
                    for any other reasons that are not listed above.
                  </li>
                  <li>
                    All refunds processed will be credited to your source
                    account within 7 working days.
                  </li>
                </ul>
              </ul>

              <h3>9. LEGAL COMPLIANCE</h3>
              <p>
                You and Spannerdoor will each comply with all laws, rules, and
                regulations, if any, applicable in connection with the
                performance of their respective obligations under this
                Agreement.
              </p>
              <h3>10. UNENFORCEABLE PROVISIONS</h3>

              <p>
                If any provision of this Agreement is or is found to be
                unenforceable under applicable law that will not affect the
                enforceability of the other provisions of this Agreement.
              </p>
              <h3>11. GOVERNING LAW; ARBITRATION</h3>
              <p>
                There are mechanisms available to you to resolve any dispute,
                conflict, claim, or controversy arising out of or broadly in
                connection with or relating to the services or these Terms,
                including those relating to its validity, its construction, or
                its enforceability or with the services provided (any
                "Dispute"). You agree and acknowledge that you must raise any
                Dispute with the services, or the services provided is
                practicable, but no later than 30 days from the date of the
                Dispute arises. If you fail to raise a Dispute within 30 days
                from the initial occurrence of such Dispute, Spannerdoor shall
                be under no obligation to resolve such Dispute.
              </p>
              <p>
                Except as otherwise set forth in these Terms, these Terms shall
                be exclusively governed by and construed in accordance with the
                laws of India, excluding its rules on conflicts of laws.
              </p>
              <p>
                In the event of a Dispute, both parties shall pursue final and
                binding arbitration and the venue of the arbitration shall be
                Bengaluru, Karnataka
              </p>

              <p>
                Any proceedings, including documents and briefs submitted by the
                parties, , and correspondence, order and awards issued by an
                arbitrator, shall remain strictly confidential and shall not be
                disclosed to any third party without the express written consent
                from the other party unless: (i) the disclosure to the third
                party is reasonably required in the context of conducting the
                arbitration proceedings; and (ii) the third party agrees
                unconditionally in writing to be bound by the confidentiality
                obligation set out in these Terms
              </p>

              <h3>12. FORCE MAJEURE</h3>
              <p>
                Any delay in or failure of performance by Spannerdoor under this
                Agreement will not be considered a breach of this Agreement and
                will be excused to the extent caused by any occurrence beyond
                its reasonable control, including, but not limited to, Act of
                God, an outbreak of diseases, power outages, failures of the
                internet, failures of banking or any other unforeseeable event.
              </p>

              <h3>13. FEEDBACK AND INFORMATION</h3>
              <p>
                Any feedback You provide to this Website shall be deemed to be
                non-confidential. Spannerdoor shall be free to use such
                information on an unrestricted basis. Further, by submitting the
                feedback, You represent and warrant that (i) Your feedback does
                not contain confidential or proprietary information of You or of
                third parties; (ii) Spannerdoor is not under any obligation of
                confidentiality, express or implied, with respect to the
                feedback; (iii) Spannerdoor may have something similar to the
                feedback already under consideration or in development; and (iv)
                You are not entitled to any compensation or reimbursement of any
                kind from Spannerdoor for the feedback under any circumstances
              </p>
              <h3>14. OTHER PROVISIONS:</h3>
              <p>
                For the purposes of the Consumer Protection Act, 2019 and rules
                and regulations thereunder, the grievance officer of Spannerdoor
                shall be:
              </p>

              <p>
                <strong>Name:</strong>
              </p>

              <p>Abishek MR </p>
              <p>Spannerdoor Private Limited (Spannerdoor)</p>

              <p>
                <strong>Office Address:</strong> 1st floor, 88/89, Gurukrupa
                Nilaya, Kempegowda Layout, Kattigenahalli,
                <br /> Bangalore, KA, India – 560064
              </p>
              <p>
                <strong>Contact Number:</strong> +91 80504 28282
              </p>
              <p>
                <strong>Email:</strong>{" "}
                <a href="mailto:info@spannerdoor.com">info@spannerdoor.com</a>
              </p>
              <p>
                <strong>Timings:</strong> 10 AM – 7 PM, Monday - Saturday,
                excluding public holidays
              </p>
              <h3>15. NOTICE</h3>
              <p>
                Spannerdoor may give notice by means of a general notice on the
                services, electronic mail to your email address in your account,
                or by written communication sent to your address as set forth in
                your account. You may give notice to Spannerdoor by written
                communication to 1st floor, 88/89, Gurukrupa Nilaya, Kempegowda
                Layout, Kattigenahalli, Bangalore, KA, India – 560064
              </p>
              <h3>16. GENERAL</h3>
              <p>
                You may not assign or transfer these Terms in whole or in part
                without Spannerdoor’s prior written approval. You give your
                approval to Spannerdoor for it to assign or transfer these Terms
                in whole or in part, including to (i) a subsidiary or affiliate;
                (ii) an acquirer of Spannerdoor’s equity, business, or assets;
                or (iii) a successor by merger.
              </p>
              <p>
                No joint venture, partnership, employment, or agency
                relationship exists between You, Spannerdoor or any service
                provider because of the contract between You and Spannerdoor or
                use of the Services.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
