import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Button,
  Box,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputLabel,
} from "@mui/material";

import { getCookie } from "../../utils/helpers/utils";
import { apiEndPoints } from "../../utils/helpers/endPoints";
import { SpannerDoorContext } from "../../utils/context/GlobalContext";
import { useCustomDialog } from "../Dialog/CustomDialog/CustomDialogService";
import {
  sendGetRequest,
  sendPutRequest,
} from "../../utils/helpers/requestController";

import "./SideBar.scss";

import signInSvg from "../../assets/images/signInSvg.svg";

const SideBar = ({ disabledPayment = false, addOnPrice = 0 }) => {
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = useState("");
  const { service, packages, brandName, modelName, dispatch }: any =
    React.useContext(SpannerDoorContext);

  const handleBrandSelect = (e: any) => {
    const serviceObj = Object.assign({}, service);
    switch (e.target.name) {
      case "select-brand":
        serviceObj.vehicleInfo.brand = e.target.value;
        serviceObj.vehicleInfo.model = "";
        getModelNames();
        break;
      case "select-model":
        serviceObj.vehicleInfo.model = e.target.value;
        break;
    }
    dispatch({ type: "service", payload: serviceObj });
  };

  const handleBooking = async (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const currentPackage = packages.filter((pack: any) => {
    return pack._id === service.packages;
  });

  const getModelNames = async () => {
    const url = apiEndPoints.modelNames + "/" + service.vehicleInfo.brand;
    const res = await sendGetRequest(url);
    dispatch({ type: "modelName", payload: res });
  };

  const getBrandNames = async () => {
    const res = await sendGetRequest(apiEndPoints.brandNames);
    dispatch({ type: "brandName", payload: res });
  };

  React.useEffect(() => {
    getBrandNames();
  }, []);

  const customDialog = useCustomDialog();
  const [discount, setDiscount] = React.useState(service?.discountAmount || 0);
  const [subTotal, setSubTotal] = React.useState(addOnPrice || 0);
  const [done, setDone] = React.useState({ success: false, error: false });
  const [isEmissionAdded, setIsEmissionAdded] = React.useState(
    service?.emissionTestNeeded || false
  );
  const navigate = useNavigate();
  const applyDiscountHandler = async () => {
    try {
      const res = await sendGetRequest(
        apiEndPoints.verifyDiscount + service?.discountCode
      );
      if (res) {
        setDiscount(res.price);
        setDone({
          success: true,
          error: false,
        });
        dispatch({
          type: "service",
          payload: { ...service, discountAmount: res.price },
        });
      }
    } catch (error: any) {
      setDiscount(0);
      setDone({
        success: false,
        error: true,
      });
    }
  };

  React.useEffect(() => {
    let subTotalPrice = 0;

    if (currentPackage[0]?.price) {
      subTotalPrice = parseInt(currentPackage[0]?.price, 10);
    }
    if (service.emissionTestNeeded) {
      subTotalPrice = subTotalPrice + 60;
    }

    setSubTotal(subTotalPrice);
    if (service.discountAmount) {
      setDone({
        success: true,
        error: false,
      });
    }
  }, [service]);

  React.useEffect(() => {
    setSubTotal((prev) => prev + addOnPrice);
  }, [addOnPrice]);

  const makePaymentHandler = async () => {
    dispatch({
      type: "service",
      payload: {
        ...service,
        totalCost: subTotal - discount + subTotal * (18 / 100),
        emissionTestNeeded: isEmissionAdded,
      },
    });
    try {
      const url = apiEndPoints.confirmService;
      const payload = {
        ...service,
        _id: sessionStorage.getItem("serviceId"),
        userId: getCookie("userId"),
        paymentStatus: "pending",
      };
      const res = await sendPutRequest(url, payload);
      sessionStorage.setItem("serviceId", res._id);
      customDialog({
        catchOnCancel: false,
        description: [
          "Service Booked Successfully",
          "You have Booked Your Service Successfully",
        ],
        customClass: "no-description",
        successLabel: "",
        maxWidthProp: "md",
        imgSrc: signInSvg,
      }).then(async () => {});
      navigate("/service/home");
    } catch (error: any) {}
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log(service);

    setOpen(false);
  };

  return (
    <>
      <div className="sideBar">
        <div className="sideBar__container">
          <div className="sideBar__subHeader">Vehicle Info</div>
          <div className="sideBar__brand">
            <div className="sideBar__brand__name">
              {
                brandName?.filter(
                  (brand: any) => brand._id === service?.vehicleInfo?.brand
                )[0]?.name
              }{" "}
              {
                modelName?.filter(
                  (model: any) => model._id === service?.vehicleInfo?.model
                )[0]?.name
              }
            </div>
            <Link
              to=""
              onClick={handleClickOpen}
              className="sideBar__brand__button"
            >
              Change
            </Link>
          </div>
          <div className="sideBar__brandImage">
            {modelName?.filter(
              (model: any) => model._id === service?.vehicleInfo?.model
            )[0]?.image ? (
              <img
                className="sideBar__brandImage__img"
                src={
                  modelName?.filter(
                    (model: any) => model._id === service?.vehicleInfo?.model
                  )[0]?.image
                }
                alt="Selected Model"
              />
            ) : (
              <img
                className="sideBar__brandImage__img"
                src="../../../images/mybike.svg"
                alt=""
              />
            )}
          </div>
          <div className="sideBar__subHeader pt-border">Service Package</div>
          <div className="sideBar__header">
            <div>
              <span className="sideBar__header__type">
                {currentPackage[0]?.name}
              </span>
              <span className="sideBar__header__price">
                {currentPackage[0]?.price}
              </span>
            </div>
            <Link to="/service/package" className="sideBar__brand__button">
              Change
            </Link>
          </div>
          <div className="sideBar__subHeader">Apply Coupon Code</div>
          <div className="sideBar__input">
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="outlined-adornment-weight"
                value={service.discountCode}
                disabled={done.success === true && done.error === false}
                onChange={(e) => {
                  setDone({
                    success: false,
                    error: false,
                  });
                  dispatch({
                    type: "service",
                    payload: { ...service, discountCode: e.target.value },
                  });
                }}
                endAdornment={
                  <InputAdornment position="end" placeholder="00000000">
                    {done.success === true && done.error === false ? (
                      <CloseIcon
                        onClick={() => {
                          setSubTotal(subTotal + discount);
                          setDiscount(0);
                          setDone({
                            success: false,
                            error: false,
                          });
                          dispatch({
                            type: "service",
                            payload: {
                              ...service,
                              discountAmount: 0,
                              discountCode: "",
                            },
                          });
                        }}
                      />
                    ) : (
                      <div
                        className="sideBar__brand__button"
                        onClick={applyDiscountHandler}
                      >
                        Apply
                      </div>
                    )}
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "discount",
                }}
              />

              {done.error && (
                <div className="form__input-error">
                  <p>Please Enter a Valid Coupon</p>
                </div>
              )}
              {!done.error && done.success && (
                <div className="form__input-info">
                  <p>Coupon applied!!</p>
                </div>
              )}
            </FormControl>
          </div>
          <div className="sideBar__input">
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="outlined-adornment-weight"
                value="Emission Test (₹60)"
                readOnly
                endAdornment={
                  <InputAdornment position="end">
                    {isEmissionAdded ? (
                      <CloseIcon
                        onClick={() => {
                          setIsEmissionAdded(false);
                          setSubTotal(subTotal - 60);
                          dispatch({
                            type: "service",
                            payload: {
                              ...service,
                              emissionTestNeeded: false,
                            },
                          });
                        }}
                      />
                    ) : (
                      <div
                        className="sideBar__brand__button"
                        onClick={() => {
                          setSubTotal(60 + subTotal);
                          setIsEmissionAdded(true);
                          dispatch({
                            type: "service",
                            payload: {
                              ...service,
                              emissionTestNeeded: true,
                            },
                          });
                        }}
                      >
                        Add
                      </div>
                    )}
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "discount",
                }}
              />
            </FormControl>
            {isEmissionAdded && (
              <div className="form__input-info">
                <p>Emission added!!</p>
              </div>
            )}
          </div>
          <div className="sideBar__costDetails">
            <div className="costIndividual">
              <div className="costHeader">Subtotal</div>
              <div className="cost">{subTotal}</div>
            </div>
            <div className="costIndividual">
              <div className="costHeader">Discount</div>
              <div className="cost discount">{discount}</div>
            </div>
            <div className="costIndividual">
              <div className="costHeader">18% GST</div>
              <div className="cost">{Math.round(subTotal * (18 / 100))}</div>
            </div>
            <div className="costIndividual">
              <div className="costHeader total">Total Cost</div>
              <div className="cost total">
                {/* {Math.round(subTotal + addOnList?.filter((addOn: any) => addOn._id === service?.addOns?.brand)[0]?.name - discount + subTotal * (18 / 100))} */}
                {Math.round(subTotal - discount + subTotal * (18 / 100))}
              </div>
            </div>
          </div>
          <div className="sideBar__payment">
            <Button
              fullWidth
              variant="contained"
              disabled={disabledPayment ? true : true}
            >
              Make Payment
            </Button>
          </div>

          <Button
            fullWidth
            variant="outlined"
            disabled={disabledPayment ? true : false}
            onClick={makePaymentHandler}
          >
            Pay Later & Continue
          </Button>
          <div className="sideBar__footer">
            <p>We Accept</p>
            <img
              src={"../../images/payment-gateway.svg"}
              alt="payment-gateway"
            />
          </div>
        </div>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change your Brand and Model</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <form onSubmit={handleBooking} autoComplete="off">
            <Box style={{ paddingTop: "10px" }}>
              <FormControl fullWidth>
                <InputLabel id="search-select-model">Select Brand</InputLabel>
                <Select
                  // Disables auto focus on MenuItems and allows TextField to be in focus
                  MenuProps={{ autoFocus: false }}
                  labelId="search-select-model"
                  name="select-brand"
                  label="Select Brand"
                  onChange={handleBrandSelect}
                  onClose={() => setSearchText("")}
                  value={service.vehicleInfo.brand}
                >
                  <ListSubheader>
                    <TextField
                      className="select-search"
                      size="small"
                      // Autofocus on textfield
                      autoFocus
                      placeholder="Search..."
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              src={"../../../images/search-icon.svg"}
                              alt="search"
                            />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>

                  {brandName?.map((option: any) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box style={{ paddingTop: "10px" }}>
              <FormControl fullWidth>
                <InputLabel id="search-select-model">Select Model</InputLabel>
                <Select
                  MenuProps={{ autoFocus: false }}
                  labelId="search-select-model"
                  name="select-model"
                  label="Select Model"
                  onChange={handleBrandSelect}
                  onClose={() => setSearchText("")}
                  value={service.vehicleInfo.model}
                >
                  <ListSubheader>
                    <TextField
                      size="small"
                      // Autofocus on textfield
                      autoFocus
                      placeholder="Type to search..."
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              src={"../../../images/search-icon.svg"}
                              alt="search"
                            />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>

                  {modelName?.map((option: any) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box>
              <FormControl fullWidth>
                <Button type="submit" variant="contained" onClick={handleClose}>
                  Change
                </Button>
              </FormControl>
            </Box>
          </form>
        </DialogContent>
        {/* <DialogActions>
  <Button onClick={handleClose}>Cancel</Button>
</DialogActions> */}
      </Dialog>
    </>
  );
};

export default SideBar;
