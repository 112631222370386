import React from "react";
import { Service } from "../models/service";
export const SpannerDoorContext = React.createContext({});

const serviceData = sessionStorage.getItem("service");
const brandName = sessionStorage.getItem("brandName");
const modelName = sessionStorage.getItem("modelName");
const packages = sessionStorage.getItem("packages");

const initialState: any = {
  serviceList: [],
  service: serviceData ? JSON.parse(serviceData) : new Service(),
  brandName: brandName ? JSON.parse(brandName) : [],
  modelName: modelName ? JSON.parse(modelName) : [],
  addOnList: [],
  addOnGroup: [],
  packages: packages ? JSON.parse(packages) : [],
  loading: false,
};

const spannerDoorReducer = (state: any, action: any) => {
  switch (action.type) {
    case "serviceList":
      return { ...state, serviceList: action.payload };

    case "service":
      sessionStorage.setItem("service", JSON.stringify(action.payload));
      return { ...state, service: action.payload };
    case "addOnList":
      return { ...state, addOnList: action.payload };
    case "addOnGroup":
      return { ...state, addOnGroup: action.payload };
    case "brandName":
      sessionStorage.setItem("brandName", JSON.stringify(action.payload));
      return { ...state, brandName: action.payload };
    case "modelName":
      sessionStorage.setItem("modelName", JSON.stringify(action.payload));
      return { ...state, modelName: action.payload };
    case "packages":
      sessionStorage.setItem("packages", JSON.stringify(action.payload));
      return { ...state, packages: action.payload };
    case "clear":
      return { ...initialState, service: new Service() };
    case "loading":
      return { ...state, loading: action.payload };
    default:
      break;
  }
};

const GlobalContextControl = ({ children }: any) => {
  const [state, dispatch]: any = React.useReducer(
    spannerDoorReducer,
    initialState
  );

  return (
    <SpannerDoorContext.Provider value={{ ...state, dispatch }}>
      {children}
    </SpannerDoorContext.Provider>
  );
};

export default GlobalContextControl;
