import React from "react";

const ProgressBar = ({
  bgcolor,
  progress,
  height,
  isProgresstxt,
  progressBarLabel,
}) => {
  const Parentdiv = {
    height: height,
    backgroundColor: "#D6DFEB",
    borderRadius: 21,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "right",
  };

  const progresstext = {
    padding: 10,
    color: "black",
    fontWeight: 900,
  };

  return (
    <div className="progress-bar">
      <div className="progress-bar-label">{progressBarLabel}</div>
      <div className="progress-block" style={Parentdiv}>
        <div className="progress-item" style={Childdiv}>
          <span className="progress-item" style={progresstext}>
            {isProgresstxt ? `${progress}%` : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
