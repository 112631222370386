import React from "react";
import "./Footer.scss";
import { Facebook, Instagram, Twitter, IndiaFlag, Heart } from "../../../src/assets/Icons";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer__block">
          <div className="footer__logo">
            <Link to="/">
              <img src={"../../../images/logo.svg"} alt="logo"></img>
            </Link>
          </div>
          <div className="footer__socialmedia">
            <Link to="https://www.facebook.com/people/Spannerdoor/100091129757170/?mibextid=ZbWKwL" target="_blank">
              <Facebook />
            </Link>
            <Link to="https://www.instagram.com/spannerdoor/" target="_blank">
              <Instagram />
            </Link>
            {/* <Link to="/" target="_blank">
              <LinkedIn />
            </Link> */}
            <Link to="https://twitter.com/spannerdoor" target="_blank">
              <Twitter />
            </Link>
          </div>
        </div>
        <div className="footer__block p-0">
          <div className="footer__links">
            <Link to="/about">About Us</Link>
            <Link to="/services">Services</Link>
            <Link to="/careers">Career</Link>
            <Link to="/faq" >Faq</Link>
            <Link to="https://spannerdoor.medium.com/" target="_blank" >Blog</Link>
            <Link to="/contact">Contact Us</Link>
            
          </div>
          <div className="footer__madeby">
            <span>Made with </span>
            <Heart />
            <span>in India</span>
            <IndiaFlag />
          </div>
        </div>

        <div className="footer__block copyright">
          <span>Copyright ©️ 2024 SPANNERDOOR PRIVATE LIMITED. All rights reserved.</span>
          <div className="footer__links">
            <Link to="/terms" target="blank">terms & condition</Link>
            <Link to="/privacy" target="blank">privacy policy</Link>
            {/* <Link to="/">Cookies</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
