import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./CustomDialog.scss";

export type ConfirmationOptions = {
  catchOnCancel?: boolean;
  description?: any;
  successLabel?: any;
  hideCancel?: boolean;
  customClass?: string;
  link?: any;
  imgSrc?: string;
  maxWidthProp?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
};

type ConfirmationDialogProps = {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onClosed?: () => void;
} & ConfirmationOptions;

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  description,
  link,
  onSubmit,
  imgSrc,
  onClose,
  hideCancel,
  customClass,
  successLabel,
  maxWidthProp,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidthProp ? maxWidthProp : "xl"}
      aria-labelledby="popup-dialog"
      aria-describedby="popup-dialog-description"
      className={`custom-dialog ${customClass ? customClass : ""}`}
    >
      <CloseIcon className="close-icon" onClick={onSubmit} />
      {imgSrc && <img src={imgSrc} alt="" />}
      {description.length > 0 && (
        <DialogContent>
          <DialogContentText>
            {description.map((descrp: any, i: number) => (
              <p key={i}>{descrp}</p>
            ))}
          </DialogContentText>
        </DialogContent>
      )}
      {link && (
        <div
          style={{
            display: "flex",
            gap: "5px",
            justifyContent: "center",
            marginTop: "5px",
          }}
        >
          <span>{link.message} </span>
          <a href={link?.link}>{link?.key}</a>
        </div>
      )}
      <DialogActions>
        <button className="btn btn--save" onClick={onSubmit} id="dialog-ok">
          {successLabel}
        </button>
      </DialogActions>
    </Dialog>
  );
};
