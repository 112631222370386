import React from "react";
import "./PendingOrder.scss";
import { sendGetRequest, sendPutRequest } from "../../utils/helpers/requestController";
import { apiEndPoints } from "../../utils/helpers/endPoints";
import { getCookie } from "../../utils/helpers/utils";
const PendingOrder = () => {
  const [data, setData] = React.useState<any>();

  React.useEffect(() => {
    getStatusOrders();
  }, []);

  const getStatusOrders = async () => {
    const res = await sendGetRequest(apiEndPoints.createService);
    if (res && res.length > 0) {
      setData(res[0]);
    }
    return res;
  };

  const makePaymentHandler = async () => {
    try {
      let res = await sendGetRequest(`${apiEndPoints.service}/${data._id}`);
      const url = apiEndPoints.confirmService;
      const payload = {
        ...res,
        _id: data._id,
        userId: getCookie("userId"),
        paymentStatus: "pending",
      };
      await sendPutRequest(url, payload);
      sessionStorage.setItem("serviceId", res._id);
    } catch (error: any) {}
  };

  const cancelOrder = async () => {
    let res = await sendGetRequest(`${apiEndPoints.service}/${data._id}`);
    res.serviceAccepted = false;
    await sendPutRequest(apiEndPoints.createService + "/" + data._id, res);
  };
  return (
    data && (
      <div className="pending-order">
        <div className="container">
          <div className="pending-order__block">
            <div className="pending-order__left">
              <div className="pending-order__title">Place your pending order</div>
              <div className="pending-order__item">
                <div className="pending-order__item-info">
                  <div className="xs-text-light">Bike</div>
                  <div className="lg-text-dark">{data?.vehicleInfo?.model?.name}</div>
                </div>
                <div className="pending-order__item-info">
                  <div className="xs-text-light">Plan</div>
                  <div className="lg-text-dark ">{data?.packages?.name}</div>
                </div>
                <div className="pending-order__item-info">
                  <div className="xs-text-light">Addons</div>
                  <div className="lg-text-dark ">
                    {data?.addOns && data?.addOns.length > 0 && data?.addOns[0]?.name}
                    {data?.addOns && data?.addOns.length > 1 && <span>&nbsp;+{data?.addOns.length - 1} more</span>}
                  </div>
                </div>
                <div className="pending-order__item-info">
                  <div className="xs-text-light">Emission test</div>
                  <div className="lg-text-dark ">{data.emissionTestNeeded ? "Yes" : "No"}</div>
                </div>
                <div className="pending-order__item-info">
                  <div className="xs-text-light">Discount</div>
                  <div className="lg-text-dark ">Rs {data.discountAmount}</div>
                </div>
                <div className="pending-order__item-info">
                  <div className="xs-text-light">Total</div>
                  <div className="lg-text-dark ">Rs {data.totalCost}</div>
                </div>
              </div>
            </div>
            <div className="pending-order__right">
              <div className="pending-order__title">Proceed payment to make service</div>
              <div className="pending-order__item">
                <button className="button " onClick={makePaymentHandler}>
                  Pay now
                </button>
                <button className="button button--outline" onClick={cancelOrder}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PendingOrder;
