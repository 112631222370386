import React from "react";

import "./GetInTouch.scss";
import { useNavigate } from "react-router-dom";

const GetInTouch = () => {
  const navigate = useNavigate();
  return (
    <div className="getintouch">
      <div className="container getintouch__block">
        <h1>Have any additional questions?</h1>
        <p>Driving excellence, one service at a time.</p>
        <button
          className="button"
          onClick={() => {
            navigate("/contact");
          }}
        >
          Get in touch
        </button>
      </div>
    </div>
  );
};

export default GetInTouch;
