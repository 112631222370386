const getCookie = (reqCookie: string) => {
  let name = reqCookie + "=",
    cookie,
    tempCookie;
  cookie = document.cookie.split(";");
  for (let i = 0; i < cookie.length; i += 1) {
    tempCookie = cookie[i];
    if (tempCookie.charAt(0) === " ") {
      tempCookie = tempCookie.substring(1);
    }
    if (tempCookie.indexOf(name) === 0) {
      return tempCookie.substring(name.length, tempCookie.length);
    }
  }
  return false;
};

const setCookie = (key: string, value: string) => {
  document.cookie = key + "=" + value;
};

export { getCookie, setCookie };
