import React from "react";

import Table from "react-bootstrap/Table";
import "../GarageList/GarageList.scss";
import LeftSideBar from "../LeftSideBar/LeftSideBar";
import { Link, useNavigate } from "react-router-dom";
import { Edit, Delete, LeftArrowCircle } from "../../../assets/Icons";
import {
  sendDeleteRequest,
  sendGetRequest,
} from "../../../utils/helpers/requestController";
import { apiEndPoints } from "../../../utils/helpers/endPoints";
import { Snackbar } from "@mui/material";

const AccessoriesList = () => {
  const [open, setOpen] = React.useState(false);
  const [accessoriesList, setAccessoriesList] = React.useState([]);

  const navigate = useNavigate();

  const getAccessoriesList = async (search = "") => {
    let url = apiEndPoints.addOns;
    if (search) {
      url = url + "?search=" + search;
    }
    const res = await sendGetRequest(url);
    setAccessoriesList(res);
  };

  React.useEffect(() => {
    getAccessoriesList();
  }, []);

  const deleteAccessory = async (id: any) => {
    const res = await sendDeleteRequest(apiEndPoints.accessories + "/" + id);
    if (res) {
      setOpen(true);
      getAccessoriesList();
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  };

  const searchHandler = (value: any) => {
    getAccessoriesList(value);
  };

  return (
    <div className="dashboard-layout admin">
      <Snackbar open={open} autoHideDuration={6000} message="Garage Deleted" />
      <div className="dashboard-layout__left">
        <LeftSideBar />
      </div>
      <div className="dashboard-layout__right">
        <div className="garage-list">
          <div className="service-dashboard__header">
            <h3>Accessories List</h3>
            <h3 className="go-back" style={{ display: "none" }}>
              <Link to="">
                <LeftArrowCircle /> Go Back
              </Link>
            </h3>
          </div>

          <div className="garage-list__table">
            <div className="garage-list__table-header">
              <div className="garage-list__table-search">
                <input
                  type="text"
                  placeholder="Search by name,brand or vehicle type"
                  className="garage-list__table-search-input"
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      searchHandler(e.target.value);
                    }
                  }}
                />
              </div>
              <div className="garage-list__action">
                <Link
                  to="/admin/accessories/add"
                  className="button button--outline"
                >
                  + Add Accessories
                </Link>
              </div>
            </div>

            <Table responsive="sm">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Code</th>
                  <th>Type </th>
                  <th>Sub Type</th>
                  <th>Vehicle Type</th>
                  <th>Descriptions</th>
                  <th>Tax Rate</th>
                  <th>Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {accessoriesList?.map((accessory: any) => {
                    return (
                      <tr>
                        <td>
                          <img
                            src={accessory?.image}
                            alt=""
                            style={{ height: "40px" }}
                          />
                        </td>
                        <td>SPDR{accessory._id}</td>
                        <td>{accessory?.name}</td>
                        <td>{accessory?.brand}</td>
                        <td>{accessory?.vehicleType}</td>
                        <td>{accessory?.description}</td>
                        <td>{accessory?.taxRate}</td>
                        <td>{accessory?.price}</td>

                        <td className="table-action">
                          <Edit
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate("/admin/accessories/" + accessory._id);
                            }}
                          />

                          <Delete
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              deleteAccessory(accessory?._id);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </>
              </tbody>
            </Table>
            {/* <nav className="pagenination">
              <List>
                {items.map(({ page, type, selected, ...item }, index) => {
                  let children = null;

                  if (type === "start-ellipsis" || type === "end-ellipsis") {
                    children = "…";
                  } else if (type === "page") {
                    children = (
                      <button
                        type="button"
                        style={{
                          fontWeight: selected ? "bold" : undefined,
                        }}
                        {...item}
                      >
                        {page}
                      </button>
                    );
                  } else {
                    children = (
                      <button type="button" {...item}>
                        {type}
                      </button>
                    );
                  }

                  return <li key={index}>{children}</li>;
                })}
              </List>
            </nav> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessoriesList;
