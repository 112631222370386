import React from "react";
import "./LeftSideBar.scss";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Home,
  Help,
  Reload,
  History,
  Document,
  Logout,
} from "../../../assets/Icons";
import { redirectToLogin } from "../../../utils/helpers/requestController";
import { useCustomDialog } from "../../../components/Dialog/CustomDialog/CustomDialogService";
import signOut from "../../../assets/images/signOut.svg";
const LeftSideBar = () => {
  const customDialog = useCustomDialog();
  const navigate = useNavigate();
  return (
    <div className="leftsidebar">
      <div className="leftsidebar__nav">
        <ul className="leftsidebar__nav-list">
          <li className="leftsidebar__nav-item">
            <NavLink className="leftsidebar__nav-link" to="/service/home">
              <Home />
              <span>Home</span>
            </NavLink>
          </li>
          <li className="leftsidebar__nav-item">
            <NavLink className="leftsidebar__nav-link" to="/service/dashboard">
              <Reload />
              <span>
                <span className="mob-hide">On Going </span> Services
              </span>
            </NavLink>
          </li>
          <li className="leftsidebar__nav-item">
            <NavLink className="leftsidebar__nav-link" to="/service/history">
              <History />
              <span>
                {" "}
                <span className="mob-hide">Service</span> History
              </span>
            </NavLink>
          </li>
          {/* <li className="leftsidebar__nav-item">
            <NavLink className="leftsidebar__nav-link" to="/">
              <Document />
              <span>Documents</span>
            </NavLink>
          </li> */}
          <li className="leftsidebar__nav-item">
            <NavLink className="leftsidebar__nav-link" to="/service/help">
              <Help />
              <span>
                Help <span className="mob-hide">& Support</span>
              </span>
            </NavLink>
          </li>
        </ul>
        <div
          className="leftsidebar__nav-item"
          style={{ cursor: "pointer" }}
          onClick={() => {
            customDialog({
              catchOnCancel: false,
              description: [
                "Sign out Successfully",
                "You have sign out successfully!",
              ],
              customClass: "no-description",
              successLabel: "",
              maxWidthProp: "md",
              imgSrc: signOut,
            }).then(async () => {
              redirectToLogin();
            });
          }}
        >
          <div className="leftsidebar__nav-link">
          <Logout />
          <span>Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSideBar;
