import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import "./ServiceOrders.scss";
import NotPicked from "./NotPicked/NotPicked";
import Picked from "./Picked/Picked";
import Repairing from "./Repairing/Repairing";
import FilterComponents from "./FilterComponents/FilterComponents";
import Completed from "./Completed/Completed";
import { apiEndPoints } from "../../../utils/helpers/endPoints";
import {
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
} from "../../../utils/helpers/requestController";
import { SpannerDoorContext } from "../../../utils/context/GlobalContext";
import Diagnose from "./Diagnose/Diagnose";
import Cancelled from "./Cancelled/Cancelled";

const ServiceOrders = () => {
  const [value, setValue] = React.useState("1");
  const [data, setData] = React.useState();
  const [search, setSearch] = React.useState();
  const [filterStart, setFilterStart] = React.useState();
  const [filterEnd, setFilterEnd] = React.useState();
  const [cancelReason, setCancelReason] = React.useState<any>("");

  const handleChange = (
    event: React.SyntheticEvent<EventTarget>,
    newValue: string
  ) => {
    getStatusOrders(newValue);
    return setValue(newValue);
  };

  const getStatusOrders = async (
    value: any,
    searchKey: any = "",
    filterStart: any = "",
    filterEnd: any = ""
  ) => {
    let res: any;
    if (!searchKey && !filterStart && !filterEnd) {
      res = await sendGetRequest(apiEndPoints.orderStatus + value);
    }
    if (searchKey) {
      res = await sendGetRequest(
        apiEndPoints.orderStatus + value + "?search=" + searchKey
      );
    }
    if (filterStart && filterEnd) {
      res = await sendGetRequest(
        apiEndPoints.orderStatus +
          value +
          "?startDate=" +
          filterStart +
          "&endDate=" +
          filterEnd
      );
    }
    if (searchKey && filterStart && filterEnd) {
      res = await sendGetRequest(
        apiEndPoints.orderStatus +
          value +
          "?search=" +
          searchKey +
          "&startDate=" +
          filterStart +
          "&endDate=" +
          filterEnd
      );
    }

    setData(res);
    return res;
  };

  const onAcceptHandler = async (action: any, item: any) => {
    if (action === "accept") {
      try {
        const payload = item;
        payload.serviceAccepted = true;
        const res = await sendPutRequest(
          apiEndPoints.createService + "/" + payload._id,
          payload
        );
        getStatusOrders(value);
      } catch (error: any) {}
    } else if (action === "cancel") {
      try {
        const payload = item;
        payload.serviceAccepted = false;
        payload.cancelRemark = cancelReason;
        const res = await sendPutRequest(
          apiEndPoints.createService + "/" + payload._id,
          payload
        );
        getStatusOrders(value);
      } catch (error: any) {}
    }
  };

  React.useEffect(() => {
    getStatusOrders(1);
  }, []);

  const searchHandler = (
    searchKey: any = "",
    filterStart: any = "",
    filterEnd: any = ""
  ) => {
    setSearch(searchKey);
    setFilterStart(filterStart ? filterStart.toISOString() : "");
    setFilterEnd(filterEnd ? filterEnd.toISOString() : "");
  };

  React.useEffect(() => {
    getStatusOrders(value, search, filterStart, filterEnd);
  }, [search, filterEnd, filterStart]);

  return (
    <div className="service-order">
      <div className="service-dashboard__header">
        <h3>Service Orders</h3>
        <div className="service-order__filter">
          <FilterComponents searchHandler={searchHandler} />
        </div>
      </div>
      <div className="service-order__block">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ maxWidth: { xs: 420, sm: 980 } }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Not Picked" value="1" />
                <Tab label="Picked" value="2" />
                <Tab label="Diagnose" value="3" />
                <Tab label="Repairing" value="4" />
                <Tab label="Completed" value="5" />
                <Tab label="Cancelled" value="7" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <NotPicked
                data={data}
                onAcceptHandler={onAcceptHandler}
                setCancelReason={setCancelReason}
                cancelReason={cancelReason}
              />
            </TabPanel>
            <TabPanel value="2">
              <Picked data={data} />
            </TabPanel>
            <TabPanel value="4">
              <Repairing data={data} />
            </TabPanel>
            <TabPanel value="5">
              <Completed data={data} />
            </TabPanel>
            <TabPanel value="3">
              <Diagnose data={data} />
            </TabPanel>
            <TabPanel value="7">
              <Cancelled data={data} />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};

export default ServiceOrders;
