import React, { useEffect } from "react";
import "./About.scss";
import TrustedCompay from "../Home/TrustedCompany/TrustedCompay";
import { Author, FB, TWT, Linked } from "../../assets/Icons";
import { Link } from "react-router-dom";

const About = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div className="about">
        <div className="container">
          <div className="about__block">
            <div className="about__title">Spannerdoor aims to be the Reliable and Cost-effective Bike Services</div>
            <div className="about__text">
              Spannerdoor is a vehicle service startup based out of Namma Bengaluru. Which provides vehicle services (Both ICE & EV) on a demand and
              subscription basis. The company provides emergency roadside as well as scheduled servicing for 2 wheeler owners. The Company also focuses on solving
              service issues for EV Customers. The Company plans to extend the business towards resale of the Vehicles which are completely serviced under the
              Spannerdoor subscription model.
              <br />
              <br />
              Spannerdoor strives to provide reliable and economic vehicle services, the best of both realms. We are at your doorstep for everything related to
              two wheeler vehicles, including vehicle servicing, repairs, washing and complete solution at one point.
              <br />
              <br />
              App-based aggregator providing automotive repairing services.ICE Vehicles - Engine Oil Replacement, Flat tire assistance, Cleaning – detailing -
              denting painting, Battery replacement. Electric Vehicles - Charging Support, Towing Support, Company Integrated Diagnostics support.
              <br />
              <br />
              Utilize our free pick-up and drop-off service while remaining cozy in your house or place of business. You can rely on us to serve as your
              personal mechanic, advisor, and authority on vehicle maintenance.
            </div>
            <div className="about__img">
              <img src={"../../../images/about-img.svg"} alt="about"></img>
            </div>
          </div>
        </div>
        {/* Comented partner company logo */}
        {/* <TrustedCompay /> */}
      </div>

      <div className="team">
        <div className="container">
          <div className="team__header">
            <div className="team__title">Meet our team</div>
            <div className="about-text">Get to know about us.</div>
          </div>
          <div className="team__block">
            <div className="team__item">
            <div className="team__picture">
              
              <img src={"../../../images/1-min.jpg"} alt=""/>
            </div>
              <div className="team__item-name"> Thirtharaj</div>
              <div className="team__item-designation">Director</div>
              <div className="team__item-info">
                With decades of expertise in two-wheeler service and renovation, our director steers innovation and perfection, ensuring your ride's
                resurrection.
              </div>
              <div className="team__item-media">
                <Link to="https://www.linkedin.com/in/thirtharaj-ganiga-260760191" target="blank">
                  <Linked />
                </Link>
                {/* <span>
                <FB/>
                  <TWT />
                </span> */}
              </div>
            </div>
            <div className="team__item">
              <div className="team__picture">
              
                <img src={"../../../images/2-min.jpg"} alt=""/>
              </div>
              <div className="team__item-name">Abhishek</div>
              <div className="team__item-designation">Director</div>
              <div className="team__item-info">
                With a wealth of experience in two-wheeler marketing, our director accelerates brands to new heights, driving success with strategic insights
                and passion that ignites.
              </div>
              <div className="team__item-media">
                <Link to="https://www.linkedin.com/in/abhishek-ganiga-86b197260/" target="blank">
                  <Linked />
                  {/* <span>
                  <FB />
                </span>
                <span>
                  <TWT />
                </span> */}
                </Link>
              </div>
            </div>
            {/* <div className="team__item">
              <Author />
              <div className="team__item-name">Macauley Herring</div>
              <div className="team__item-designation">CEO & Founder</div>
              <div className="team__item-info">
                Dance is the hidden language of the soul.
              </div>
              <div className="team__item-media">
              <Link to="https://www.linkedin.com/in/thirtharaj-ganiga-260760191" target="blank">
                  <Linked />
                  </Link>
              </div>
            </div> */}
          </div>
          {/* <div className="team__block">
            <div className="team__item">
              <Author />
              <div className="team__item-name">Macauley Herring</div>
              <div className="team__item-designation">CEO & Founder</div>
              <div className="team__item-info">
                Dance is the hidden language of the soul.
              </div>
              <div className="team__item-media">
                <span>
                  <FB />
                </span>
                <span>
                  <TWT />
                </span>
              </div>
            </div>
            <div className="team__item">
              <Author />
              <div className="team__item-name">Macauley Herring</div>
              <div className="team__item-designation">CEO & Founder</div>
              <div className="team__item-info">
                Dance is the hidden language of the soul.
              </div>
              <div className="team__item-media">
                <span>
                  <FB />
                </span>
                <span>
                  <TWT />
                </span>
              </div>
            </div>
            <div className="team__item">
              <Author />
              <div className="team__item-name">Macauley Herring</div>
              <div className="team__item-designation">CEO & Founder</div>
              <div className="team__item-info">
                Dance is the hidden language of the soul.
              </div>
              <div className="team__item-media">
                <Link to="">
                  <FB />
                </Link>
                <Link to="">
                  <TWT />
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="about-getintouch">
        <div className="container about-getintouch__block">
          <div>
            <h2>Want to Join the Team?</h2>
            <p>
              OR Drop us an email To <a href="mailto:info@spannerdoor.com"> info@spannerdoor.com</a>
            </p>
          </div>
          <Link to="/careers" className="button open-positions">
            Check Open Positions
          </Link>
        </div>
      </div>
    </>
  );
};

export default About;
