import React from "react";
import { useNavigate } from "react-router-dom";

import errorIcon from "./../../assets/images/404Image.svg";
import "./NotFoundPage.scss";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate("/");
  };

  const refreshPage = () => {
    window.location.reload();
  };
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="notFoundPage">
      <img src={errorIcon} alt="" />
      <h1>Oh no! Error 404</h1>
      <p>Something went wrong, so this page is broken.</p>
      <div className="action">
        <button className="button button--primary" onClick={redirectToHome}>
          Go back to Homepage
        </button>
        <button className="button button--outline" onClick={refreshPage}>
          Try again
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
