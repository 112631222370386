import React from "react";
import ContactUs from "../../ContactUs/ContactUs";
import LeftSideBar from "../LeftSideBar/LeftSideBar";
import { Link } from "react-router-dom";

const HelpSupportAdmin = () => {
  return (
    <div className="dashboard-layout">
      <div className="dashboard-layout__left">
        <LeftSideBar />
      </div>
      <div className="dashboard-layout__right">
     
        <ContactUs type={"admin"} />
      </div>
    </div>
  );
};

export default HelpSupportAdmin;
