import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { makeStyles } from "@material-ui/core";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  ServiceLocation,
  SelectPlan,
  Editional,
  Kyc,
  DateTime,
  Confirm,
} from "../../assets/Icons";
import "./Service-info.scss";
import clsx from "clsx";

const Services = () => {
  const [value, setValue] = React.useState("1");
  
    React.useEffect(() => {
      window.scroll(0, 0);
    }, []);

  const steps = [
    {
      label: "1. Vehicle Details",
      description: `Provide your vehicle details, including make, model, and variant. We cater to all makes
      and models (less than 500 CC) across various brands such as TVS, Suzuki, Honda,
      Hero, Yamaha, Bajaj, Jawa, Mahindra, Royal Enfield, Piaggio, and Aprilia. Make sure to
      select the correct variant, as it helps us determine the accurate price for the service.
      Also, select your preferred location for the service.`,
    },
    {
      label: "2. Select a Plan",
      description: `Once the pricing details are available, choose the desired plan. For periodic service, we
      offer multiple packages tailored to your motorcycle or scooter usage. Select the plan
      that best suits your needs.`,
    },
    {
      label: "3. Customer KYC & Choose Date & Time",
      description: `Please note that our services are not available on the same day. Select your preferred
      date and time for the service, keeping in mind that it is subject to slot availability. If
      your preferred slot is unavailable, our service team will contact you to reschedule.`,
    },
    {
      label: "4. Add Pickup & Drop Location",
      description: `Customers can schedule a pickup for their two-wheeler from their preferred location,
      be it their home, office, or any other convenient spot. If your vehicle is in a
      non-operational condition or unsafe to ride due to mechanical issues, the pickup and
      drop off service eliminates the need to risk riding it to a service center. This ensures
      both your safety and the safety of others on the road.`,
    },
    {
      label: "5. Additional Details",
      description: `If you have specific requirements for spare parts or repairs, kindly mention them while
      booking. This information helps us ensure that the necessary spares are available
      during the appointment. If you're unsure of the problem, describe the symptoms or
      complaints you're experiencing, as it aids in diagnosing and resolving the issue during`,
    },
    {
      label: "6. Booking Confirmation",
      description: `Ensure that you provide accurate details such as your mobile number, alternate
      number, address, and email ID. Providing your email ID enables us to send status
      updates, estimate approvals, and invoice details.Once your booking is complete, you
      will receive a confirmation SMS and email.
      `,
    },
  ];

  const handleChange = (
    event: React.SyntheticEvent<EventTarget>,
    newValue: string
  ) => {
    return setValue(newValue);
  };

  const [activeStep, setActiveStep] = React.useState(0);

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleReset = () => {
    setActiveStep(0);
  };

  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: "#eaeaf0",
      padding: 8,
      borderRadius: "100%",
    },
    active: {
      color: "red",
    },
    completed: {
      color: "green",
    },
  }));

  const CustomStepIcon = (props: any) => {
    const classes = useStyles();
    const { active, completed } = props;

    const stepIcons: any = {
      1: <ServiceLocation />,
      2: <SelectPlan />,
      3: <DateTime />,
      4: <Editional />,
      5: <Kyc />,
      6: <Confirm />,
    };
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {stepIcons[String(props.icon)]}
      </div>
    );
  };

  return (
    <>
      <div className="service">
        <div className="container">
          <div className="service__block">
            <div className="service__block-text">
              <span className="service__badge">We are hiring</span>
              <h2 className="service__title">Service Booking Process</h2>
              <p>
                Schedule a service that suits your motorcycle or scooter, and
                our mechanic will come to your parking location to provide the
                service. You can book the service through our homepage or by
                clicking the "Book Now" button. Choose from options such as
                periodic service, repair services, accidental damage fixes, tyre
                replacement, battery replacement, or opt for an inspection if
                you're unsure of the issue.
              </p>
            </div>
            <img
              className="service__img"
              src={"../../../images/service-img.svg"}
              alt="service-img"
            ></img>
          </div>
        </div>
      </div>

      <div className="service service-stepper">
        <div className="container">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ maxWidth: { xs: 400, sm: 980 } }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="Service Booking" value="1" />
                  <Tab label=" Service Delivery Process" value="2" />
                  <Tab label=" Payment & Feedback" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Box>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                      <Step key={step.label} expanded={true}>
                        <StepLabel StepIconComponent={CustomStepIcon}>
                          {step.label}
                        </StepLabel>
                        <StepContent>
                          <Typography>{step.description}</Typography>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <div className="career__info">
                  <div className="career__info-title">
                    1. Customer Inquiry and Appointment
                  </div>
                  <ul className="career__info-list-block">
                    <li className="career__info-list">
                      The customer contacts the service provider, either through
                      phone, website, app, or in-person visit, to inquire about
                      the services they need for their two-wheeler
                    </li>
                    <li className="career__info-list">
                      The service provider gathers information about the type of
                      service required, any specific issues the vehicle is
                      facing, and the preferred date and time for the service.
                    </li>
                  </ul>
                  <div className="career__info-title">
                    2. Appointment Confirmation
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      The service provider confirms the appointment details with
                      the customer, including the date, time, and location for
                      pickup if the pickup and drop-off service is requested.
                    </li>
                  </ul>
                  <div className="career__info-title">3. Vehicle Pickup</div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      If the customer has opted for pickup, a representative of
                      the service provider arrives at the designated location to
                      pick up the two-wheeler.
                    </li>
                    <li className="career__info-list">
                      The representative may inspect the vehicle's condition and
                      note down any pre-existing issues reported by the
                      customer.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    4. Vehicle Inspection and Assessment
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      The two-wheeler is brought to the service center or
                      workshop, where it undergoes a comprehensive inspection.
                    </li>
                    <li className="career__info-list">
                      Trained technicians assess the overall condition of the
                      vehicle and identify any mechanical, electrical, or
                      cosmetic issues.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    4. Service Estimation
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      Based on the inspection results, the technicians create a
                      service estimate detailing the repairs, parts replacement,
                      maintenance tasks, and associated costs.
                    </li>
                    <li className="career__info-list">
                      The estimate is communicated to the customer for approval.
                      If there are any additional repairs or changes needed, the
                      customer is informed.
                    </li>
                    <li className="career__info-list">
                      The aforementioned details will be archived on the
                      customer's dedicated page for future reference.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    5. Customer Approval and Service Commencement
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      The customer reviews the service estimate and gives their
                      approval for the suggested repairs and maintenance tasks.
                    </li>
                    <li className="career__info-list">
                      Once approval is received, the service process begins.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    6. Repairs and Maintenance:
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      Technicians perform the required repairs, replacements,
                      and maintenance tasks according to the approved service
                      plan.
                    </li>
                    <li className="career__info-list">
                      The service is carried out with attention to detail and
                      adherence to manufacturer guidelines.
                    </li>
                  </ul>

                  <div className="career__info-title">7. Quality Checks</div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      After the repairs are completed, the vehicle undergoes
                      thorough quality checks to ensure that all issues have
                      been addressed and that the vehicle is in optimal
                      condition.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    8. Vehicle Cleaning and Detailing
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      The two-wheeler is cleaned and detailed, ensuring that it
                      is returned to the customer in a presentable and clean
                      state.
                    </li>
                  </ul>

                  <div className="career__info-title">9. Final Inspection</div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      A final inspection is conducted to verify that all repairs
                      and services have been completed successfully.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    10. Billing and Payment
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      The customer is presented with the final invoice detailing
                      the services rendered, parts replaced, labor charges, and
                      any applicable taxes.
                    </li>
                    <li className="career__info-list">
                      Payment is processed, and the customer receives a receipt.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    11. Vehicle Drop-off or Delivery
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      If the customer had requested pickup and drop-off, the
                      service provider arranges for the two-wheeler to be
                      delivered back to the customer's preferred location.
                    </li>
                    <li className="career__info-list">
                      Alternatively, the customer can visit the service center
                      to pick up their vehicle.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    12. Customer Feedback
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      The service provider may solicit feedback from the
                      customer to gauge their satisfaction with the service and
                      identify areas for improvement.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    13. Follow-up and Maintenance Recommendations
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      The service provider may provide maintenance
                      recommendations to the customer to help them keep their
                      vehicle in good condition between services.
                    </li>
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="3">
                <div className="career__info">
                  <div className="career__info-title">1. Package Selection</div>
                  <ul className="career__info-list-block">
                    <li className="career__info-list">
                      Once the user accesses the website or app, they can select
                      the type of service they need for their two-wheeler, such
                      as maintenance, repair, or customization.
                    </li>
                  </ul>
                  <div className="career__info-title">
                    2. Service Customization and Estimation
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      Users can provide details about their vehicle's issues or
                      requirements.
                    </li>
                    <li className="career__info-list">
                      Based on the provided information, the app or website
                      generates a cost estimate for the selected services,
                      including parts, labor, and taxes.
                    </li>
                  </ul>
                  <div className="career__info-title">3. Payment Options</div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      Users are offered multiple payment options, including UPI,
                      credit/debit cards, digital wallets, online banking, and
                      more.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    4. Secure Payment Gateway
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      The website or app integrates a secure payment gateway to
                      process the transaction, ensuring the safety of users'
                      payment information.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    5. Payment Confirmation
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      Once the payment is successfully processed, users receive
                      a payment confirmation message, along with mail
                      confirmation.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    5. Payment Confirmation
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                      Once the payment is successfully processed, users receive
                      a payment confirmation message, along with mail
                      confirmation.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    6. Service Completion Notification
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                    After the service is completed and the user's two-wheeler is ready for
delivery, the user is notified through the app or website.
                    </li>
                  </ul>

                  <div className="career__info-title">
                    7. Service Completion Notification
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                    After the service is completed and the user's two-wheeler is ready for
delivery, the user is notified through the app or website.
                    </li>
                  </ul>


                  <div className="career__info-title">
                    8. Feedback Request
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                     Users receive a feedback request, asking them to rate their experience and
provide comments on the service quality and overall experience.

                    </li>
                  </ul>

                  
                  <div className="career__info-title">
                    9. Rating System
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                     A user-friendly star rating system is used to gather quantitative feedback
on the service.

                    </li>
                  </ul>

                  <div className="career__info-title">
                 10. Comment Section
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                    A user-friendly star rating system is used to gather quantitative feedback
on the service.

                    </li>
                  </ul>

                  <div className="career__info-title">
                 10. Feedback Submission
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                     Users submit their feedback, which is recorded and associated with their
service request.


                    </li>
                  </ul>

                  <div className="career__info-title">
                 11. Thank You Message:
                  </div>
                  <ul className="career__info-list">
                    <li className="career__info-list">
                    After submitting feedback, users receive a thank you message for taking
the time to provide their thoughts.

                    </li>
                  </ul>


                  
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Services;
