export class jobsheetDetails {
  name: string;
  odo: string;
  brand: string;
  model: string;
  package: string;
  reg: string;
  mobile: string;
  orderId: string;
  link: string;
  fuelType: string;
  card: any;
  fuelPercentage: string;
  emission: string;
  emissionExpiryDate: string;
  insurance: string;
  insuranceExpiryDate: string;
  chassisNumber: string;
  engineNumber: string;
  recipientGST: string;
  serviceConsultant: string;
  constructor(data: any = {}) {
    this.reg = data.reg || "";
    this.odo = data.odo || "";
    this.link = data.link || "";
    this.name = data.name || "";
    this.brand = data.brand || "";
    this.model = data.model || "";
    this.mobile = data.mobile || "";
    this.package = data.package || "";
    this.orderId = data.orderId || "";
    this.package = data.package || "";
    this.fuelType = data.fuelType || "";
    this.emission = data.emission || "";
    this.insurance = data.insurance || "";
    this.engineNumber = data.engineNumber || "";
    this.recipientGST = data.recipientGST || "";
    this.chassisNumber = data.chassisNumber || "";
    this.fuelPercentage = data.fuelPercentage || "";
    this.emissionExpiryDate = data.emissionExpiryDate || "";
    this.insuranceExpiryDate = data.insuranceExpiryDate || "";
    this.serviceConsultant = data.serviceConsultant || "";
  }
}

export class jobsheetDetailsErr {
  name: boolean;
  odo: boolean;
  brand: boolean;
  model: boolean;
  package: boolean;
  reg: boolean;
  mobile: boolean;
  orderId: boolean;
  link: boolean;
  fuelType: boolean;
  card: any;
  fuelPercentage: boolean;
  emission: boolean;
  emissionExpiryDate: boolean;
  insurance: boolean;
  insuranceExpiryDate: boolean;
  chassisNumber: boolean;
  engineNumber: boolean;
  recipientGST: boolean;
  serviceConsultant: boolean;
  constructor(data: any = {}) {
    this.reg = data.reg || false;
    this.odo = data.odo || false;
    this.link = data.link || false;
    this.name = data.name || false;
    this.brand = data.brand || false;
    this.model = data.model || false;
    this.mobile = data.mobile || false;
    this.package = data.package || false;
    this.orderId = data.orderId || false;
    this.package = data.package || false;
    this.fuelType = data.fuelType || false;
    this.emission = data.emission || false;
    this.insurance = data.insurance || false;
    this.engineNumber = data.engineNumber || false;
    this.recipientGST = data.recipientGST || false;
    this.chassisNumber = data.chassisNumber || false;
    this.fuelPercentage = data.fuelPercentage || false;
    this.emissionExpiryDate = data.emissionExpiryDate || false;
    this.insuranceExpiryDate = data.insuranceExpiryDate || false;
    this.serviceConsultant = data.serviceConsultant || false;
  }
}
