import { useEffect, useState } from "react";
import React from "react";
import OtpInput from "react-otp-input";
import { Box, FormControl } from "@mui/material";
import Button from "@mui/material/Button";
import { Verified } from "@mui/icons-material";
import { SpannerDoorContext } from "../../utils/context/GlobalContext";
import { apiEndPoints } from "../../utils/helpers/endPoints";
import { sendPostRequest } from "../../utils/helpers/requestController";
import { getCookie, setCookie } from "../../utils/helpers/utils";
import { useNavigate } from "react-router-dom";
import { useCustomDialog } from "../../components/Dialog/CustomDialog/CustomDialogService";

import signInSvg from "./../../assets/images/signInSvg.svg";
import { useSnackbar } from "../../components/Dialog/SnackbarDialog/SnackbarService";

const OtpInputs = ({ type, onClose, phoneNum, setSubmitted }: any) => {
  const [otp, setOtp] = useState("");
  const { service }: any = React.useContext(SpannerDoorContext);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const customDialog = useCustomDialog();
  const snackbar = useSnackbar();

  const handleVerify = async (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const payload = {
      phone: service?.usersInfo?.contact,
      otp: otp.toString(),
    };
    try {
      const res = await sendPostRequest(apiEndPoints.verifyUser, payload);
      setCookie("access_token", res.data.token);
      setCookie("isAdmin", res.data.isAdmin);
      sessionStorage.setItem("access_token", res.data.token);
      sessionStorage.setItem("userId", res.data.userId);
      setCookie("userId", res.data.userId);
      if (!type) {
        customDialog({
          catchOnCancel: false,
          description: ["Sign in Successfully", "You have sign in Successfully"],
          customClass: "no-description",
          successLabel: "",
          maxWidthProp: "md",
          imgSrc: signInSvg,
        }).then(async () => {});
        navigate("service/package");
      } else {
        onClose();
        if (res?.data?.isAdmin) {
          navigate("admin/dashboard");
        } else {
          navigate("service/home");
        }
      }
    } catch (error: any) {
      setError(true);
      console.log(error);
    }
  };

  const handleBooking = async (evt: any) => {
    const payload = {
      phone: service?.usersInfo?.contact,
    };
    try {
      await sendPostRequest(apiEndPoints.addUser, payload);
      snackbar({
        title: "OTP sent successfully",
        hideCancel: true,
        open: true,
        autoHideDuration: 300000,
      });
      // setSubmitted(true);
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleVerify} autoComplete="off">
      <div>
        <div className="otp-title">
          Enter OTP sent to {phoneNum?.slice(3)} <span onClick={() => setSubmitted(false)}>Change?</span>
        </div>

        <OtpInput
          value={otp}
          onChange={setOtp}
          containerStyle="containerstyle"
          inputStyle="inputstyle"
          inputType="tel"
          numInputs={4}
          renderSeparator={<span> </span>}
          renderInput={(props) => <input {...props} />}
        />

        {error && (
          <div className="form__input-error">
            <p>Please enter the valid otp .</p>
          </div>
        )}
        <Box className="verify-action">
          <FormControl fullWidth>
            <Button type="submit" variant="contained">
              Verify
            </Button>
          </FormControl>
        </Box>
        <hr />
        <p className="resend-text">
          If you didn’t receive a code
          <span className="resend-link" onClick={handleBooking} style={{ cursor: "pointer" }}>
            {" "}
            Resend
          </span>
        </p>
      </div>
    </form>
  );
};

export default OtpInputs;
