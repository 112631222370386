export const pincodeRegEx = new RegExp("^\\d{6}$");
export const textRegEx = new RegExp("^[a-zA-Z\\s]+$");
export const phoneRegEx = new RegExp("^(\\+91|\\+91\\-|0)?[6-9]\\d{9}$");
export const mailRegEx = new RegExp("^[\\w.-]+@([\\w-]+\\.)+[\\w-]{2,4}$");
export const vehicleRegEx = new RegExp("^[A-Za-z]{2}\\d{2}[A-Za-z]{2}\\d{4}$");

export const TaxRate = [
  {
    option: 0,
  },
  {
    option: 5,
  },
  {
    option: 12,
  },
  {
    option: 18,
  },
  {
    option: 28,
  },
];
export const accessoriesTyp = [
  {
    id: 1,
    option: "Bike",
  },
  {
    id: 2,
    option: "Scooter",
  },
  {
    id: 3,
    option: "Bike/Scooter",
  },
];
