export const rootConfig: any = {
  apiRoot: "http://localhost:3000/api/",
  // apiRoot: "http://13.235.10.231:3000/api/",
  // apiRoot: "https://spannerdoor.com:3000/api/",
  // apiRoot: "https://service.spannerdoor.com:3000/api/",
};

export const apiEndPoints: any = {
  addOns: `${rootConfig.apiRoot}addOns`,
  invoice: `${rootConfig.apiRoot}invoice`,
  userData: `${rootConfig.apiRoot}me`,
  contact: `${rootConfig.apiRoot}contact`,
  addUser: `${rootConfig.apiRoot}send_otp`,
  garageList: `${rootConfig.apiRoot}garage`,
  brandNames: `${rootConfig.apiRoot}brand/`,
  modelNames: `${rootConfig.apiRoot}models`,
  verifyUser: `${rootConfig.apiRoot}verify`,
  createService: `${rootConfig.apiRoot}services`,
  service: `${rootConfig.apiRoot}service`,
  addOnGroup: `${rootConfig.apiRoot}addOnsGroup`,
  servicePackage: `${rootConfig.apiRoot}packages`,
  verifyDiscount: `${rootConfig.apiRoot}discount/`,
  pincodeGet: `https://api.postalpincode.in/pincode/`,
  orderStatus: `${rootConfig.apiRoot}services/status/`,
  confirmService: `${rootConfig.apiRoot}services-confirm`,
  grage: `${rootConfig.apiRoot}grage`,
  accessories: `${rootConfig.apiRoot}addOn`,
  uploadJobSheet: `${rootConfig.apiRoot}services/uploadJobSheet`,
  uploadInvoice: `${rootConfig.apiRoot}services/uploadInvoice`,
};
